import React from 'react'
import { View } from 'react-native'
import Svg, { Path } from 'react-native-svg'
import { useTheme } from '@ui-kitten/components'
import { StyleService, useStyleSheet } from '@src/style/service'
import { useArcIndicator } from '@src/hooks/useArcIndicator'
import { Text } from '@src/components/base'
import { NUTRITION_CHART_COLORS } from '../NutritionSummaryChart'

export const ARC_INDICATOR_PROPERTIES = { width: 170, height: 140, strokeWidth: 17 }
const ARC_COEFFICIENT = 1.4

export interface CaloriesArcChartProps {
  title: string
  value?: number
  minValue: number
  maxValue: number
}

export const CaloriesArcChart = ({ title, value, minValue, maxValue }: CaloriesArcChartProps) => {
  const styles = useStyleSheet(themedStyles)
  const theme = useTheme()

  const { width, height, strokeWidth } = ARC_INDICATOR_PROPERTIES

  const { arcLength, arcPath } = useArcIndicator(width, height, width, strokeWidth, ARC_COEFFICIENT)

  const progress = value === undefined ? 0 : Math.min(value, maxValue) / (maxValue - minValue)
  const strokeDashoffset = arcLength * progress - arcLength

  const targetOvershotColor =
    !!value && value > maxValue ? theme['theme.error.base'] : theme['theme.text.primary']
  return (
    <View style={styles.container}>
      <Svg width={width} height={height}>
        <Path
          stroke={NUTRITION_CHART_COLORS.calories.background}
          fill="none"
          strokeLinecap="round"
          {...{
            strokeWidth,
            d: arcPath,
          }}
        />
        <Path
          stroke={NUTRITION_CHART_COLORS.calories.progress}
          fill="none"
          strokeDasharray={`${arcLength}, ${arcLength}`}
          strokeLinecap="round"
          {...{
            strokeDashoffset,
            strokeWidth,
            d: arcPath,
          }}
        />
      </Svg>
      <View style={styles.infoContainer}>
        <Text type="regular" style={styles.title}>
          {title}
        </Text>
        <Text
          type="title-2"
          style={{
            color: targetOvershotColor,
          }}
        >
          {value ?? '0'}
        </Text>
        <Text type="large" bold style={styles.maxValue}>
          {maxValue}
        </Text>
      </View>
    </View>
  )
}

const themedStyles = StyleService.create({
  container: {
    alignItems: 'center',
  },
  title: {
    color: 'theme.text.secondary',
    marginBottom: 8,
  },
  maxValue: {
    color: 'theme.text.tertiary',
  },
  infoContainer: {
    position: 'absolute',
    top: 55,
    left: 0,
    right: 0,
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
})
