import App from '@models/app'
import Users from '@models/users'
import Meals from '@screens/Meals/models/meals'
import Ingredients from '@screens/Ingredients/models/ingredients'
import Scans from '@screens/Scans/models/scans'
import Sensor from '@screens/Settings/models/sensor'
import Events from '@screens/Events/models/events'
import Address from '@screens/Subscription/models/address'
import Settings from '@screens/Settings/models/settings'
import Activities from '@screens/Activities/models/activities'
import Measurements from '@screens/Measurements/models/measurements'
import History from '@src/screens/History/models/history'
import Marketplace from '@src/screens/Marketplace/model/marketplace'
import Education from '@src/screens/Education/model/education'
import Insights from '@src/screens/Insights/models/insights'
import PolicyConsents from '@src/screens/PolicyConsents/models/policyConsents'
import Courses from '@screens/Learn/models/courses'
import Tutorials from '@src/screens/Tutorials/models/tutorials'
import JournalEntries from '@src/screens/JournalEntries/models/journalEntries'
import DailyMeasurements from '@src/screens/DailyMeasurements/models/dailyMeasurements'
import Chatbot from '@src/screens/Chat/models/chatbot'
import Notifications from '@src/models/notifications'
import NutritionistHub from '@src/screens/NutritionistHub/models/nutritionistHub'
import Goals from '@src/models/goals'
import Checklist from '@src/screens/Checklist/models/checklist'
import LabResults from '@src/screens/LabResults/models/labResults'
import InsuranceStatus from '@src/screens/InsuranceStatus/models/insuranceStatus'
import DailyActions from '@src/screens/DailyActions/models/dailyActions'
import PrescriptionDexcom from '@src/screens/Dexcom/Prescription/models/prescriptionDexcom'

const models = {
  app: new App(),
  users: new Users(),
  scans: new Scans(),
  meals: new Meals(),
  ingredients: new Ingredients(),
  journalEntries: new JournalEntries(),
  sensor: new Sensor(),
  dailyActions: new DailyActions(),
  events: new Events(),
  history: new History(),
  address: new Address(),
  settings: new Settings(),
  activities: new Activities(),
  measurements: new Measurements(),
  dailyMeasurements: new DailyMeasurements(),
  marketplace: new Marketplace(),
  education: new Education(),
  insights: new Insights(),
  policyConsents: new PolicyConsents(),
  courses: new Courses(),
  tutorials: new Tutorials(),
  chatbot: new Chatbot(),
  notifications: new Notifications(),
  nutritionistHub: new NutritionistHub(),
  goals: new Goals(),
  checklist: new Checklist(),
  labResults: new LabResults(),
  insuranceStatus: new InsuranceStatus(),
  prescriptionDexcom: new PrescriptionDexcom(),
}

export default models
