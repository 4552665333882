import { useIsByosLinkupSensorMode } from '../hooks'
import { useIsOwnSensorGlucoseDetected } from './useIsOwnSensorGlucoseDetected'
import { useIsThirdPartyByosSensorMode } from './useIsThirdPartyByosSensorMode'
import { useShowByosDataInfoUi } from './useShowByosDataInfoUi'

export const useShowByosThirdPartyDelaySyncInfo = () => {
  const shouldShowByosDataDelayUi = useShowByosDataInfoUi()
  const isOwnSensorGlucoseDetected = useIsOwnSensorGlucoseDetected()
  const isThirdPartyByosSensorMode = useIsThirdPartyByosSensorMode()
  const isByosLinkupSensorMode = useIsByosLinkupSensorMode()

  if (!shouldShowByosDataDelayUi || !isOwnSensorGlucoseDetected || isByosLinkupSensorMode) {
    return false
  }

  return isThirdPartyByosSensorMode
}
