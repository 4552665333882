import { useSelector } from 'react-redux'
import { sensorSelector } from '@src/selectors/sensor'
import { useIsLibreLinkup } from '@src/utils/hooks'
import { useIsByosScannableSensorMode } from '../hooks'
import { useIsOwnSensorGlucoseDetected } from './useIsOwnSensorGlucoseDetected'
import { useShowByosDataInfoUi } from './useShowByosDataInfoUi'
import { useIsThirdPartyByosSensorMode } from './useIsThirdPartyByosSensorMode'

export const useShowByosIntegrationWarning = () => {
  const currentSensor = useSelector(sensorSelector)
  const isByosScannableSensorMode = useIsByosScannableSensorMode()

  const isOwnSensorGlucoseDetected = useIsOwnSensorGlucoseDetected()
  const isThirdPartyByosSensorMode = useIsThirdPartyByosSensorMode()
  const isLibreLinkup = useIsLibreLinkup()

  const showByosDataInfoUi = useShowByosDataInfoUi()

  const nativeWarning = showByosDataInfoUi && isByosScannableSensorMode && !currentSensor
  const thirdPartyWarning =
    showByosDataInfoUi &&
    isThirdPartyByosSensorMode &&
    !isOwnSensorGlucoseDetected &&
    !isLibreLinkup &&
    !currentSensor // eventually we wil create sensor records for third party sensors

  return { nativeWarning, thirdPartyWarning }
}

export const useIsByosIntegrationWarning = () => {
  const { nativeWarning, thirdPartyWarning } = useShowByosIntegrationWarning()

  return nativeWarning || thirdPartyWarning
}
