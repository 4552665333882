import { gql } from '@apollo/client'

const ACTIVATE_PRESCRIPTION_DEXCOM = gql`
  mutation activatePrescriptionDexcom {
    activatePrescriptionDexcom {
      success
    }
  }
`

export default ACTIVATE_PRESCRIPTION_DEXCOM
