import React, { useContext, useEffect, useState } from 'react'
import { ScrollView, View } from 'react-native'
import { useNavigation } from '@react-navigation/core'
import { useDispatch } from 'react-redux'
import { StackNavigationProp } from '@react-navigation/stack'
import { RouteProp, useRoute } from '@react-navigation/native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Button, Icon, Text, UI_ICONS_MAPPING } from '@components/base'
import { LoadingContainer } from '@src/screens/Common/containers/LoadingContainer'
import {
  QuestionnaireScreenName,
  QuestionnaireStackParamList,
  RootStackParamList,
} from '@src/navigation/types'
import { QuestionnaireContext } from '../questionnaire.navigator'

const INSURANCE_SUBMISSIONS_ERROR_MESSAGE =
  'We had an issue with your eligibility check. Our team has been notified and a customer support \
representative will reach out to you shortly. In the meantime, please proceed to sensor selection'

export const InsuranceQuestionnaireCompletedScreen = () => {
  const styles = useStyleSheet(themedStyles)

  const insets = useSafeAreaInsets()

  const dispatch = useDispatch()
  const navigation = useNavigation<StackNavigationProp<RootStackParamList>>()
  const { params } = useRoute<
    RouteProp<QuestionnaireStackParamList, QuestionnaireScreenName.InsuranceQuestionnaireCompleted>
  >()

  const { nextScreen, nextScreenParams } = params || {}

  const [eligibilityCheckRunning, setEligibilityCheckRunning] = useState(true)
  const [error, setError] = useState(false)

  const { completedScreenConfig } = useContext(QuestionnaireContext)
  const {
    title,
    header,
    icon,
    intro,
    primaryActionText,
    onCloseButtonPress,
  } = completedScreenConfig

  const onErrorContinuePress = () => {
    navigation.replace('Drawer', { screen: 'Dashboard' })
  }

  useEffect(() => {
    dispatch({
      type: 'nutritionistHub/submitInsurancePolicy',
      failure: () => {
        setError(true)
      },
      success: () => {
        setEligibilityCheckRunning(false)
      },
    })
  }, [dispatch])

  if (eligibilityCheckRunning) {
    return (
      <LoadingContainer
        loadingMessage="Checking Insurance"
        errorMessage={INSURANCE_SUBMISSIONS_ERROR_MESSAGE}
        error={error}
      >
        <Button
          type="primary"
          size="block"
          accessibilityLabel="Continue"
          onPress={onErrorContinuePress}
        >
          Continue
        </Button>
      </LoadingContainer>
    )
  }

  const onPress = () => {
    if (nextScreen) {
      navigation.replace(nextScreen as any, nextScreenParams)
      return
    }

    onCloseButtonPress()
  }

  return (
    <ScrollView
      bounces={false}
      style={styles.container}
      contentContainerStyle={[styles.viewContent, { paddingBottom: Math.max(insets.bottom, 16) }]}
    >
      <View style={styles.topContainer}>
        {!!icon && <Icon name={UI_ICONS_MAPPING[icon]} style={styles.successIcon} />}
        <Text type="title-2" style={styles.titleText}>
          {title || "You're Eligible for Insurance-Covered Video Calls!"}
        </Text>
        <Text type="large" style={styles.stepText}>
          {header}
        </Text>
        <Text type="regular" style={styles.stepText}>
          {intro}
        </Text>
      </View>
      <Button
        size="block"
        accessibilityLabel={primaryActionText || 'Schedule call'}
        type="primary"
        onPress={onPress}
      >
        {primaryActionText || 'Schedule call'}
      </Button>
    </ScrollView>
  )
}

const themedStyles = StyleService.create({
  container: {
    flex: 1,
    backgroundColor: 'theme.background',
    paddingHorizontal: 24,
    paddingTop: 60,
  },
  viewContent: {
    flexGrow: 1,
    justifyContent: 'space-between',
  },
  topContainer: {
    flex: 1,
    flexGrow: 1,
  },
  titleText: {
    textAlign: 'center',
    marginBottom: 24,
  },
  stepText: {
    textAlign: 'left',
    marginBottom: 24,
  },
  successIcon: {
    alignSelf: 'center',
    width: 48,
    marginVertical: 24,
    color: 'theme.text.primary',
  },
})
