import { SensorTypes } from 'react-native-freestyle-libre'
import { useCallback } from 'react'
import { Feature, useFeatureFlag } from '@src/components'
import { SCAN_DISABLED_SENSORS } from '../containers/types'

export interface UseIsScanDisabledForSensorParams {
  model: SensorTypes
}

export const useIsScanDisabledForSensor = () => {
  const scanDisabledLibre1And2 = useFeatureFlag(Feature.ScanDisableFullLibre1And2)
  return useCallback(
    ({ model }: UseIsScanDisabledForSensorParams) => {
      return scanDisabledLibre1And2 && SCAN_DISABLED_SENSORS.includes(model)
    },
    [scanDisabledLibre1And2],
  )
}
