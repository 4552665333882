import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFocusEffect, useNavigation } from '@react-navigation/native'
import { View } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { NavigationContainer } from '@src/screens/Common/containers'
import { ErrorMessages } from '@src/config'
import { useSnack } from '@src/utils'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Button, Icon, Text } from '@src/components/base'
import { ScrollViewWithFade } from '@src/components'
import {
  canCreateNewPolicySelector,
  insurancePoliciesSelector,
} from '../models/insuranceStatus.selectors'
import { InsurancePolicyComponent } from './InsurancePolicyComponent'

export const InsuranceStatusScreen = () => {
  const styles = useStyleSheet(themedStyles)
  const dispatch = useDispatch()
  const showSnack = useSnack()
  const insets = useSafeAreaInsets()
  const insurancePolicies = useSelector(insurancePoliciesSelector)
  const canCreateNewPolicy = useSelector(canCreateNewPolicySelector)
  const navigation = useNavigation()

  useFocusEffect(
    useCallback(() => {
      dispatch({
        type: 'insuranceStatus/fetchInsurancePolicies',
        failure: (error: any) => {
          const errorMessage = error?.message || ErrorMessages.ServerError
          showSnack(errorMessage, null, 'error')
        },
      })
    }, [dispatch, showSnack]),
  )

  const onAddNewInsurancePress = () => {
    navigation.navigate('InsurancePolicyForm')
  }

  return (
    <NavigationContainer title="Insurance Status" style={styles.container}>
      <ScrollViewWithFade scrollViewStyle={styles.content}>
        {!insurancePolicies?.length ? (
          <View style={styles.emptyPoliciesContainer}>
            <Icon name="identification-card" style={styles.identificationCardIcon} />
            <Text type="title-3" style={styles.noInsuranceTitle}>
              No insurance provided
            </Text>
            <Text type="regular" style={styles.noInsuranceContent}>
              Please, add your details to schedule calls fully covered.
            </Text>
          </View>
        ) : (
          <View>
            {insurancePolicies.map((policy) => (
              <InsurancePolicyComponent
                key={policy.id}
                insurancePolicy={policy}
                style={styles.policyContainer}
              />
            ))}
          </View>
        )}
      </ScrollViewWithFade>
      {canCreateNewPolicy && (
        <Button
          accessibilityLabel="Add New Insurance"
          type="primary"
          size="block"
          onPress={onAddNewInsurancePress}
          style={[styles.button, { marginBottom: insets.bottom + 16 }]}
        >
          Add New Insurance
        </Button>
      )}
    </NavigationContainer>
  )
}

const themedStyles = StyleService.create({
  container: {
    flex: 1,
  },
  content: {
    flexGrow: 1,
    paddingTop: 16,
    paddingHorizontal: 16,
    justifyContent: 'space-between',
  },
  policyContainer: {
    marginBottom: 8,
  },
  button: {
    marginTop: 16,
    marginHorizontal: 16,
  },
  emptyPoliciesContainer: {
    alignItems: 'center',
    marginTop: 96,
  },
  identificationCardIcon: {
    width: 64,
    marginBottom: 24,
  },
  noInsuranceTitle: {
    marginBottom: 12,
  },
  noInsuranceContent: {
    maxWidth: 240,
    textAlign: 'center',
  },
})
