import React, { useEffect } from 'react'
import { ActivityIndicator, TouchableOpacity, View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import { ImageStyle } from 'react-native-fast-image'
import { useIsFocused } from '@react-navigation/native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { ImageWithPlaceholder } from '@src/components/Image/FastImageWithPlaceholder'
import { Text } from '@src/components/base'
import { ScrollViewWithFade, useActionBottomSheet } from '@src/components'
import { useSnack } from '@src/utils'
import { ErrorMessages } from '@src/config'
import { CoachProfile } from '@src/types'
import { topPriorityCoachAssignmentSelector } from '../models/nutritionistHub.selectors'

export const NutritionistInfoCard = () => {
  const styles = useStyleSheet(themedStyle)
  const showActionBottomSheet = useActionBottomSheet()
  const coachAssignment = useSelector(topPriorityCoachAssignmentSelector)
  const showSnack = useSnack()
  const dispatch = useDispatch()
  const isFocused = useIsFocused()

  useEffect(() => {
    if (!isFocused) {
      return
    }

    dispatch({
      type: 'nutritionistHub/fetchCoachAssignments',
      failure: (error: any) => {
        const errorMessage = error?.message || ErrorMessages.ServerError
        showSnack(errorMessage, null, 'error')
      },
    })
  }, [dispatch, showSnack, isFocused])

  const nutritionist = coachAssignment?.coach

  if (!nutritionist) {
    return null
  }

  const { firstName, lastName } = nutritionist
  const { bio, photo, credentials } = nutritionist?.coachProfile || ({} as CoachProfile)

  let nutritionistNameAndTitle = `${firstName} ${lastName.charAt(0)}`
  if (credentials) {
    nutritionistNameAndTitle += `, ${credentials}`
  }

  const onPress = () => {
    if (!bio) {
      return
    }

    const body = (
      <ScrollViewWithFade
        style={styles.bio}
        scrollViewStyle={styles.bioScrollView}
        fadeColor={styles.bio.backgroundColor}
      >
        <Text type="large" style={styles.bioText}>
          {bio}
        </Text>
      </ScrollViewWithFade>
    )
    showActionBottomSheet({
      title: nutritionistNameAndTitle,
      body,
      icon: photo && (
        <ImageWithPlaceholder
          style={styles.actionSheetPhoto as ImageStyle}
          source={{ uri: photo }}
          placeholderComponent={<ActivityIndicator animating size="small" {...styles.spinner} />}
        />
      ),
      primaryButton: {
        text: 'Dismiss',
      },
    })
  }

  return (
    <TouchableOpacity
      accessibilityLabel="Your Nutritionist"
      style={styles.nutritionistInfoContainer}
      onPress={onPress}
      disabled={!bio}
    >
      <View style={styles.nutritionistInfoContent}>
        {photo && (
          <ImageWithPlaceholder
            style={styles.nutritionistPhoto as ImageStyle}
            source={{ uri: photo }}
            placeholderComponent={<ActivityIndicator animating size="small" {...styles.spinner} />}
          />
        )}
        <View style={styles.nutritionistNameAndLinkContainer}>
          <Text type="large">{nutritionistNameAndTitle}</Text>
          {bio && (
            <Text type="regular" style={styles.secondaryText}>
              Learn more about your nutritionist
            </Text>
          )}
        </View>
      </View>
    </TouchableOpacity>
  )
}

const themedStyle = StyleService.create({
  nutritionistInfoContainer: {
    flexDirection: 'row',
    borderColor: 'theme.surface.base1',
    borderRadius: 16,
    borderWidth: 2,
    marginBottom: 16,
  },
  nutritionistInfoContent: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 16,
    gap: 8,
  },
  nutritionistPhoto: {
    width: 64,
    height: 64,
    borderRadius: 32,
    marginRight: 12,
  },
  nutritionistNameAndLinkContainer: {
    justifyContent: 'space-between',
  },
  secondaryText: {
    color: 'theme.text.secondary',
  },
  spinner: {
    color: 'theme.surface.base',
  },
  bio: {
    flexBasis: 300,
    backgroundColor: 'theme.background.modal',
  },
  bioScrollView: {
    paddingTop: 16,
  },
  bioText: {
    textAlign: 'center',
  },
  actionSheetPhoto: {
    width: 104,
    height: 104,
    borderRadius: 52,
    marginBottom: 16,
  },
})
