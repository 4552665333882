/* eslint-disable max-len */
import * as React from 'react'
import Svg, {
  Path,
  G,
  Filter,
  Defs,
  Circle,
  Stop,
  LinearGradient,
  FeFlood,
  FeColorMatrix,
  FeOffset,
  FeGaussianBlur,
  FeBlend,
} from 'react-native-svg'

export const DexcomCgm = () => (
  <Svg width="77" height="68" viewBox="0 0 77 68" fill="none">
    <G filter="url(#filter0_d_4096_42403)">
      <Path
        d="M65.6483 24.1022C65.6483 35.7567 55.1314 45.2044 42.6973 45.2044C30.2632 45.2044 11.8013 41.5661 11.8013 24.9298C11.8013 8.29352 30.2632 3 42.6973 3C55.1314 3 65.6483 12.4478 65.6483 24.1022Z"
        fill="url(#paint0_linear_4096_42403)"
      />
      <Path
        d="M61.4513 21.1916C61.4513 31.2385 52.7819 39.3831 42.5321 39.3831C32.2822 39.3831 17.0635 35.003 17.0635 21.905C17.0635 8.80709 32.2822 3 42.5321 3C52.7819 3 61.4513 11.1446 61.4513 21.1916Z"
        fill="url(#paint1_linear_4096_42403)"
      />
      <Circle
        opacity="0.7"
        cx="30.9875"
        cy="23.0466"
        r="1.75852"
        fill="url(#paint2_linear_4096_42403)"
      />
    </G>
    <Defs>
      <Filter
        id="filter0_d_4096_42403"
        x="0.452912"
        y="0.980876"
        width="76.5439"
        height="64.9008"
        filterUnits="userSpaceOnUse"
      >
        <FeFlood floodOpacity="0" result="BackgroundImageFix" />
        <FeColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <FeOffset dy="9.32923" />
        <FeGaussianBlur stdDeviation="5.67418" />
        <FeColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
        <FeBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4096_42403" />
        <FeBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_4096_42403"
          result="shape"
        />
      </Filter>
      <LinearGradient
        id="paint0_linear_4096_42403"
        x1="38.7327"
        y1="3"
        x2="38.7327"
        y2="45.2044"
        gradientUnits="userSpaceOnUse"
      >
        <Stop stopColor="#EDEDED" />
        <Stop offset="1" stopColor="#F3F3F3" />
      </LinearGradient>
      <LinearGradient
        id="paint1_linear_4096_42403"
        x1="41.804"
        y1="39.3689"
        x2="42.6096"
        y2="3.7024"
        gradientUnits="userSpaceOnUse"
      >
        <Stop stopColor="#E4E4E4" />
        <Stop offset="1" stopColor="white" />
      </LinearGradient>
      <LinearGradient
        id="paint2_linear_4096_42403"
        x1="30.9875"
        y1="21.2881"
        x2="30.9875"
        y2="24.8051"
        gradientUnits="userSpaceOnUse"
      >
        <Stop stopColor="#5A5A5A" />
        <Stop offset="1" stopColor="#676767" stopOpacity="0.07" />
      </LinearGradient>
      <LinearGradient
        id="paint3_linear_4096_42403"
        x1="31.3391"
        y1="64.3125"
        x2="31.3391"
        y2="67.1261"
        gradientUnits="userSpaceOnUse"
      >
        <Stop stopColor="#5A5A5A" />
        <Stop offset="1" stopColor="#676767" stopOpacity="0.07" />
      </LinearGradient>
    </Defs>
  </Svg>
)
