import React from 'react'
import { TouchableOpacity, View } from 'react-native'
import FastImage, { ImageStyle } from 'react-native-fast-image'
import { useSelector } from 'react-redux'
import { useTheme } from '@ui-kitten/components'
import { useNavigation } from '@react-navigation/native'
import { Text } from '@components/base'
import { StyleService, useStyleSheet } from '@src/style/service'
import { userSelector } from '@src/selectors/app'
import { useUserInitials } from '@src/utils/hooks'
import { useIsDarkMode } from '@src/config/theme'
import { useWebPresentationModeContext } from '@src/config/webPresentationMode'
import { MobileAppFeature } from '@src/types'
import { Debug } from '@src/config'
import { User } from '@src/utils'

export const AccountAvatar = () => {
  const styles = useStyleSheet(themedStyle)
  const { avatar } = useSelector(userSelector) ?? {}
  const initials = useUserInitials()
  const theme = useTheme()
  const navigation = useNavigation()
  const isDarkMode = useIsDarkMode()

  const { isWebPresentationMode } = useWebPresentationModeContext()

  if (isWebPresentationMode) {
    return
  }

  const handleAccountIconPress = () => {
    navigation.navigate('Account')
  }

  const handleAccountIconLongPress = () => {
    if (Debug.useAdminMenu && User.hasFeature(MobileAppFeature.AdminAccess)) {
      navigation.navigate('AdminSettings')
    }
  }

  return (
    <TouchableOpacity
      onPress={handleAccountIconPress}
      onLongPress={handleAccountIconLongPress}
      accessibilityLabel="View account"
    >
      <View style={styles.container}>
        {avatar ? (
          <FastImage style={styles.avatar as ImageStyle} source={{ uri: avatar }} />
        ) : (
          <View
            style={[
              styles.initialsContainer,
              {
                backgroundColor: isDarkMode
                  ? theme['theme.accent.brick']
                  : theme['theme.accent.citron'],
              },
            ]}
          >
            <Text type="regular" bold style={styles.initials}>
              {initials}
            </Text>
          </View>
        )}
      </View>
    </TouchableOpacity>
  )
}

const themedStyle = StyleService.create({
  container: {
    width: 40,
    height: 40,
    marginHorizontal: 16,
  },
  initialsContainer: {
    width: '100%',
    height: '100%',
    borderRadius: 100,
    alignItems: 'center',
    justifyContent: 'center',
  },
  avatar: {
    height: '100%',
    width: '100%',
    borderRadius: 100,
  },
  initials: {
    color: 'theme.primary.dark',
  },
})
