/* eslint-disable max-len */
import * as React from 'react'
import Svg, { Rect, Path, G, Mask } from 'react-native-svg'

export const DialChartPreview = () => (
  <Svg width="58" height="43" viewBox="0 0 58 43">
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.1011 6.85046L16.2209 12.9703C23.5203 7.16361 33.9179 7.16361 41.2173 12.9697L47.3359 6.85104C36.6287 -2.28358 20.8077 -2.28358 10.1011 6.85046Z"
      fill="#4CD571"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M34.504 34.2786C34.5044 37.4521 31.9322 40.0243 28.7582 40.0243C25.5847 40.0247 23.0125 37.4525 23.0125 34.2786C23.0125 31.1055 28.7582 19.3394 28.7582 19.3394C28.7582 19.3394 34.504 31.1055 34.504 34.2786Z"
      fill="#383838"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M31.0154 36.0014C31.0149 37.2707 29.9858 38.2992 28.7166 38.2997C27.4473 38.2997 26.4188 37.2707 26.4188 36.0014C26.4182 34.7322 27.4473 33.7031 28.7171 33.7031C29.9858 33.7031 31.0154 34.7322 31.0154 36.0014Z"
      fill="#D6D6D5"
    />
    <Mask id="mask0_4096_41521" maskUnits="userSpaceOnUse" x="0" y="10" width="13" height="30">
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.8322 39.6357C7.19195 32.4726 7.57108 23.0065 12.97 16.2197L6.85247 10.1016C-0.272209 18.4524 -1.84164 29.9152 2.14664 39.6357H11.8322Z"
        fill="#DD4141"
      />
    </Mask>
    <G mask="url(#mask0_4096_41521)">
      <Rect x="-6.47339" y="30.749" width="23.4661" height="11.3285" fill="#DD4141" />
      <Rect x="-6.47339" y="9.70996" width="25.0845" height="21.0386" fill="#FFB114" />
    </G>
    <Mask id="mask1_4096_41521" maskUnits="userSpaceOnUse" x="43" y="10" width="14" height="30">
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M44.8334 39.6357C49.4736 32.4726 49.0944 23.0065 43.6955 16.2197L49.8131 10.1016C56.9377 18.4524 58.5072 29.9152 54.5189 39.6357H44.8334Z"
        fill="#DD4141"
      />
    </Mask>
    <G mask="url(#mask1_4096_41521)">
      <Rect
        width="23.4661"
        height="11.3285"
        transform="matrix(-1 0 0 1 63.1389 30.749)"
        fill="#DD4141"
      />
      <Rect
        width="25.0845"
        height="21.0386"
        transform="matrix(-1 0 0 1 63.1389 9.70996)"
        fill="#FFB114"
      />
    </G>
  </Svg>
)
