import React from 'react'
import { View } from 'react-native'
import { useNavigation } from '@react-navigation/core'
import { StackNavigationProp } from '@react-navigation/stack'
import { StyleService, useStyleSheet } from '@src/style/service'
import { AppStackParamList } from '@src/navigation/types'
import { MacroSlider } from '@components'
import { HighlightsCard } from '../Highlights/HighlightsCard'
import { useMacroData } from '../../utils/useMacroData'

export const NutritionSummaryCard = () => {
  const styles = useStyleSheet(themedStyles)
  const navigation = useNavigation<StackNavigationProp<AppStackParamList>>()

  const { values, goals, percents, showNetCarbs } = useMacroData()

  const handleMorePress = () => {
    navigation.navigate('NutritionSummaryModal')
  }

  return (
    <HighlightsCard onMorePress={handleMorePress} title="Daily Nutrition Summary">
      <View style={styles.macrosRow}>
        <MacroSlider
          macro="calories"
          value={values.calories}
          maxValue={goals.calories}
          percent={percents.calories}
          style={styles.macroSlider}
        />
        <MacroSlider
          macro={showNetCarbs ? 'netCarbs' : 'carbs'}
          value={showNetCarbs ? values.netCarbs : values.carbs}
          maxValue={showNetCarbs ? goals.netCarbs : goals.carbs}
          percent={showNetCarbs ? percents.netCarbs : percents.carbs}
          style={styles.macroSlider}
        />
      </View>
      <View style={styles.macrosRow}>
        <MacroSlider
          macro="protein"
          value={values.protein}
          maxValue={goals.protein}
          percent={percents.protein}
          style={styles.macroSlider}
        />
        <MacroSlider
          macro="fat"
          value={values.fat}
          maxValue={goals.fat}
          percent={percents.fat}
          style={styles.macroSlider}
        />
      </View>
    </HighlightsCard>
  )
}

const themedStyles = StyleService.create({
  macrosRow: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    marginBottom: 12,
    gap: 24,
  },
  macroSlider: {
    minWidth: 140,
    flex: 1,
  },
})
