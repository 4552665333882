import React from 'react'
import { Image, ImageStyle, ScrollView, View } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Text, Button, Icon } from '@components/base'
import { dangerSignImage, hourglassImage } from '@src/assets/images'
import { FlexView } from '@src/components/FlexView'
import { NavigationContainer } from '@screens/Common/containers'
import { useGoBack } from '@utils/navigation'
import { AppStackParamList } from '@src/navigation/types'

interface BaseSuccessContainerProps {
  navigationTitle: string
  title: string
  description: string
  timeSectionDescription: React.ReactChild
  dangerSectionDescription: React.ReactChild
  secondaryButton?: React.ReactNode
}

export const BaseSuccessContainer = (props: BaseSuccessContainerProps) => {
  const goBack = useGoBack()
  const navigation = useNavigation<StackNavigationProp<AppStackParamList>>()

  const styles = useStyleSheet(themedStyle)

  const {
    navigationTitle,
    title,
    description,
    timeSectionDescription,
    dangerSectionDescription,
    secondaryButton,
  } = props

  const handleExit = () => {
    if (!navigation.canGoBack()) {
      return navigation.replace('Drawer', { screen: 'Dashboard' })
    }

    goBack()
  }

  return (
    <NavigationContainer
      title={navigationTitle}
      leftIcon={{ pack: 'eva', name: 'close' }}
      style={styles.container}
      navigationBarProps={{
        backgroundColor: 'transparent',
      }}
      goBack={handleExit}
    >
      <ScrollView contentContainerStyle={styles.contentContainer} style={styles.container}>
        <FlexView>
          <Icon name="check-circle" weight="fill" style={styles.icon} />
          <Text type="title-2" style={styles.title}>
            {title}
          </Text>
          <Text type="regular" bold style={styles.text}>
            {description}
          </Text>
          <View style={styles.section}>
            <View style={styles.sectionImageContainer}>
              <Image source={hourglassImage.imageSource} style={styles.image as ImageStyle} />
            </View>
            <Text type="regular" style={styles.sectionDescription}>
              {timeSectionDescription}
            </Text>
          </View>
          <View style={styles.section}>
            <View style={styles.sectionImageContainer}>
              <Image source={dangerSignImage.imageSource} style={styles.image as ImageStyle} />
            </View>
            <Text type="regular" style={styles.sectionDescription}>
              {dangerSectionDescription}
            </Text>
          </View>
        </FlexView>
        <View>
          <Button
            type="primary"
            size="block"
            accessibilityLabel="Continue to the app"
            onPress={handleExit}
          >
            Continue To The App
          </Button>
          {secondaryButton}
        </View>
      </ScrollView>
    </NavigationContainer>
  )
}

const themedStyle = StyleService.create({
  container: {
    flex: 1,
    backgroundColor: 'theme.background',
  },
  contentContainer: {
    flex: 1,
    padding: 16,
    paddingBottom: 24,
  },
  icon: {
    alignSelf: 'center',
    width: 64,
    height: 64,
    marginBottom: 24,
    color: 'theme.success.base',
  },
  title: {
    marginBottom: 16,
    textAlign: 'center',
  },
  text: {
    textAlign: 'center',
    marginHorizontal: 24,
    marginBottom: 16,
  },
  image: {
    width: '100%',
    maxWidth: 600,
    height: undefined,
    maxHeight: 600,
    alignSelf: 'center',
    aspectRatio: 1,
  },
  sectionImageContainer: {
    width: 40,
    height: 40,
    marginRight: 16,
  },
  sectionDescription: {
    flexWrap: 'wrap',
    flex: 1,
  },
  section: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 32,
  },
})
