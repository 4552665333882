import { useSelector } from 'react-redux'
import { useMemo } from 'react'
import {
  onboardingSkippedSelector,
  uiStatesSelector,
  uxSensorModeKindSelector,
} from '@src/selectors/app'
import { UiStateNames, UserExperienceSensorModeKind } from '@src/types'
import { TutorialSensorKind, TutorialGroups } from '@src/screens/Tutorials/models/tutorials.types'
import { Feature, useFeatureFlag } from '@src/components'
import { isByosOnboardingSensorMode } from '@src/screens/OwnSensorOnboarding/utils'
import { useHasOwnSensorSubscription } from '@src/hooks/useHasOwnSensorSubscription'

export const useShouldShowByosOnboardingTutorials = () => {
  const onboardingSkipped = useSelector(onboardingSkippedSelector)
  const shouldShowByosOnboarding = useShouldShowByosOnboarding()
  const uiStates = useSelector(uiStatesSelector)

  if (!shouldShowByosOnboarding || onboardingSkipped) {
    return false
  }

  const tutorialCompletedUiState = uiStates.find(
    (uiState) => uiState.name === UiStateNames.OwnSensorTutorialCompleted,
  )
  const tutorialCompleted = tutorialCompletedUiState?.value || false

  return !tutorialCompleted
}

export const useShouldShowByosOnboarding = () => {
  const onboardingSkipped = useSelector(onboardingSkippedSelector)
  const uxSensorModeKind = useSelector(uxSensorModeKindSelector)
  const uiStates = useSelector(uiStatesSelector)
  const byosOnboardingEnabled = useFeatureFlag(Feature.ByosOnboarding)
  const hasOwnSensorSubscription = useHasOwnSensorSubscription()

  if (
    !byosOnboardingEnabled ||
    onboardingSkipped ||
    !hasOwnSensorSubscription ||
    !isByosOnboardingSensorMode(uxSensorModeKind)
  ) {
    return false
  }

  const onboardingCompletedUiState = uiStates.find(
    (uiState) => uiState.name === UiStateNames.OwnSensorOnboardingCompleted,
  )
  const onboardingCompleted = onboardingCompletedUiState?.value || false

  return !onboardingCompleted
}

export const useByosOnboardingTutorial = (): {
  group: TutorialGroups
  sensorKind?: TutorialSensorKind
} => {
  const uxSensorModeKind = useSelector(uxSensorModeKindSelector)
  const byosOnboardingSensorMode = isByosOnboardingSensorMode(uxSensorModeKind)
  const scanDisabledLibre1And2 = useFeatureFlag(Feature.ScanDisableFullLibre1And2)
  const sensorModeTutorialsMap = useGetSensorModeTutorials(scanDisabledLibre1And2)
  if (!byosOnboardingSensorMode) {
    return { group: TutorialGroups.NoTutorialGroup }
  }

  return sensorModeTutorialsMap[uxSensorModeKind] || { group: TutorialGroups.NoTutorialGroup }
}

const useGetSensorModeTutorials = (
  scanDisabledLibre1And2: boolean,
): Partial<
  Record<UserExperienceSensorModeKind, { group: TutorialGroups; sensorKind?: TutorialSensorKind }>
> => {
  return useMemo(
    () => ({
      [UserExperienceSensorModeKind.OwnSensorDexcomG6]: {
        group: TutorialGroups.Dexcom,
        sensorKind: TutorialSensorKind.DexcomG6,
      },
      [UserExperienceSensorModeKind.OwnSensorDexcomG7]: {
        group: TutorialGroups.Dexcom,
        sensorKind: TutorialSensorKind.DexcomG7,
      },
      [UserExperienceSensorModeKind.OwnSensorDexcomStelo]: {
        group: TutorialGroups.Dexcom,
        sensorKind: TutorialSensorKind.DexcomStelo,
      },
      [UserExperienceSensorModeKind.OwnSensorLibre1]: scanDisabledLibre1And2
        ? { group: TutorialGroups.LibreLinkup }
        : { group: TutorialGroups.LibreNative },
      [UserExperienceSensorModeKind.OwnSensorLibre2]: scanDisabledLibre1And2
        ? { group: TutorialGroups.LibreLinkup }
        : { group: TutorialGroups.LibreNative },
      [UserExperienceSensorModeKind.OwnSensorLibre3]: { group: TutorialGroups.LibreNative },
    }),
    [scanDisabledLibre1And2],
  )
}

const SCANNABLE_SENSOR_MODES = [
  UserExperienceSensorModeKind.OwnSensorLibre1,
  UserExperienceSensorModeKind.OwnSensorLibre2,
  UserExperienceSensorModeKind.OwnSensorLibre3,
]
export const useIsByosScannableSensorMode = () => {
  const uxSensorModeKind = useSelector(uxSensorModeKindSelector)
  const scanDisabledLibre1And2 = useFeatureFlag(Feature.ScanDisableFullLibre1And2)

  if (!uxSensorModeKind) {
    return false
  }

  if (scanDisabledLibre1And2) {
    return uxSensorModeKind === UserExperienceSensorModeKind.OwnSensorLibre3
  }

  return SCANNABLE_SENSOR_MODES.includes(uxSensorModeKind)
}

const LINKUP_SENSOR_MODES = [
  UserExperienceSensorModeKind.OwnSensorLibre1,
  UserExperienceSensorModeKind.OwnSensorLibre2,
]

export const useIsByosLinkupSensorMode = () => {
  const uxSensorModeKind = useSelector(uxSensorModeKindSelector)

  if (!uxSensorModeKind) {
    return false
  }

  return LINKUP_SENSOR_MODES.includes(uxSensorModeKind)
}
