import { RouteProp } from '@react-navigation/core'
import { ParamListBase } from '@react-navigation/native'
import { ActivityType, Meal, Measurement } from '@src/screens/Events/models/events.types'
import { BaseIngredient } from '@src/screens/Ingredients/types'
import { ShareableViews } from '@src/containers/ShareViewModal/constants'
import {
  ProductSelection,
  GroupedProducts,
  CheckoutKind,
} from '@src/screens/Marketplace/types/types'
import { ChartTypes } from '@src/components/Timeline/constants'
import {
  Activity,
  BillingProduct,
  BillingProductCategory,
  EducationArticle,
  EhrAppointmentRecurrence,
  HealthDataMeasurementSource,
  HealthDataMeasurementType,
  InsuranceBerryStreetAppointmentChargeType,
  InsuranceBerryStreetAppointmentType,
  InsurancePolicy,
  JournalEntry,
  LegalPolicy,
  MealKind,
  Outcome,
  Product,
  Reminder,
  ReminderKind,
  SensorModel,
  Statistic,
  Subscription,
  SubscriptionCheckoutOptionKind,
  SurveysConfigKind,
  TutorialStep,
  UiInlineAlert,
  UserSensorKind,
} from '@src/types'
import { ProgramType } from '@src/screens/Questionnaire/screens/GoalsQuestionnaireStartScreen'
import { LessonWithContent } from '@src/screens/Learn/models/courses.types'
import { QuestionId } from '@src/screens/Questionnaire/QuestionnaireConfigInteractor'
import { ThresholdType } from '@src/screens/Settings/models/settings.types'
import { DateSelectorType } from '@components'
import {
  GlucoseKetonesEnum,
  ScoreSectionEnum,
  SectionEnum,
  MacrosSectionEnum,
  MeasurementEnum,
} from '@src/screens/Insights/utils/pageConfig'
import { AddressType } from '@src/screens/Marketplace/components/checkout/AddressForm'
import { TutorialGroups } from '@src/screens/Tutorials/models/tutorials.types'
import { ReactNativeFile } from '@src/utils/image'
import { MeasurementValues } from '@src/screens/Measurements/types'
import { TERRA_GOOGLE_FIT_NAVIGATION_SOURCES } from '@src/screens/Events/utils/useHealthPermissionScreenProps'

export type ReferralParams = {
  source: 'Onboarding' | 'Drawer' | 'Account'
}

type WithCancelModal = {
  cancel?: boolean
}

type WithPhotoUpload = {
  photo?: ReactNativeFile
}

type WithForceLandscapeOrientation = {
  forceLandscapeOrientation?: boolean
}

export interface CoursesModuleParams {
  programId: string
  moduleId: string
  locked: boolean
}

export type CommonStackParamList = {
  Camera: {
    parentScreen: 'AddMeal' | 'EditMeal' | 'SignUp' | 'SubmitInsuranceCardScreen'
    parentParams?: any
  }
}

type WithInlineAlert = {
  inlineAlert?: {
    type: UiInlineAlert
    message: string
  }
}

type MapRouteToScreenAndProps<T extends ParamListBase> = {
  [K in keyof T]: T[K] extends undefined
    ? { screen: K; params?: never }
    : { screen: K; params: T[K] }
}

export type ScreenAndProps<
  T extends ParamListBase = RootStackParamList
> = MapRouteToScreenAndProps<T>[keyof MapRouteToScreenAndProps<T>]

export type CommonAuthorizedStackParamList = {
  AddonCheckout: {
    selections: {
      0: ProductSelection
    } & ProductSelection[]
  }
  DietitianMenuCheckout: {
    groupedProducts?: GroupedProducts
    selection?: ProductSelection
    goBackOnSuccess?: boolean
  }
  CheckoutWithInsuranceOption: {
    product: Product
  }
  DietitianMenuGroupProductSelector: {
    groupedProducts: GroupedProducts
    selection?: ProductSelection
  }
  DietitianMenuFeedback: undefined
  ChangeAddress: { addressType: AddressType }
  CreatePaymentMethod: undefined
  DiscountCode: { product?: BillingProduct }
  EligibilityRejection: undefined
  SensorSelection:
    | undefined
    | {
        product: Product
        savings?: number
        requireEligibilityCheck: boolean
        sensorOptions?: SensorModel[]
      }
}

export type AuthStackParamList = {
  SignIn: undefined
  SignUp: WithPhotoUpload | undefined
  SignInOptions: undefined
  PasswordResetRequest: undefined
  PasswordResetRequestSuccess: { email: string } | undefined
  PasswordReset: { passwordToken: string; email: string } | undefined
  ShowAdmin: undefined
}

export type DashboardStackParamsList = {
  Events: undefined
}

export type OnboardingStackParamsList = {
  SelectPlan: undefined
  SelectSubscription:
    | {
        productGroupId?: string
        requireEligibilityCheck?: boolean
      }
    | undefined
  EligibilityCheck: {
    product: Product
    savings?: number
    checkoutOptionKinds?: SubscriptionCheckoutOptionKind[]
    nextScreen?: { screen: 'Questionnaire'; params: any }
  }
  SubscriptionCheckout: {
    product: Product
    subscriptionId?: string
    savings?: number
    trialEndsAt?: string | null
    checkoutKind?: CheckoutKind
    checkoutOptionKinds?: SubscriptionCheckoutOptionKind[]
  }
  StartPurchaseFlow: undefined
  ProductsLoading: undefined
  FreeTrialSubscriptionOffer: { product: Product; subscription?: Subscription }
  SensorSelection:
    | undefined
    | {
        product: Product
        savings?: number
        requireEligibilityCheck: boolean
        sensorOptions?: SensorModel[]
      }
  ConfirmSensorSelection: undefined
  HealthPermission: undefined
  TutorialsGroup: {
    group: TutorialGroups
  }
  CMSTutorial: {
    tutorialId: string
  }
  TerraProvider: {
    provider: string
    source?: TERRA_GOOGLE_FIT_NAVIGATION_SOURCES
    resource?: string
    reason?: string
  } & WithCancelModal
  CheckConnection: undefined
  AddScan: {
    action: 'scan' | 'activate'
  }
  ConnectionSuccessful: undefined
  IntegrationSuccessful: undefined
  LibreLinkupIntegration: undefined
  LibreAuth: undefined
}

export type SubscriptionsStackParamsList = {
  Marketplace: WithCancelModal | undefined
  SelectPlan: undefined
  SelectSubscription:
    | {
        productGroupId?: string
        requireEligibilityCheck?: boolean
      }
    | undefined
  EligibilityCheck: {
    product: Product
    savings?: number
    checkoutOptionKinds?: SubscriptionCheckoutOptionKind[]
    nextScreen?: { screen: 'Questionnaire'; params: any }
  }
  SensorSelection:
    | undefined
    | {
        product: Product
        savings?: number
        requireEligibilityCheck: boolean
        sensorOptions?: SensorModel[]
      }
}

export type DetailedPageUrlParams = {
  statSection: SectionEnum
  statMetaKey: ScoreSectionEnum | GlucoseKetonesEnum | MacrosSectionEnum | MeasurementEnum
  startDate: string
  endDate: string
}
export type InsightsStackParamsList = {
  InsightsDashboard: undefined
  InsightsDetailedPage:
    | { statistic: Statistic }
    | {
        statSection: SectionEnum
        statMetaKey: ScoreSectionEnum | GlucoseKetonesEnum | MacrosSectionEnum | MeasurementEnum
        startDate: string
        endDate: string
      }
  InsightsSettings: undefined
  ChooseGoalModal: undefined
  ChoosePrimaryGoalMetricsModal: undefined
}

export type TabParamList = {
  Events: undefined
  History: { resetFilters?: boolean } | undefined
  MultiBar: undefined
  Insights: undefined
  Learn: undefined
  NutritionistHub: WithInlineAlert | undefined
}

export type DrawerParamList = {
  Dashboard: { screen: 'Dashboard' | 'Events' } | undefined
  History: { resetFilters?: boolean } | undefined
  Education: undefined
  Account: (WithCancelModal & WithPhotoUpload) | undefined
  Settings: undefined
  Referral: ReferralParams
  Subscriptions: { screen: 'Marketplace' } | undefined
  Support: undefined
  Insights: undefined
  NutritionistHub: WithInlineAlert | undefined
  FreeTrialSubscriptionOffer: { product: Product; subscription?: Subscription }
  Learn: undefined
}

export interface SubscriptionParams {
  subscriptionId?: string
}

export type AppStackParamList = {
  // onboarding
  Onboarding: {
    screen: string
    params: ScreenAndProps
  }

  // auth
  Auth: undefined

  Walkthrough: undefined

  AlmostThere: undefined

  Scans: undefined

  BluetoothPermissionModal: undefined

  ErrorScanningModal:
    | {
        parentScreen: 'CoursesModule'
        parentScreenParams: CoursesModuleParams
      }
    | {
        parentScreen?: 'Education' | 'EducationModal' | 'SensorSettings' | 'Drawer'
        parentScreenParams?: { screen: 'Dashboard' }
      }

  ConnectivityErrorModal: { error: 'internet' | 'bluetooth' }

  CheckConnection: undefined

  // other
  Drawer:
    | {
        screen: 'Dashboard'
        params?: { screen: 'Events' | 'NutritionistHub' }
      }
    | undefined
  AppSettings: undefined
  FriendReferral: ReferralParams
  Subscriptions: { screen: 'Marketplace' } | undefined
  ChangeDateRange: { type: DateSelectorType; allowRangeSelection?: boolean }
  AddJournalEntry: {
    item?: JournalEntry
    id?: string
    body: string
    occurredAt?: string
  } & WithCancelModal
  EditJournalEntry: {
    item: JournalEntry
  } & WithCancelModal
  AddScan:
    | {
        action: 'activate'
        parentScreen: 'CoursesModule'
        parentScreenParams: CoursesModuleParams
      }
    | {
        action: 'scan' | 'activate'
        parentScreen?: 'Education' | 'EducationModal' | 'SensorSettings' | 'Drawer'
        parentScreenParams?: { screen: 'Dashboard' }
      }
    | undefined
  CGMActivated: undefined
  OrderConfirmed: undefined
  DietitianAddonOrderConfirmed: {
    product: Product
    groupedProducts?: GroupedProducts
    goBackOnSuccess?: boolean
    suggestedCallFlow?: boolean
  }
  AddActivity:
    | ({
        item?: Activity
        activityType?: ActivityType
      } & WithCancelModal)
    | undefined
  ActivityDetail: { item: Activity }
  ActivitySource: undefined
  Marketplace: WithCancelModal | undefined

  /* Meals */
  AddMeal?: {
    shouldGoBack?: boolean
    mealKind?: MealKind
  } & WithPhotoUpload &
    WithCancelModal
  EditMeal: {
    id?: string
  } & WithPhotoUpload &
    WithCancelModal
  MealDetail: {
    item: Meal
  }
  CompareMeals: {
    initialMealIds: string
  }
  CompareMealsDetails: {
    mealIds: string
  }
  UnlockNutritionistSupport: undefined
  ShareMealComparison: {
    mealIds: string
  }
  CustomIngredient:
    | ({
        ingredientId?: string
        name?: string
      } & WithCancelModal)
    | undefined
  IngredientSearch: {
    createdIngredientId?: string
    mealType?: MealKind
  } & WithCancelModal
  BarcodeIngredientSearch: undefined
  IngredientPortion: {
    ingredient: BaseIngredient
    isUpdatingIngredient?: boolean
  }

  AddMeasurement:
    | ({
        item?: {
          externalSource?: HealthDataMeasurementSource
          id?: string
          occurredAt?: string
          type: HealthDataMeasurementType
          values?: MeasurementValues
        }
        type?: HealthDataMeasurementType
      } & WithCancelModal)
    | undefined
  EditMeasurement: { item: Measurement } & WithCancelModal
  EditDailyMeasurementModal: { date: string; type: HealthDataMeasurementType }
  SensorWarmUpModal: undefined
  ShowActions: undefined
  IntercomMenu: undefined
  ShowTutorial: { tutorial: TutorialStep[] } | undefined
  ShowHorizontalChart: { chartScript?: string; title: string } & WithForceLandscapeOrientation
  ZoomChart: { type: ChartTypes } & WithForceLandscapeOrientation
  GlucoseScoreModal: undefined
  NutritionSummaryModal: undefined
  TimelineTutorialModal: { timeline: 'Glucose' | 'Ketone' }
  NutritionDetails: undefined
  CalibrateSensor: undefined
  ChangeMacroGoals: { source: keyof RootStackParamList }
  ChangeFastingWindow: undefined
  ShareViewModal: {
    shareableViewName: ShareableViews
    item: Meal | Activity
  }
  AdminSettings: undefined
  DashboardEdit: undefined
  HistoryEdit: undefined
  AccountSubscriptions: undefined
  EditAccountDetails: WithCancelModal | undefined
  ChangePassword: undefined
  ResearchSurveys: undefined
  AddLabResults: undefined
  LabResultsSubmittedScreen: undefined
  LabResultsScreen: undefined
  BiomarkerForm: {
    id?: string
    biomarkerName: string
    biomarkerKind: string
    value?: number
    date?: string
    unit?: string
  }
  OnboardingChecklist: undefined
  ProductsSelection: {
    addons: Product[]
    selectedAddon: Product
  }
  ProgramDetails: SubscriptionParams
  CancelSubscriptionOtherOptions: SubscriptionParams
  CancelSubscriptionOnCommitment: SubscriptionParams
  CancelSubscriptionSwitchSensor: SubscriptionParams & {
    newSensorKind: UserSensorKind
  }
  SensorSwitchConfirmed: { newSensorKind: UserSensorKind }
  UpgradeToDexcomSensor: undefined
  CancelSubscriptionConfirmation: SubscriptionParams & {
    showInsuranceCallsUpsell?: string
  }
  ChurnRecoveryOptions: SubscriptionParams & {
    billingProductCategory: BillingProductCategory
    title: string
    description: string
    notes: string
    upsellOfferLink: string
  }
  ChurnRecoveryUpsellOffer: SubscriptionParams & {
    billingProduct: BillingProduct
    title: string
    description: string
    billingProductPrice: string
    billingProductIntervalDescription: string
    offerPillText: string
    offerHeaderText: string
    offerSubheaderText: string
    offerFooterText: string
    showCommitmentDisclaimer: boolean
  }
  ChurnRecoveryUpsellOfferConfirmation: {
    subscriptionId: string
    billingProduct: BillingProduct
    billingProductPrice: string
    billingProductIntervalDescription: string
  }
  InsuranceCallsUpsellScreen: {
    showSuccessAlert?: boolean
  }
  SubscriptionCheckout: {
    product: Product
    subscriptionId?: string
    savings?: number
    trialEndsAt?: string | null
    checkoutKind?: CheckoutKind
    checkoutOptionKinds?: SubscriptionCheckoutOptionKind[]
  }
  PauseSubscription: SubscriptionParams
  PauseSubscriptionReview: SubscriptionParams & {
    cycleCount: number
  }
  PauseSubscriptionSuccess: undefined
  ResumeSubscriptionModal: undefined
  ReactivateSubscriptionModal: SubscriptionParams
  DexcomResearchOptedOutModal: undefined
  ReadyToParticipateScreen: undefined
  ActivationInfoScreen: undefined
  RemainingSensorsQuestionScreen: undefined
  FinalizePrescriptionDexcomSwitchScreen: undefined
  SwitchToDexcomNotSuccessfulScreen: undefined
  ChangeBillingCycleModal: SubscriptionParams
  ChangeBillingCycleSuccessModal: SubscriptionParams
  ManageUpcomingSubscriptionModal: undefined
  UserSettings: undefined
  SensorSettings: { showSensorWarmUpModal: boolean } | undefined
  NotificationsSettings: undefined
  AddReminderModal: undefined
  EditReminderModal: {
    reminder: Reminder
    reminderConfig: ReminderKind
  }
  EditScanReminderModal: undefined
  ThresholdSettings: { type: ThresholdType; source: keyof RootStackParamList }
  GlucoseThresholdSettings: { source: keyof RootStackParamList }
  Integrations: WithCancelModal | undefined
  TerraProvider: {
    provider: string
    source?: TERRA_GOOGLE_FIT_NAVIGATION_SOURCES
    resource?: string
    reason?: string
  } & WithCancelModal
  LibreLinkupIntegration: WithCancelModal | undefined
  LibreAuth: undefined
  IntegrationSuccessful: undefined
  SourcePriorityList: {
    measurementType: HealthDataMeasurementType
  }
  TutorialsGroup: {
    group: TutorialGroups
  }
  ActivateSensorTutorialsGroup: {
    group: TutorialGroups
  }
  CMSTutorial: {
    tutorialId: string
  }
  VideoTutorial: {
    url: string
    title: string
    description: string
  }
  HealthKitIntegration: undefined
  KetoMojoIntegration: undefined
  EducationModal: undefined
  RefillConsent: undefined
  RequiredPolicyConsent: { nextScreen?: ScreenAndProps }
  PolicyConsent: {
    policy: LegalPolicy
    nextScreen?: ScreenAndProps
  }
  SkipTutorial: undefined
  PermissionsCarousel: undefined
  CGMEducation:
    | {
        parentScreen: 'CoursesModule'
        parentScreenParams: CoursesModuleParams
      }
    | { parentScreen: 'Education' | 'EducationModal' | 'SensorSettings' }
  GlucoseEducation: undefined
  Articles: {
    articles: EducationArticle[]
  }
  FAQs: undefined
  FAQsArticles: {
    articles: EducationArticle[]
  }
  SensorInfoModal: undefined
  CancelModal: {
    title: string
    cancelText?: string
    confirmText?: string
    parentScreen:
      | 'AddActivity'
      | 'AddMeal'
      | 'EditMeal'
      | 'CustomIngredient'
      | 'AddMeasurement'
      | 'IngredientSearch'
      | 'Marketplace'
      | 'Integrations'
      | 'TerraProvider'
      | 'LibreLinkupIntegration'
      | 'EditAccountDetails'
    confirmScreen?: 'CustomIngredient' | 'IngredientSearch'
    confirmScreenParams?: any
    cancelScreen?: 'BarcodeIngredientSearch' | 'IngredientSearch'
    cancelScreenParams?: any
    replace?: boolean
  }
  RateOurAppModal: undefined
  Questionnaire: {
    questionnaire: SurveysConfigKind
    nextScreen?: ScreenAndProps
    allowEarlyExit?: boolean
    showCompletedScreen?: boolean
    showEligibilitySuccessNote?: boolean
    skipIntroScreen?: boolean
  }
  CoursesLearn: undefined
  CoursesProgram?: {
    programId: string
  }
  CoursesModule: CoursesModuleParams
  CoursesLesson: {
    lesson: LessonWithContent
    parentScreenParams: CoursesModuleParams
  }
  CoursesLessonItem: {
    lesson: LessonWithContent
    index: number
    parentScreenParams: CoursesModuleParams
  }
  CoursesModuleCompletion: {
    title: string
    isProgramComplete: boolean
    nextModuleParams: CoursesModuleParams
  }
  CoursesExperiment: {
    lesson: LessonWithContent
    parentScreenParams: CoursesModuleParams
  }
  CoursesSensorActivation: CoursesModuleParams
  JournalEngagementPrompt: undefined
  WebViewModal: {
    uri: string
    customTitle?: string
  }
  Chat:
    | {
        cannedPromptKey?: string
      }
    | undefined
  ChatWelcomeModal: undefined
  ChatDelimiterConfirmationModal: {
    channelId: string
  }
  HQApproval: undefined
  CallScheduling: {
    allowBackNavigation?: boolean
    appointmentType: InsuranceBerryStreetAppointmentType
    appointmentId?: string
    pendingAppointmentId?: string
    errorMessage?: string
  }
  VideoCallConfirmedModal: {
    id: string
    title: string
    nutritionistName?: string | null
    date: string
    addToGoogleCalendarLink?: string | null
    unauthenticatedIcsLink?: string | null
    appointmentType: InsuranceBerryStreetAppointmentType
    chargeType?: InsuranceBerryStreetAppointmentChargeType | null
  }
  VideoCallInReviewModal: {
    title: string
    nutritionistName?: string | null
    date: string
  }
  RescheduleOrCancelVideoCallModal: {
    videoCallId: string
    appointmentType: InsuranceBerryStreetAppointmentType
    nutritionistName?: string | null
  }
  LateRescheduleOrCancelVideoCallModal: {
    videoCallId: string
    appointmentType: InsuranceBerryStreetAppointmentType
    nutritionistName?: string | null
  }
  FreePassLateRescheduleOrCancelVideoCallModal: {
    videoCallId: string
    appointmentType: InsuranceBerryStreetAppointmentType
    nutritionistName?: string | null
  }
  LateRescheduleVideoCallModal: {
    videoCallId: string
    appointmentType: InsuranceBerryStreetAppointmentType
  }
  FreePassLateRescheduleVideoCallModal: {
    videoCallId: string
    appointmentType: InsuranceBerryStreetAppointmentType
    nutritionistName?: string | null
  }
  VideoCallsIntroModal: undefined
  FeePaymentSuccessfulModal: undefined
  FeePaymentIncompleteModal: undefined
  SetReminderModal: {
    dismissButtonText: string
    goBackOnDismiss: boolean
  }
  PaymentFailed: {
    videoCallId: string
    feeProduct: Product | undefined
    appointmentType: InsuranceBerryStreetAppointmentType
  }
  FeePaymentScreen: {
    videoCallId: string
    feeProduct: Product | undefined
    appointmentType: InsuranceBerryStreetAppointmentType
  }
  VideoCallsGettingStartedScreen: undefined
  PaidCallsIntroScreen: undefined
  BookingConfirmationScreen: {
    date: string
  }
  VideoCallCheckout: {
    suggestedCallFlow?: boolean
  }
  ClaimFreeVideoCallModal: {
    appointmentId: string
    appointmentType: InsuranceBerryStreetAppointmentType
  }
  InsuranceNotEligibleModal: undefined
  ActionBottomSheet: undefined
  ScheduleCallOrPurchasePlanModal: {
    appointmentType: InsuranceBerryStreetAppointmentType
  }
  MonthlyNutritionCoachingCheckout: undefined
  FreeVideoCall: undefined
  MemberNoteDetail: {
    noteId: string
  }
  MemberNotes: undefined
  AllProducts: undefined
  VideoCallsUpsellModal: undefined
  VideoCallsUpsellDetailsModal: {
    details: { title?: string; body: string }[]
    description: { emphasis?: string; body: string }
  }
  AllVideoCalls: undefined
  EditDailyActionsModal: undefined
  UpsertRecurrence: {
    appointmentRecurrence: EhrAppointmentRecurrence | null
    insurancePolicy: InsurancePolicy
  }
  InsuranceStatus: undefined
  UpsertRecurrenceSuccessfulModal: undefined
  VideoCallCheckIn: {
    appointmentId: string
  }
  VideoCallCheckInAddendum: {
    appointmentId: string
  }
  CheckInCompletedModal: undefined
  SubmitInsuranceCardScreen: undefined
  History: { resetFilters?: boolean } | undefined
  ScanMigrationGuide:
    | undefined
    | { parentScreen: 'CoursesModule'; parentScreenParams: CoursesModuleParams }
    | {
        parentScreen?: 'Education' | 'EducationModal' | 'SensorSettings' | 'Drawer'
        parentScreenParams?: { screen: 'Dashboard' }
      }
  EligibilityCheck: {
    product: Product
    checkoutOptionKinds?: SubscriptionCheckoutOptionKind[]
    savings?: number
    nextScreen?: { screen: 'Questionnaire'; params: any }
  }
  InsurancePolicyForm: undefined
}

export enum QuestionnaireScreenName {
  GoalsQuestionnaireStart = 'GoalsQuestionnaireStart',
  InsuranceQuestionnaireStart = 'InsuranceQuestionnaireStart',
  QuestionnaireSectionStart = 'QuestionnaireSectionStart',
  QuestionnaireQuestion = 'QuestionnaireQuestion',
  QuestionnaireCompleted = 'QuestionnaireCompleted',
  QuestionnaireLoading = 'QuestionnaireLoading',
  HealthQuestionnaireRejectionReason = 'HealthQuestionnaireRejectionReason',
  HealthQuestionnaireWarningModal = 'HealthQuestionnaireWarningModal',
  QuestionnaireRejection = 'QuestionnaireRejection',
  InsuranceQuestionnaireCompleted = 'InsuranceQuestionnaireCompleted',
  InsuranceEarlyExitCompleted = 'InsuranceEarlyExitCompleted',
}

export type QuestionnaireStackParamList = {
  [QuestionnaireScreenName.GoalsQuestionnaireStart]:
    | {
        programType: ProgramType
      }
    | undefined
  [QuestionnaireScreenName.HealthQuestionnaireRejectionReason]: { outcome: Outcome }
  [QuestionnaireScreenName.QuestionnaireSectionStart]: undefined
  [QuestionnaireScreenName.QuestionnaireQuestion]: QuestionId
  [QuestionnaireScreenName.QuestionnaireCompleted]:
    | undefined
    | { nextScreen?: string; nextScreenParams?: any }
  [QuestionnaireScreenName.QuestionnaireLoading]: {
    error: boolean
    errorMessage?: string
  }
  [QuestionnaireScreenName.HealthQuestionnaireWarningModal]: undefined
  [QuestionnaireScreenName.QuestionnaireRejection]: {
    outcome: Outcome
    nextScreen?: string
    nextScreenParams?: any
  }
  [QuestionnaireScreenName.InsuranceQuestionnaireCompleted]:
    | undefined
    | { nextScreen?: string; nextScreenParams?: any }
  [QuestionnaireScreenName.InsuranceEarlyExitCompleted]: undefined
  [QuestionnaireScreenName.InsuranceQuestionnaireStart]:
    | { allowEarlyExit: boolean; showEligibilitySuccessNote: boolean }
    | undefined
}

export type RootStackParamList = CommonStackParamList &
  CommonAuthorizedStackParamList &
  AuthStackParamList &
  DashboardStackParamsList &
  OnboardingStackParamsList &
  TabParamList &
  DrawerParamList &
  AppStackParamList &
  QuestionnaireStackParamList &
  InsightsStackParamsList

export type DrawerRouteProp<T extends keyof DrawerParamList> = RouteProp<DrawerParamList, T>
export type AppRouteProp<T extends keyof AppStackParamList> = RouteProp<AppStackParamList, T>
export type InsightsRouteProp<T extends keyof InsightsStackParamsList> = RouteProp<
  InsightsStackParamsList,
  T
>

export type CommonAuthorizedRouteProps<T extends keyof CommonAuthorizedStackParamList> = RouteProp<
  CommonAuthorizedStackParamList,
  T
>
export type AuthRouteProp<T extends keyof AuthStackParamList> = RouteProp<AuthStackParamList, T>
