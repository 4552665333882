import { Model } from '@models'
import ACTIVATE_PRESCRIPTION_DEXCOM from '../graphql/activatePrescriptionDexcom'

export default class PrescriptionDexcom {
  namespace = 'prescriptionDexcom'

  state = {
    ...Model.defaultState,
  }

  effects = {
    activatePrescriptionDexcom: Model.buildEffect({
      name: `${this.namespace}/activatePrescriptionDexcom`,
      query: ACTIVATE_PRESCRIPTION_DEXCOM,
      caching: false,
      dataPath: 'activatePrescriptionDexcom',
    }),
  }
}
