export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = {
  [_ in K]?: never
}
export type Incremental<T> =
  | T
  | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string }
  String: { input: string; output: string }
  Boolean: { input: boolean; output: boolean }
  Int: { input: number; output: number }
  Float: { input: number; output: number }
  /** An ISO 8601-encoded date */
  ISO8601Date: { input: any; output: any }
  /** An ISO 8601-encoded datetime */
  ISO8601DateTime: { input: any; output: any }
  /** Represents untyped JSON */
  JSON: { input: any; output: any }
  Upload: { input: any; output: any }
}

/** Autogenerated return type of ActivatePrescriptionDexcomAction. */
export type ActivatePrescriptionDexcomActionPayload = {
  __typename?: 'ActivatePrescriptionDexcomActionPayload'
  success: Scalars['Boolean']['output']
}

export type ActivationData = {
  calibrationInfo?: InputMaybe<CalibrationInfo>
  maxLifeInMinutes?: InputMaybe<Scalars['Int']['input']>
  model: Scalars['String']['input']
  serialNumber: Scalars['String']['input']
  startTime?: InputMaybe<Scalars['Int']['input']>
  status?: InputMaybe<Scalars['String']['input']>
}

export type Activity = {
  __typename?: 'Activity'
  allDay: Scalars['Boolean']['output']
  autoGenerated: Scalars['Boolean']['output']
  createdAt: Scalars['ISO8601DateTime']['output']
  description: Scalars['String']['output']
  endedAt: Scalars['ISO8601DateTime']['output']
  externalSource?: Maybe<Scalars['String']['output']>
  favorite: Scalars['Boolean']['output']
  glucose: EntryGlucose
  id: Scalars['ID']['output']
  length: Scalars['Int']['output']
  occurredAt: Scalars['ISO8601DateTime']['output']
  startedAt: Scalars['ISO8601DateTime']['output']
  statistics: EntryStatistics
  status?: Maybe<Scalars['String']['output']>
  title: Scalars['String']['output']
  type: Scalars['String']['output']
}

export type ActivityCollection = {
  __typename?: 'ActivityCollection'
  activities: Array<Activity>
}

export type ActivityFilter = {
  endDate?: InputMaybe<Scalars['String']['input']>
  endHour?: InputMaybe<Scalars['Int']['input']>
  excludeTypes?: InputMaybe<Array<Scalars['String']['input']>>
  favorite?: InputMaybe<Scalars['Boolean']['input']>
  graph?: InputMaybe<Scalars['String']['input']>
  includeTypes?: InputMaybe<Array<Scalars['String']['input']>>
  order?: InputMaybe<Scalars['String']['input']>
  orderBy?: InputMaybe<Scalars['String']['input']>
  query?: InputMaybe<Scalars['String']['input']>
  scope?: InputMaybe<Scalars['String']['input']>
  startDate?: InputMaybe<Scalars['String']['input']>
  startHour?: InputMaybe<Scalars['Int']['input']>
  types?: InputMaybe<Array<KeyValueStringArray>>
}

export type AddonLimit = {
  __typename?: 'AddonLimit'
  description?: Maybe<Scalars['String']['output']>
  limit?: Maybe<Scalars['Int']['output']>
  productId: Scalars['ID']['output']
  purchasedAmount: Scalars['Int']['output']
}

export type Address = {
  __typename?: 'Address'
  city: Scalars['String']['output']
  country?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  state?: Maybe<AddressState>
  street: Scalars['String']['output']
  street2: Scalars['String']['output']
  zipCode: Scalars['String']['output']
}

export enum AddressCountries {
  Ca = 'CA',
  Gb = 'GB',
  Us = 'US',
}

export enum AddressState {
  Aa = 'AA',
  Ab = 'AB',
  Ae = 'AE',
  Ak = 'AK',
  Al = 'AL',
  Ap = 'AP',
  Ar = 'AR',
  As = 'AS',
  Az = 'AZ',
  Bc = 'BC',
  Ca = 'CA',
  Co = 'CO',
  Ct = 'CT',
  Dc = 'DC',
  De = 'DE',
  Fl = 'FL',
  Fm = 'FM',
  Ga = 'GA',
  Gu = 'GU',
  Hi = 'HI',
  Ia = 'IA',
  Id = 'ID',
  Il = 'IL',
  In = 'IN',
  Ks = 'KS',
  Ky = 'KY',
  La = 'LA',
  Ma = 'MA',
  Mb = 'MB',
  Md = 'MD',
  Me = 'ME',
  Mh = 'MH',
  Mi = 'MI',
  Mn = 'MN',
  Mo = 'MO',
  Mp = 'MP',
  Ms = 'MS',
  Mt = 'MT',
  Nb = 'NB',
  Nc = 'NC',
  Nd = 'ND',
  Ne = 'NE',
  Nh = 'NH',
  Nj = 'NJ',
  Nl = 'NL',
  Nm = 'NM',
  Ns = 'NS',
  Nt = 'NT',
  Nu = 'NU',
  Nv = 'NV',
  Ny = 'NY',
  Oh = 'OH',
  Ok = 'OK',
  On = 'ON',
  Or = 'OR',
  Other = 'Other',
  Pa = 'PA',
  Pe = 'PE',
  Pr = 'PR',
  Pw = 'PW',
  Qc = 'QC',
  Ri = 'RI',
  Sc = 'SC',
  Sd = 'SD',
  Sk = 'SK',
  Tn = 'TN',
  Tx = 'TX',
  Ut = 'UT',
  Va = 'VA',
  Vi = 'VI',
  Vt = 'VT',
  Wa = 'WA',
  Wi = 'WI',
  Wv = 'WV',
  Wy = 'WY',
  Yt = 'YT',
}

export type AllowEarlyExitBehavior = {
  __typename?: 'AllowEarlyExitBehavior'
  followupSurvey?: Maybe<SurveysConfigKind>
}

export type Answer = {
  __typename?: 'Answer'
  /** @deprecated Use followups instead */
  followup?: Maybe<Followup>
  followups: Array<Followup>
  inlineAlert?: Maybe<InlineAlert>
  label: Scalars['String']['output']
  value: Scalars['String']['output']
}

export type AnswersGroup = {
  __typename?: 'AnswersGroup'
  answers: Array<Answer>
  answersOrder: SurveysConfigObjectQuestionsGroupQuestionResponseAnswersGroupAnswersOrder
}

export type Appointment = {
  __typename?: 'Appointment'
  actualDuration?: Maybe<Scalars['Int']['output']>
  addToGoogleCalendarLink?: Maybe<Scalars['String']['output']>
  agenda?: Maybe<Scalars['String']['output']>
  appointmentType?: Maybe<InsuranceBerryStreetAppointmentType>
  chargeType?: Maybe<InsuranceBerryStreetAppointmentChargeType>
  date?: Maybe<Scalars['String']['output']>
  externalVideochatUrl?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  insurancePolicy?: Maybe<InsurancePolicy>
  meetingAt?: Maybe<Scalars['ISO8601DateTime']['output']>
  meetingStatus?: Maybe<InsuranceBerryStreetAppointmentMeetingStatus>
  nutritionistName?: Maybe<Scalars['String']['output']>
  recurrent: Scalars['Boolean']['output']
  status: InsuranceBerryStreetAppointmentStatus
  title?: Maybe<Scalars['String']['output']>
  unauthenticatedIcsLink?: Maybe<Scalars['String']['output']>
  user: User
}

export type AppointmentCollection = {
  __typename?: 'AppointmentCollection'
  appointments: Array<Appointment>
  pageCount?: Maybe<Scalars['Int']['output']>
  totalCount: Scalars['Int']['output']
}

export type AppointmentDynamicFilter = {
  field: AppointmentDynamicFilterField
  operation: DynamicFilterItemOperation
  value: Scalars['String']['input']
}

export enum AppointmentDynamicFilterField {
  MeetingAt = 'meeting_at',
  MeetingStatus = 'meeting_status',
  Status = 'status',
}

export type AppointmentDynamicFilterItem = {
  items: Array<AppointmentDynamicFilter>
  operator: DynamicFilterOperator
}

export enum AppointmentEhrSortField {
  MeetingAt = 'meeting_at',
}

export type AppointmentSort = {
  direction: SortDirection
  field: AppointmentSortField
}

export enum AppointmentSortField {
  MeetingAt = 'meeting_at',
}

export type AppointmentType = {
  __typename?: 'AppointmentType'
  duration: Scalars['String']['output']
  key: InsuranceBerryStreetAppointmentType
  title: Scalars['String']['output']
}

export type ApprovalRequest = {
  __typename?: 'ApprovalRequest'
  createdAt: Scalars['ISO8601DateTime']['output']
  id: Scalars['ID']['output']
  kind: UserApprovalRequestKind
  state: UserApprovalRequestState
  user: User
}

export type Availability = {
  __typename?: 'Availability'
  appointmentType: InsuranceBerryStreetAppointmentType
  dayOfWeek: Scalars['Int']['output']
  rangeEndAt: Scalars['ISO8601DateTime']['output']
  rangeStartAt: Scalars['ISO8601DateTime']['output']
}

export type AvailableDates = {
  __typename?: 'AvailableDates'
  dates: Array<Scalars['ISO8601Date']['output']>
}

export type AvailableDatesWithSlots = {
  __typename?: 'AvailableDatesWithSlots'
  coachProfiles?: Maybe<Array<CoachProfile>>
  dates: Array<Date>
}

export type AvailableSlots = {
  __typename?: 'AvailableSlots'
  slots: Array<Slot>
}

export type Behavior = {
  __typename?: 'Behavior'
  allowEarlyExit: Scalars['Boolean']['output']
  allowMultipleSubmissions: Scalars['Boolean']['output']
  redirectOnOutcome: Scalars['Boolean']['output']
  showAlertOnClose: Scalars['Boolean']['output']
  showProgressIndicator: Scalars['Boolean']['output']
  trackResponses: Scalars['Boolean']['output']
  warnBeforeStopping: Scalars['Boolean']['output']
}

export enum BillingProduct {
  AutoCancelNutrisenseMonthly = 'auto_cancel_nutrisense_monthly',
  BundledFixedMember = 'bundled_fixed_member',
  BundledFixedMemberBuyNowPayLater = 'bundled_fixed_member_buy_now_pay_later',
  CancellationFee = 'cancellation_fee',
  Cgm = 'cgm',
  CompoundedSemaglutide = 'compounded_semaglutide',
  CompoundedSemaglutideWithSupport = 'compounded_semaglutide_with_support',
  DexcomCgm = 'dexcom_cgm',
  DexcomPatch = 'dexcom_patch',
  DmDietitian_4Video_30Min = 'dm_dietitian_4_video_30_min',
  DmDietitianVideo_30Min = 'dm_dietitian_video_30_min',
  DmMealPlan = 'dm_meal_plan',
  DmQGlucoseCompare = 'dm_q_glucose_compare',
  DmQGlucoseEvent = 'dm_q_glucose_event',
  DmQGlucoseFasting = 'dm_q_glucose_fasting',
  DmQGlucoseMetrics = 'dm_q_glucose_metrics',
  DmQGlucoseTrends = 'dm_q_glucose_trends',
  DmSupplementReview = 'dm_supplement_review',
  DmWeeklyGoals = 'dm_weekly_goals',
  DowngradeFee = 'downgrade_fee',
  DowngradeFee_6To_3Cycle_1 = 'downgrade_fee_6_to_3_cycle_1',
  DowngradeFee_6To_3Cycle_2 = 'downgrade_fee_6_to_3_cycle_2',
  DowngradeFee_12To_3Cycle_1 = 'downgrade_fee_12_to_3_cycle_1',
  DowngradeFee_12To_3Cycle_2 = 'downgrade_fee_12_to_3_cycle_2',
  DowngradeFee_12To_6Cycle_1 = 'downgrade_fee_12_to_6_cycle_1',
  DowngradeFee_12To_6Cycle_2 = 'downgrade_fee_12_to_6_cycle_2',
  DowngradeFee_12To_6Cycle_3 = 'downgrade_fee_12_to_6_cycle_3',
  DowngradeFee_12To_6Cycle_4 = 'downgrade_fee_12_to_6_cycle_4',
  DowngradeFee_12To_6Cycle_5 = 'downgrade_fee_12_to_6_cycle_5',
  FreeCgmReplacement = 'free_cgm_replacement',
  FreeSoftwareOnlyDppAnnualMembership = 'free_software_only_dpp_annual_membership',
  FreeSoftwareOnlyMedicalAnnualMembership = 'free_software_only_medical_annual_membership',
  FreeSoftwareOnlyOwnSensorAnnualMembership = 'free_software_only_own_sensor_annual_membership',
  FreeSoftwareOnlyVideoCallsAnnualMembership = 'free_software_only_video_calls_annual_membership',
  Influencer_1 = 'influencer_1',
  Influencer_2 = 'influencer_2',
  MedicalConsultAddon = 'medical_consult_addon',
  MedicalConsultLateCancellationFee = 'medical_consult_late_cancellation_fee',
  MedicalConsultLateRescheduleFee = 'medical_consult_late_reschedule_fee',
  MedicalConsultNoShowFee = 'medical_consult_no_show_fee',
  NonRecurring_1_16Rd = 'non_recurring_1_16_rd',
  NonRecurring_1_32Rd = 'non_recurring_1_32_rd',
  NonRecurring_1NoRd = 'non_recurring_1_no_rd',
  NonRecurring_2_32Rd = 'non_recurring_2_32_rd',
  NonRecurring_2NoRd = 'non_recurring_2_no_rd',
  NonTaxablePrepaidNutrisenseMonthly_1 = 'non_taxable_prepaid_nutrisense_monthly_1',
  NonTaxablePrepaidNutrisenseMonthly_3 = 'non_taxable_prepaid_nutrisense_monthly_3',
  NonTaxablePrepaidNutrisenseMonthly_6 = 'non_taxable_prepaid_nutrisense_monthly_6',
  NonTaxablePrepaidNutrisenseMonthly_12 = 'non_taxable_prepaid_nutrisense_monthly_12',
  NutrisenseDietitian = 'nutrisense_dietitian',
  NutrisenseDietitianLimited = 'nutrisense_dietitian_limited',
  NutrisenseMonthly = 'nutrisense_monthly',
  NutrisenseMonthly_3 = 'nutrisense_monthly_3',
  NutrisenseMonthly_6 = 'nutrisense_monthly_6',
  NutrisenseMonthly_6BuyNowPayLater = 'nutrisense_monthly_6_buy_now_pay_later',
  NutrisenseMonthly_12 = 'nutrisense_monthly_12',
  NutrisenseMonthly_12BuyNowPayLater = 'nutrisense_monthly_12_buy_now_pay_later',
  NutrisensePeriodic_1_3 = 'nutrisense_periodic_1_3',
  NutrisensePeriodic_1_6 = 'nutrisense_periodic_1_6',
  NutrisensePeriodic_2_3 = 'nutrisense_periodic_2_3',
  NutrisensePeriodic_2_6 = 'nutrisense_periodic_2_6',
  NutrisenseSoftwareOnly = 'nutrisense_software_only',
  NutritionCoachFocused = 'nutrition_coach_focused',
  PaidCgmReplacement = 'paid_cgm_replacement',
  PaidDexcomPatch = 'paid_dexcom_patch',
  PaidPatch = 'paid_patch',
  PaidReshipCgmReplacement = 'paid_reship_cgm_replacement',
  Patch = 'patch',
  PrepaidNutrisenseMonthly_1 = 'prepaid_nutrisense_monthly_1',
  PrepaidNutrisenseMonthly_3 = 'prepaid_nutrisense_monthly_3',
  PrepaidNutrisenseMonthly_6 = 'prepaid_nutrisense_monthly_6',
  PrepaidNutrisenseMonthly_12 = 'prepaid_nutrisense_monthly_12',
  ReshipCgmReplacement = 'reship_cgm_replacement',
  ShippingFee = 'shipping_fee',
  SoftwareOnlyAnnualMembership = 'software_only_annual_membership',
  SoftwareOnlyOwnSensorAnnualMembership = 'software_only_own_sensor_annual_membership',
  SoftwareOnlyOwnSensorMonthlyMembership = 'software_only_own_sensor_monthly_membership',
  SupplementMg_2 = 'supplement_mg_2',
  SupplementMg_6 = 'supplement_mg_6',
  SupplementMvt_2 = 'supplement_mvt_2',
  SupplementMvt_6 = 'supplement_mvt_6',
  SupplementQ10_2 = 'supplement_q10_2',
  SupplementQ10_6 = 'supplement_q10_6',
  Tax = 'tax',
  Unknown = 'unknown',
  UpsellNutrisenseMonthly = 'upsell_nutrisense_monthly',
  UpsellNutrisenseMonthly_3 = 'upsell_nutrisense_monthly_3',
  UpsellNutrisenseMonthly_6 = 'upsell_nutrisense_monthly_6',
  VideoCallLateCancellationFee = 'video_call_late_cancellation_fee',
  VideoCallLateRescheduleFee = 'video_call_late_reschedule_fee',
  VideoCallNoShowFee = 'video_call_no_show_fee',
  WelcomeKit = 'welcome_kit',
}

export enum BillingProductCategory {
  ChurnRecoveryLearnedEverythingNeeded = 'churn_recovery_learned_everything_needed',
  ChurnRecoveryNotUsedOften = 'churn_recovery_not_used_often',
  ChurnRecoveryReachedHealthGoal = 'churn_recovery_reached_health_goal',
  ChurnRecoveryTooExpensive = 'churn_recovery_too_expensive',
  ChurnRecoveryUpsellAdhesiveSkinIrritation = 'churn_recovery_upsell_adhesive_skin_irritation',
  ChurnRecoveryUpsellDidNotLikeProduct = 'churn_recovery_upsell_did_not_like_product',
  ChurnRecoveryUpsellDidNotLikeSensor = 'churn_recovery_upsell_did_not_like_sensor',
  ChurnRecoveryUpsellLearnedEverythingNeeded = 'churn_recovery_upsell_learned_everything_needed',
  ChurnRecoveryUpsellNotUsedOften = 'churn_recovery_upsell_not_used_often',
  ChurnRecoveryUpsellOther = 'churn_recovery_upsell_other',
  ChurnRecoveryUpsellReachedHealthGoal = 'churn_recovery_upsell_reached_health_goal',
  ChurnRecoveryUpsellServiceNotValuable = 'churn_recovery_upsell_service_not_valuable',
  ChurnRecoveryUpsellTooExpensive = 'churn_recovery_upsell_too_expensive',
  Fee = 'fee',
  FreeTrial = 'free_trial',
  Glp = 'glp',
  Marketplace = 'marketplace',
  NutritionistHub = 'nutritionist_hub',
  Onboarding = 'onboarding',
}

export enum BillingProductGroupKind {
  Commitment = 'commitment',
  Dropdown = 'dropdown',
}

export type BiteAi = {
  __typename?: 'BiteAi'
  dailyValues: Array<KeyValuePairFloat>
  units: Array<BiteAiKeyValuePairString>
}

export type BiteAiKeyValuePairString = {
  __typename?: 'BiteAiKeyValuePairString'
  key: Scalars['String']['output']
  value: Scalars['String']['output']
}

export type BloodPressureMeasurement = {
  __typename?: 'BloodPressureMeasurement'
  diastolic: Scalars['Float']['output']
  systolic: Scalars['Float']['output']
}

export type BluetoothGlucoseData = {
  glucoseRaw: Scalars['Int']['input']
  minutesSinceStart: Scalars['Int']['input']
}

export type BluetoothScanData = {
  history: Array<BluetoothGlucoseData>
  serialNumber: Scalars['String']['input']
}

export type CalendarDate = {
  __typename?: 'CalendarDate'
  date: Scalars['ISO8601Date']['output']
  score?: Maybe<Scalars['Int']['output']>
}

export type CalibrationInfo = {
  i1: Scalars['Float']['input']
  i2: Scalars['Float']['input']
  i3: Scalars['Float']['input']
  i4: Scalars['Float']['input']
  i5: Scalars['Float']['input']
  i6: Scalars['Float']['input']
}

export type Charge = {
  __typename?: 'Charge'
  amount: Scalars['Float']['output']
  amountRefunded: Scalars['Float']['output']
  created: Scalars['ISO8601DateTime']['output']
  disputeStripeId?: Maybe<Scalars['String']['output']>
  disputed: Scalars['Boolean']['output']
  failureMessage?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  invoice?: Maybe<Invoice>
  paid: Scalars['Boolean']['output']
  providerPaymentMethodId?: Maybe<Scalars['String']['output']>
  refundable: Scalars['Boolean']['output']
  refunded: Scalars['Boolean']['output']
  status: Scalars['String']['output']
  stripeId: Scalars['String']['output']
  user: User
}

export type Chart = {
  __typename?: 'Chart'
  description: Scalars['String']['output']
  meta: ChartMeta
  range: ChartRange
  title: Scalars['String']['output']
  type: Scalars['String']['output']
  values: Array<ValuePair>
  xAxis: Scalars['String']['output']
  yAxis: Scalars['String']['output']
}

export type ChartData = {
  __typename?: 'ChartData'
  data: Array<ValuePair>
  key: Scalars['String']['output']
  section: Scalars['String']['output']
}

export type ChartFilter = {
  endDate: Scalars['String']['input']
  period: Period
  startDate: Scalars['String']['input']
  types: Array<KeyValueStringArray>
}

export type ChartMeta = {
  __typename?: 'ChartMeta'
  key?: Maybe<Scalars['String']['output']>
  section?: Maybe<Scalars['String']['output']>
  tag?: Maybe<Scalars['String']['output']>
}

export type ChartRange = {
  __typename?: 'ChartRange'
  goal?: Maybe<Scalars['Float']['output']>
  goalMax?: Maybe<Scalars['Float']['output']>
  goalMin?: Maybe<Scalars['Float']['output']>
  max?: Maybe<Scalars['Float']['output']>
  min?: Maybe<Scalars['Float']['output']>
  tickCount?: Maybe<Scalars['Int']['output']>
}

export type Charts = {
  __typename?: 'Charts'
  charts: Array<Chart>
}

export type ChartsConfig = {
  __typename?: 'ChartsConfig'
  filters: ChartsFilters
}

export type ChartsData = {
  __typename?: 'ChartsData'
  values?: Maybe<Array<ChartData>>
}

export type ChartsFilters = {
  __typename?: 'ChartsFilters'
  types: Array<KeyValuePairStringArray>
}

export type ChatConversation = {
  __typename?: 'ChatConversation'
  assignee?: Maybe<User>
  candidateRead: Scalars['Boolean']['output']
  id: Scalars['ID']['output']
  intercomBackfillState?: Maybe<ChatConversationIntercomBackfillState>
  intercomCreatedAt: Scalars['ISO8601DateTime']['output']
  intercomId: Scalars['String']['output']
  kind: ChatConversationKind
  lastMessage?: Maybe<ChatConversationMessage>
  lastNote?: Maybe<ChatConversationNote>
  lastUserMessage?: Maybe<ChatConversationMessage>
  read: Scalars['Boolean']['output']
  state: ChatConversationState
  user: User
}

export enum ChatConversationIntercomBackfillState {
  Completed = 'completed',
  InProgress = 'in_progress',
  Pending = 'pending',
}

export enum ChatConversationKind {
  Coach = 'coach',
  Support = 'support',
}

export type ChatConversationMessage = {
  __typename?: 'ChatConversationMessage'
  id: Scalars['ID']['output']
  sender?: Maybe<User>
  senderKind: ChatConversationMessageSenderKind
  sentAt: Scalars['ISO8601DateTime']['output']
  value: Scalars['String']['output']
}

export enum ChatConversationMessageSenderKind {
  Automated = 'automated',
  Unknown = 'unknown',
  User = 'user',
}

export type ChatConversationNote = {
  __typename?: 'ChatConversationNote'
  createdAt: Scalars['ISO8601DateTime']['output']
  createdBy: User
  id: Scalars['ID']['output']
  value: Scalars['String']['output']
}

export enum ChatConversationState {
  Closed = 'closed',
  Open = 'open',
  Snoozed = 'snoozed',
}

export type Chatbot = {
  __typename?: 'Chatbot'
  lastShownCutoffAt: Scalars['ISO8601DateTime']['output']
  responseTimeoutInSeconds: Scalars['Int']['output']
}

export type ChecklistItem = {
  __typename?: 'ChecklistItem'
  completed: Scalars['Boolean']['output']
  deepLink: Scalars['String']['output']
  key: UserChecklistItem
  title: Scalars['String']['output']
}

export type ChecklistItemCollection = {
  __typename?: 'ChecklistItemCollection'
  checklistItems: Array<ChecklistItem>
}

export type CheckoutOption = {
  __typename?: 'CheckoutOption'
  kind: SubscriptionCheckoutOptionKind
}

export type ClientConfig = {
  __typename?: 'ClientConfig'
  activityTypes: Array<Scalars['String']['output']>
  appointmentTypes: Array<AppointmentType>
  biteAi: BiteAi
  caProvinces: Array<State>
  charts: ChartsConfig
  chatbot: Chatbot
  customIngredients: CustomIngredients
  dailyActions: Array<DailyActionKind>
  dayTabs: Array<KeyValuePairString>
  education: Education
  events: Events
  featureFlags: Array<FeatureFlag>
  goalMetricOptions: Array<GoalMetricOption>
  goalOptions: Array<GoalOption>
  googleFit: GoogleFit
  healthData: HealthDataConfig
  healthKit: HealthKit
  healthMetrics: Array<HealthMetricType>
  healthSettings: HealthSettings
  history: History
  insights: Config
  insuranceKinds: InsuranceKinds
  journal: JournalConfig
  mealTypes: Array<Scalars['String']['output']>
  meals: Meals
  measurementTypes: Array<Scalars['String']['output']>
  measurements: Array<MeasurementConfig>
  onboarding: Onboarding
  privacyCenterUrl: Scalars['String']['output']
  questionnaireUrl: Scalars['String']['output']
  referral: Referral
  reminders: Array<ReminderKind>
  remindersSettings: RemindersSettings
  researchFaqs: ResearchFaqs
  score: Score
  sensorSettings: SensorSettings
  settings: Settings
  signup: Signup
  stats: StatsConfig
  tickets: Tickets
  timeZones: Array<Scalars['String']['output']>
  tutorials: Array<Tutorials>
  usStates: Array<State>
}

export type Coach = {
  __typename?: 'Coach'
  active: Scalars['Boolean']['output']
  bio?: Maybe<Scalars['String']['output']>
  coachId: Scalars['ID']['output']
  credentials?: Maybe<Scalars['String']['output']>
  endDate?: Maybe<Scalars['ISO8601Date']['output']>
  fullName: Scalars['String']['output']
  getHealthieId?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  kind: EhrAppointmentProviderKind
  manager?: Maybe<User>
  outOfOffice: Scalars['Boolean']['output']
  photo?: Maybe<Scalars['String']['output']>
  priorityChangedAt?: Maybe<Scalars['ISO8601Date']['output']>
  schedulingPriority?: Maybe<Scalars['Int']['output']>
  specialties: Array<EhrCoachSpecialtyDef>
  startDate?: Maybe<Scalars['ISO8601Date']['output']>
  stateLicences: Array<Scalars['String']['output']>
  updatedAt: Scalars['ISO8601Date']['output']
}

export type CoachAssignment = {
  __typename?: 'CoachAssignment'
  appointment?: Maybe<Appointment>
  assignedAt?: Maybe<Scalars['ISO8601DateTime']['output']>
  coach?: Maybe<User>
  createdAt: Scalars['ISO8601DateTime']['output']
  deactivatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>
  dietitianMenu: Scalars['Boolean']['output']
  id: Scalars['ID']['output']
  kind?: Maybe<CoachAssignmentKind>
  last: Scalars['Boolean']['output']
  state: CoachAssignmentState
  supportType: CoachAssignmentSupportType
  user: User
}

export type CoachAssignmentCollection = {
  __typename?: 'CoachAssignmentCollection'
  coachAssignments: Array<CoachAssignment>
}

export type CoachAssignmentInfo = {
  __typename?: 'CoachAssignmentInfo'
  activeAssignmentsCount: Scalars['Int']['output']
  coachId: Scalars['String']['output']
  createdAt: Scalars['ISO8601DateTime']['output']
  id: Scalars['ID']['output']
  updatedAt: Scalars['ISO8601DateTime']['output']
}

export enum CoachAssignmentKind {
  AnnualMembership = 'annual_membership',
  CoachingPlan = 'coaching_plan',
  Fixed_8Weeks = 'fixed_8_weeks',
  Influencer = 'influencer',
  NoCoreSubscription = 'no_core_subscription',
  NonRecurring = 'non_recurring',
  Recurring_12Weeks = 'recurring_12_weeks',
  Regular = 'regular',
}

export enum CoachAssignmentState {
  Active = 'active',
  Inactive = 'inactive',
  Paused = 'paused',
  Pending = 'pending',
}

export enum CoachAssignmentSupportType {
  AsyncChat = 'async_chat',
  MealPlan = 'meal_plan',
  SupplementReview = 'supplement_review',
  VideoCall = 'video_call',
  VideoChat = 'video_chat',
}

export type CoachProfile = {
  __typename?: 'CoachProfile'
  /** @deprecated Use `state` instead */
  active: Scalars['Boolean']['output']
  bio?: Maybe<Scalars['String']['output']>
  coachId: Scalars['ID']['output']
  credentials?: Maybe<Scalars['String']['output']>
  endDate?: Maybe<Scalars['ISO8601Date']['output']>
  fullName: Scalars['String']['output']
  getHealthieId?: Maybe<Scalars['String']['output']>
  manager?: Maybe<User>
  outOfOffice: Scalars['Boolean']['output']
  photo?: Maybe<Scalars['String']['output']>
  priorityChangedAt?: Maybe<Scalars['ISO8601Date']['output']>
  schedulingPriority?: Maybe<Scalars['Int']['output']>
  specialties: Array<EhrCoachSpecialtyDef>
  startDate?: Maybe<Scalars['ISO8601Date']['output']>
  state: EhrCoachState
  stateLicences: Array<Scalars['String']['output']>
  updatedAt: Scalars['ISO8601Date']['output']
}

export type Cohort = {
  __typename?: 'Cohort'
  id: Scalars['ID']['output']
  instructor?: Maybe<CoachProfile>
  sessions: Array<CohortSession>
  state: CoursesCohortState
}

export type CohortCollection = {
  __typename?: 'CohortCollection'
  cohorts: Array<Cohort>
}

export type CohortSession = {
  __typename?: 'CohortSession'
  attendance?: Maybe<CohortSessionAttendance>
  cancelledAt?: Maybe<Scalars['ISO8601DateTime']['output']>
  id: Scalars['ID']['output']
  joinUrl?: Maybe<Scalars['String']['output']>
  kind: CoursesCohortSessionKind
  name: Scalars['String']['output']
  position: Scalars['Int']['output']
  scheduledAt: Scalars['ISO8601DateTime']['output']
  scheduledEndAt: Scalars['ISO8601DateTime']['output']
}

export type CohortSessionAttendance = {
  __typename?: 'CohortSessionAttendance'
  id: Scalars['ID']['output']
  rsvp: CoursesCohortSessionAttendanceRsvp
  state: CoursesCohortSessionAttendanceState
}

export type CompletedScreen = {
  __typename?: 'CompletedScreen'
  footnote?: Maybe<Scalars['String']['output']>
  header: Scalars['String']['output']
  icon: UiIcon
  /** @deprecated use intro_markdown */
  intro?: Maybe<Scalars['String']['output']>
  introMarkdown?: Maybe<Scalars['String']['output']>
  primaryActionText?: Maybe<Scalars['String']['output']>
  secondaryActionLink?: Maybe<Link>
  title?: Maybe<Scalars['String']['output']>
}

export type Config = {
  __typename?: 'Config'
  filters: StatsFilters
  statistics: Array<Statistics>
}

export type ConfigUnit = {
  __typename?: 'ConfigUnit'
  precision: Scalars['Int']['output']
  ranges: Array<RangeUnit>
  units: Scalars['String']['output']
}

export enum CoursesCohortSessionAttendanceRsvp {
  Awaiting = 'awaiting',
  Confirmed = 'confirmed',
  Declined = 'declined',
}

export enum CoursesCohortSessionAttendanceState {
  Attended = 'attended',
  Excluded = 'excluded',
  Pending = 'pending',
  Unattended = 'unattended',
}

export enum CoursesCohortSessionKind {
  Dpp2021Module1 = 'dpp2021_module1',
  Dpp2021Module2 = 'dpp2021_module2',
  Dpp2021Module3 = 'dpp2021_module3',
  Dpp2021Module4 = 'dpp2021_module4',
  Dpp2021Module5 = 'dpp2021_module5',
  Dpp2021Module6 = 'dpp2021_module6',
  Dpp2021Module7 = 'dpp2021_module7',
  Dpp2021Module8 = 'dpp2021_module8',
  Dpp2021Module9 = 'dpp2021_module9',
  Dpp2021Module10 = 'dpp2021_module10',
  Dpp2021Module11 = 'dpp2021_module11',
  Dpp2021Module12 = 'dpp2021_module12',
  Dpp2021Module13 = 'dpp2021_module13',
  Dpp2021Module14 = 'dpp2021_module14',
  Dpp2021Module15 = 'dpp2021_module15',
  Dpp2021Module16 = 'dpp2021_module16',
  Dpp2021Module17 = 'dpp2021_module17',
  Dpp2021Module18 = 'dpp2021_module18',
  Dpp2021Module19 = 'dpp2021_module19',
  Dpp2021Module20 = 'dpp2021_module20',
  Dpp2021Module21 = 'dpp2021_module21',
  Dpp2021Module22 = 'dpp2021_module22',
  Dpp2021Module23 = 'dpp2021_module23',
  Dpp2021Module24 = 'dpp2021_module24',
  Dpp2021Module25 = 'dpp2021_module25',
  Dpp2021Module26 = 'dpp2021_module26',
}

export enum CoursesCohortState {
  Draft = 'draft',
  Live = 'live',
  Stopped = 'stopped',
}

/** Autogenerated return type of CreateAction. */
export type CreateActionPayload = {
  __typename?: 'CreateActionPayload'
  success: Scalars['Boolean']['output']
}

export type CreatedOneTimePayment = {
  __typename?: 'CreatedOneTimePayment'
  invoice: Invoice
}

export type CreatedSubscription = {
  __typename?: 'CreatedSubscription'
  clientSecret?: Maybe<Scalars['String']['output']>
  providerSubscriptionId: Scalars['String']['output']
  subscription: Subscription
}

export type CustomIngredients = {
  __typename?: 'CustomIngredients'
  macros: Array<Macro>
}

export type DailyAction = {
  __typename?: 'DailyAction'
  autocomplete: Scalars['Boolean']['output']
  category: UserDailyActionCategory
  completionsCount?: Maybe<Scalars['Int']['output']>
  createdAt: Scalars['ISO8601DateTime']['output']
  description: Scalars['String']['output']
  icon: UiIcon
  id: Scalars['ID']['output']
  kind: UserDailyActionKind
  statKey?: Maybe<Scalars['String']['output']>
  status: UserDailyActionStatus
  title: Scalars['String']['output']
  updatedAt: Scalars['ISO8601DateTime']['output']
}

export type DailyActionCollection = {
  __typename?: 'DailyActionCollection'
  completionCounts: Array<DailyActionCompletionsCount>
  dailyActions: Array<DailyAction>
}

export type DailyActionCompletionsCount = {
  __typename?: 'DailyActionCompletionsCount'
  completionCount: Scalars['Int']['output']
  dailyActionId: Scalars['Int']['output']
}

export type DailyActionKind = {
  __typename?: 'DailyActionKind'
  autocomplete: Scalars['Boolean']['output']
  category: UserDailyActionCategory
  description: Scalars['String']['output']
  key: Scalars['String']['output']
  title: Scalars['String']['output']
}

export type DailyMeasurementsSourcePriority = {
  __typename?: 'DailyMeasurementsSourcePriority'
  sources: Array<HealthDataMeasurementSource>
  type: HealthDataMeasurementType
}

export type DailyScore = {
  __typename?: 'DailyScore'
  score?: Maybe<Scalars['Int']['output']>
  scoreMean?: Maybe<Scalars['Int']['output']>
  scorePeak?: Maybe<Scalars['Int']['output']>
  scoreStdDev?: Maybe<Scalars['Int']['output']>
  scoreTimeOutsideRange?: Maybe<Scalars['Int']['output']>
}

export type Date = {
  __typename?: 'Date'
  date: Scalars['ISO8601Date']['output']
  slots: Array<Slot>
}

export type DateFilter = {
  endDate?: InputMaybe<Scalars['String']['input']>
  endHour?: InputMaybe<Scalars['Int']['input']>
  excludeTypes?: InputMaybe<Array<Scalars['String']['input']>>
  favorite?: InputMaybe<Scalars['Boolean']['input']>
  graph?: InputMaybe<Scalars['String']['input']>
  includeTypes?: InputMaybe<Array<Scalars['String']['input']>>
  order?: InputMaybe<Scalars['String']['input']>
  orderBy?: InputMaybe<Scalars['String']['input']>
  query?: InputMaybe<Scalars['String']['input']>
  scope?: InputMaybe<Scalars['String']['input']>
  startDate?: InputMaybe<Scalars['String']['input']>
  startHour?: InputMaybe<Scalars['Int']['input']>
  types?: InputMaybe<Array<KeyValueStringArray>>
}

export type DatesWithSlots = {
  __typename?: 'DatesWithSlots'
  dates: Array<EhrDate>
  providers: Array<EhrProvider>
}

export type Delimiter = {
  __typename?: 'Delimiter'
  createdAt: Scalars['ISO8601DateTime']['output']
  id: Scalars['ID']['output']
}

export enum DexcomConnectionStatus {
  Active = 'active',
  Invalidated = 'invalidated',
}

export type DexcomConnectionType = {
  __typename?: 'DexcomConnectionType'
  lastSyncedAt?: Maybe<Scalars['ISO8601DateTime']['output']>
  status: DexcomConnectionStatus
}

export enum DexcomResearchStatus {
  AcceptedSensor = 'accepted_sensor',
  CheckoutAcceptedSensor = 'checkout_accepted_sensor',
  CheckoutDeclinedSensor = 'checkout_declined_sensor',
  ConsentAccepted = 'consent_accepted',
  ConsentWithdrawn = 'consent_withdrawn',
  ConsentWithdrawnAdmin = 'consent_withdrawn_admin',
  DeclinedResearchConsent = 'declined_research_consent',
  DeclinedSensor = 'declined_sensor',
  Enrolled = 'enrolled',
  EnteredEligiblePlan = 'entered_eligible_plan',
  MedicalHistoryAccepted = 'medical_history_accepted',
  MedicalHistoryRejected = 'medical_history_rejected',
  NotInEligiblePlan = 'not_in_eligible_plan',
  Participating = 'participating',
}

export type DirectUpload = {
  __typename?: 'DirectUpload'
  headers: Array<KeyValuePairString>
  signedId: Scalars['String']['output']
  url: Scalars['String']['output']
}

export type Doctor = {
  __typename?: 'Doctor'
  avatar?: Maybe<Scalars['String']['output']>
  bio?: Maybe<Scalars['String']['output']>
  credentials?: Maybe<Scalars['String']['output']>
  firstName?: Maybe<Scalars['String']['output']>
  getHealthieId?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  kind: EhrAppointmentProviderKind
  lastName?: Maybe<Scalars['String']['output']>
  state: EhrDoctorState
}

export type DoctorAssignment = {
  __typename?: 'DoctorAssignment'
  doctor: Doctor
  id: Scalars['ID']['output']
  state: EhrDoctorAssignmentState
}

export type DppHealthDataRecordChecklist = {
  __typename?: 'DppHealthDataRecordChecklist'
  requiresPhotoUpload: Scalars['Boolean']['output']
  state: DppHealthDataRecordChecklistState
}

export enum DppHealthDataRecordChecklistState {
  Done = 'done',
  Optional = 'optional',
  Pending = 'pending',
  Required = 'required',
}

export enum DppHealthDataRecordKind {
  HbA1c = 'hb_a1c',
  PhysicalActivity = 'physical_activity',
  Weight = 'weight',
}

export type DppHealthDataRecordsChecklist = {
  __typename?: 'DppHealthDataRecordsChecklist'
  hbA1cChecklist: DppHealthDataRecordChecklist
  physicalActivityChecklist: DppHealthDataRecordChecklist
  weightChecklist: DppHealthDataRecordChecklist
}

export enum DynamicFilterItemOperation {
  Blank = 'blank',
  Cont = 'cont',
  ContAll = 'cont_all',
  ContAny = 'cont_any',
  DoesNotMatch = 'does_not_match',
  DoesNotMatchAll = 'does_not_match_all',
  DoesNotMatchAny = 'does_not_match_any',
  End = 'end',
  EndAll = 'end_all',
  EndAny = 'end_any',
  Eq = 'eq',
  Gt = 'gt',
  GtAll = 'gt_all',
  GtAny = 'gt_any',
  Gteq = 'gteq',
  GteqAll = 'gteq_all',
  GteqAny = 'gteq_any',
  ICont = 'i_cont',
  IContAll = 'i_cont_all',
  IContAny = 'i_cont_any',
  In = 'in',
  IsNull = 'is_null',
  Lt = 'lt',
  LtAll = 'lt_all',
  LtAny = 'lt_any',
  Lteq = 'lteq',
  LteqAll = 'lteq_all',
  LteqAny = 'lteq_any',
  Matches = 'matches',
  MatchesAll = 'matches_all',
  MatchesAny = 'matches_any',
  NotCont = 'not_cont',
  NotContAll = 'not_cont_all',
  NotContAny = 'not_cont_any',
  NotEnd = 'not_end',
  NotEndAll = 'not_end_all',
  NotEndAny = 'not_end_any',
  NotEq = 'not_eq',
  NotEqAll = 'not_eq_all',
  NotICont = 'not_i_cont',
  NotIContAll = 'not_i_cont_all',
  NotIContAny = 'not_i_cont_any',
  NotIn = 'not_in',
  NotNull = 'not_null',
  NotStart = 'not_start',
  NotStartAll = 'not_start_all',
  NotStartAny = 'not_start_any',
  Present = 'present',
  Start = 'start',
  StartAll = 'start_all',
  StartAny = 'start_any',
}

export enum DynamicFilterOperator {
  And = 'and',
  Or = 'or',
}

export type Education = {
  __typename?: 'Education'
  collections: Array<EducationCollection>
}

export type EducationArticle = {
  __typename?: 'EducationArticle'
  author: Scalars['String']['output']
  imageUrl: Scalars['String']['output']
  subtitle: Scalars['String']['output']
  title: Scalars['String']['output']
  url: Scalars['String']['output']
}

export type EducationCollection = {
  __typename?: 'EducationCollection'
  articles: Array<EducationArticle>
  imageUrl: Scalars['String']['output']
  subtitle: Scalars['String']['output']
  title: Scalars['String']['output']
  url: Scalars['String']['output']
}

export type EffectiveDailyMeasurement = {
  __typename?: 'EffectiveDailyMeasurement'
  availableMeasurements: Array<Measurement>
  date: Scalars['ISO8601Date']['output']
  defaultSource?: Maybe<HealthDataMeasurementSource>
  selectedSource?: Maybe<HealthDataMeasurementSource>
  type: HealthDataMeasurementType
}

export type EhrAppointment = {
  __typename?: 'EhrAppointment'
  addToGcalLink?: Maybe<Scalars['String']['output']>
  category: EhrAppointmentCategory
  getHealthieId?: Maybe<Scalars['ID']['output']>
  getHealthieInstanceKind: EhrGetHealthieInstanceKind
  id: Scalars['ID']['output']
  isGroup: Scalars['Boolean']['output']
  joinUrl?: Maybe<Scalars['String']['output']>
  kind: EhrAppointmentKind
  lastState: EhrAppointmentState
  meetingAt?: Maybe<Scalars['ISO8601DateTime']['output']>
  startUrl?: Maybe<Scalars['String']['output']>
  title?: Maybe<Scalars['String']['output']>
  unauthenticatedIcsLink?: Maybe<Scalars['String']['output']>
}

export enum EhrAppointmentAttendanceChargeKind {
  BsFree = 'bs_free',
  BsInsuranceCovered = 'bs_insurance_covered',
  BsPaid = 'bs_paid',
  NsPaid = 'ns_paid',
}

export enum EhrAppointmentCategory {
  Coaching = 'coaching',
  Dpp = 'dpp',
  Medical = 'medical',
}

export type EhrAppointmentCollection = {
  __typename?: 'EhrAppointmentCollection'
  appointments: Array<EhrAppointment>
  pageCount?: Maybe<Scalars['Int']['output']>
  totalCount: Scalars['Int']['output']
}

export enum EhrAppointmentKind {
  DppSession = 'dpp_session',
  Followup = 'followup',
  Followup_30 = 'followup_30',
  Followup_45 = 'followup_45',
  Followup_90 = 'followup_90',
  GlpSession = 'glp_session',
  GlpSession_10 = 'glp_session_10',
  Initial = 'initial',
  Initial_60 = 'initial_60',
  NoneCovered = 'none_covered',
}

export enum EhrAppointmentProviderKind {
  Coach = 'coach',
  Doctor = 'doctor',
}

export enum EhrAppointmentReason {
  BillingSideEffect = 'billing_side_effect',
  CoachesBillableMissingData = 'coaches_billable_missing_data',
  Console = 'console',
  GetHealthie = 'get_healthie',
  GlpIntakeSurveyRestarted = 'glp_intake_survey_restarted',
  InsuranceDomainMigration = 'insurance_domain_migration',
  InsuranceSideEffect = 'insurance_side_effect',
  PaymentMethodMissing = 'payment_method_missing',
  RecurrenceDelete = 'recurrence_delete',
  RecurrenceUpsert = 'recurrence_upsert',
  StaleFreePending = 'stale_free_pending',
  UpcomingAppointmentRefresh = 'upcoming_appointment_refresh',
  User = 'user',
}

export type EhrAppointmentRecurrence = {
  __typename?: 'EhrAppointmentRecurrence'
  cadence: EhrAppointmentRecurrenceCadence
  days: Array<EhrAppointmentRecurrenceDay>
  id: Scalars['ID']['output']
  numberOfCalls: Scalars['Int']['output']
  timeSlots?: Maybe<Array<Scalars['ISO8601DateTime']['output']>>
}

export enum EhrAppointmentRecurrenceCadence {
  BiWeekly = 'bi_weekly',
  Monthly = 'monthly',
  Weekly = 'weekly',
}

export enum EhrAppointmentRecurrenceDay {
  All = 'all',
  Friday = 'friday',
  Monday = 'monday',
  Thursday = 'thursday',
  Tuesday = 'tuesday',
  Wednesday = 'wednesday',
}

export type EhrAppointmentSort = {
  direction: SortDirection
  field: AppointmentEhrSortField
}

export type EhrAppointmentState = {
  __typename?: 'EhrAppointmentState'
  appointmentId: Scalars['ID']['output']
  id: Scalars['ID']['output']
  kind: EhrAppointmentStateKind
  reason: EhrAppointmentReason
}

export enum EhrAppointmentStateKind {
  Cancelled = 'cancelled',
  Deleted = 'deleted',
  InReview = 'in_review',
  LateCancellation = 'late_cancellation',
  LateReschedule = 'late_reschedule',
  NoShow = 'no_show',
  Occurred = 'occurred',
  PendingReschedule = 'pending_reschedule',
  PendingSchedule = 'pending_schedule',
  Rescheduled = 'rescheduled',
  Scheduled = 'scheduled',
}

export type EhrBookedAppointment = {
  __typename?: 'EhrBookedAppointment'
  date?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  title?: Maybe<Scalars['String']['output']>
}

export enum EhrChartNoteKind {
  CheckIn = 'check_in',
  CheckInAddendum = 'check_in_addendum',
  MedicalIntakeForm = 'medical_intake_form',
  RenewalIntakeForm = 'renewal_intake_form',
}

export enum EhrCoachSpecialty {
  AutoimmuneConditions = 'autoimmune_conditions',
  Bariatric = 'bariatric',
  Cancer = 'cancer',
  ChronicFatigue = 'chronic_fatigue',
  EatingDisorders = 'eating_disorders',
  Fertility = 'fertility',
  FoodAllergies = 'food_allergies',
  GutHealth = 'gut_health',
  HealthOptimization = 'health_optimization',
  HealthyAging = 'healthy_aging',
  HeartDisease = 'heart_disease',
  MensHealth = 'mens_health',
  MindfulEating = 'mindful_eating',
  PrediabetesDiabetes = 'prediabetes_diabetes',
  PrenatalNutrition = 'prenatal_nutrition',
  SportsPerformance = 'sports_performance',
  ThyroidDisorders = 'thyroid_disorders',
  WeightConcerns = 'weight_concerns',
  WomensHealth = 'womens_health',
}

export type EhrCoachSpecialtyDef = {
  __typename?: 'EhrCoachSpecialtyDef'
  key: EhrCoachSpecialty
  name: Scalars['String']['output']
}

export enum EhrCoachState {
  Active = 'active',
  Inactive = 'inactive',
}

export type EhrDate = {
  __typename?: 'EhrDate'
  date: Scalars['ISO8601Date']['output']
  slots: Array<EhrSlot>
}

export enum EhrDoctorAssignmentState {
  Active = 'active',
  Inactive = 'inactive',
}

export enum EhrDoctorState {
  Active = 'active',
  Inactive = 'inactive',
}

export enum EhrGetHealthieInstanceKind {
  BerryStreet = 'berry_street',
  Nutrisense = 'nutrisense',
}

export type EhrProvider = Coach | Doctor

export type EhrSlot = {
  __typename?: 'EhrSlot'
  providerId: Scalars['ID']['output']
  time: Scalars['ISO8601DateTime']['output']
}

export type EntryGlucose = {
  __typename?: 'EntryGlucose'
  healthyRange: Array<Scalars['Float']['output']>
  range: Array<Scalars['Float']['output']>
  values: Array<Measurement>
}

export type EntryStatistics = {
  __typename?: 'EntryStatistics'
  aucScore?: Maybe<Scalars['Float']['output']>
  glucose2hrDelta?: Maybe<Scalars['Float']['output']>
  glucoseDelta?: Maybe<Scalars['Float']['output']>
  glucoseMax?: Maybe<Scalars['Float']['output']>
  glucoseMin?: Maybe<Scalars['Float']['output']>
  glucoseScore?: Maybe<Scalars['Int']['output']>
  glucoseScore2hrDelta?: Maybe<Scalars['Int']['output']>
  glucoseScoreAuc?: Maybe<Scalars['Int']['output']>
  glucoseScoreDelta?: Maybe<Scalars['Int']['output']>
  glucoseScorePeak?: Maybe<Scalars['Int']['output']>
}

export type EstimatedTax = {
  __typename?: 'EstimatedTax'
  estimatedTax: Scalars['Float']['output']
}

export type Event = Activity | JournalEntry | Meal | Measurement | Note

export type EventCollection = {
  __typename?: 'EventCollection'
  events: Array<Event>
}

export type Events = {
  __typename?: 'Events'
  /** @deprecated event filtering is no longer fetched as of mobile app v2.8.1 */
  filters: ListFilters
}

export type EventsData = {
  __typename?: 'EventsData'
  events: EventCollection
}

export type ExpandableModule = {
  __typename?: 'ExpandableModule'
  contentId: Scalars['ID']['output']
  id: Scalars['ID']['output']
  lessons?: Maybe<Array<Lesson>>
  position?: Maybe<Scalars['Int']['output']>
  progress?: Maybe<Progress>
}

export type ExpandableProgram = {
  __typename?: 'ExpandableProgram'
  contentId: Scalars['ID']['output']
  id: Scalars['ID']['output']
  modules?: Maybe<Array<ExpandableModule>>
  position?: Maybe<Scalars['Int']['output']>
  progress?: Maybe<Progress>
}

export type FeatureFlag = {
  __typename?: 'FeatureFlag'
  enabled: Scalars['Boolean']['output']
  key: Scalars['String']['output']
}

export type File = {
  __typename?: 'File'
  createdAt: Scalars['ISO8601DateTime']['output']
  downloadFileUrl: Scalars['String']['output']
  fileName: Scalars['String']['output']
  fileType: Scalars['String']['output']
  fileUrl: Scalars['String']['output']
  id: Scalars['ID']['output']
  permanentUrl: Scalars['String']['output']
  tag?: Maybe<UserFileTag>
}

export type FileDynamicFilter = {
  field: FileDynamicFilterField
  operation: DynamicFilterItemOperation
  value: Scalars['String']['input']
}

export enum FileDynamicFilterField {
  Tag = 'tag',
}

export type FileDynamicFilterItem = {
  items: Array<FileDynamicFilter>
  operator: DynamicFilterOperator
}

export type FileMetadata = {
  byteSize: Scalars['Int']['input']
  checksum: Scalars['String']['input']
  contentType: Scalars['String']['input']
  filename: Scalars['String']['input']
}

export type FileSort = {
  direction: SortDirection
  field: FileSortField
}

export enum FileSortField {
  CreatedAt = 'created_at',
}

export enum FilterAppointmentsKind {
  /** Fetch all appointments */
  All = 'ALL',
  /** Fetch past appointments */
  Past = 'PAST',
  /** Fetch upcoming appointments */
  Upcoming = 'UPCOMING',
}

export type Followup = {
  __typename?: 'Followup'
  kind: SurveysConfigObjectQuestionsGroupQuestionResponseAnswersGroupAnswerFollowupKind
  parentAnswer: Answer
  parentQuestionKey: SurveysQuestionKey
  question: Question
}

export type FulfillmentConfiguration = {
  __typename?: 'FulfillmentConfiguration'
  sensorKind: UserSensorKind
  userId: Scalars['ID']['output']
}

export type GenerateAuthUrl = {
  __typename?: 'GenerateAuthUrl'
  url: Scalars['String']['output']
  userId: Scalars['String']['output']
}

export type GenerateSdkToken = {
  __typename?: 'GenerateSdkToken'
  token: Scalars['String']['output']
}

export type GetHealthieApiKey = {
  __typename?: 'GetHealthieApiKey'
  apiKey?: Maybe<Scalars['String']['output']>
}

export type GlucoseDailyScore = {
  __typename?: 'GlucoseDailyScore'
  today: DailyScore
}

export type GlucoseData = {
  error?: InputMaybe<Scalars['Int']['input']>
  glucoseCalibrated?: InputMaybe<Scalars['Int']['input']>
  glucoseRaw: Scalars['Int']['input']
  hasError?: InputMaybe<Scalars['Boolean']['input']>
  sampleTime: Scalars['Int']['input']
  temperature?: InputMaybe<Scalars['Int']['input']>
  temperatureAdjustment?: InputMaybe<Scalars['Int']['input']>
  timeSinceStart?: InputMaybe<Scalars['Int']['input']>
}

export type GlucoseStatistics = {
  __typename?: 'GlucoseStatistics'
  today: Stat
}

export type GlucoseThresholdPreset = {
  __typename?: 'GlucoseThresholdPreset'
  description: Scalars['String']['output']
  high: Scalars['Int']['output']
  low: Scalars['Int']['output']
  name: Scalars['String']['output']
}

export type Goal = {
  __typename?: 'Goal'
  description?: Maybe<Scalars['String']['output']>
  kind: UserGoalKind
  title: Scalars['String']['output']
}

export type GoalMetric = {
  __typename?: 'GoalMetric'
  highThresholdValue?: Maybe<Scalars['Float']['output']>
  kind: UserGoalMetricKind
  lowThresholdValue?: Maybe<Scalars['Float']['output']>
  primary: Scalars['Boolean']['output']
  section: Scalars['String']['output']
  statKey: Scalars['String']['output']
}

export type GoalMetricOption = {
  __typename?: 'GoalMetricOption'
  text: Scalars['String']['output']
  value: UserGoalMetricKind
}

export type GoalOption = {
  __typename?: 'GoalOption'
  text: Scalars['String']['output']
  value: UserGoalKind
}

export type GoogleFit = {
  __typename?: 'GoogleFit'
  ignore: Array<Scalars['String']['output']>
}

export type HealthDataConfig = {
  __typename?: 'HealthDataConfig'
  measurementSourceDefs: Array<HealthDataMeasurementSourceDef>
  measurementTypeDefs: Array<HealthDataMeasurementTypeDef>
}

export enum HealthDataMeasurementSource {
  Dexcom = 'dexcom',
  Fitbit = 'fitbit',
  Freestylelibre = 'freestylelibre',
  Garmin = 'garmin',
  Googlefit = 'googlefit',
  Healthkit = 'healthkit',
  Ketomojo = 'ketomojo',
  LibreLinkup = 'libre_linkup',
  Nutrisense = 'nutrisense',
  Oura = 'oura',
  Whoop = 'whoop',
}

export type HealthDataMeasurementSourceDef = {
  __typename?: 'HealthDataMeasurementSourceDef'
  iconDef?: Maybe<IconDef>
  key: HealthDataMeasurementSource
  label: Scalars['String']['output']
  providesDailyMeasurements: Scalars['Boolean']['output']
  sourceTags: Array<Scalars['String']['output']>
}

export enum HealthDataMeasurementType {
  Appetite = 'appetite',
  BloodGlucose = 'blood_glucose',
  BloodKetones = 'blood_ketones',
  BloodPressure = 'blood_pressure',
  BodyFatPercentage = 'body_fat_percentage',
  BodyTemperature = 'body_temperature',
  BreathKetones = 'breath_ketones',
  CyclingDistance = 'cycling_distance',
  Energy = 'energy',
  ExerciseTime = 'exercise_time',
  FlightsClimbed = 'flights_climbed',
  Focus = 'focus',
  Glucose = 'glucose',
  Mood = 'mood',
  RunningDistance = 'running_distance',
  StepCount = 'step_count',
  Stress = 'stress',
  SwimmingDistance = 'swimming_distance',
  Weight = 'weight',
}

export type HealthDataMeasurementTypeDef = {
  __typename?: 'HealthDataMeasurementTypeDef'
  icon?: Maybe<UiIcon>
  /** @deprecated Use icon instead */
  iconDef?: Maybe<IconDef>
  isDailyMeasurement: Scalars['Boolean']['output']
  key: HealthDataMeasurementType
  label: Scalars['String']['output']
}

export enum HealthDataMetricBiomarker {
  Bmi = 'bmi',
  FastedGlucose = 'fasted_glucose',
  FastedInsulin = 'fasted_insulin',
  HbA1c = 'hb_a1c',
  HdlCholesterol = 'hdl_cholesterol',
  Height = 'height',
  HsCrp = 'hs_crp',
  LdlCholesterol = 'ldl_cholesterol',
  TotalCholesterol = 'total_cholesterol',
  Triglycerides = 'triglycerides',
  Tsh = 'tsh',
  VitaminD = 'vitamin_d',
}

export type HealthKit = {
  __typename?: 'HealthKit'
  ignore: Array<Scalars['String']['output']>
  sourceIgnore: Array<Scalars['String']['output']>
}

export type HealthKitData = {
  appleExerciseTime?: InputMaybe<Array<NumericValue>>
  bloodGlucose?: InputMaybe<Array<NumericValue>>
  bloodPressure?: InputMaybe<Array<NumericValue>>
  bodyFatPercentage?: InputMaybe<Array<NumericValue>>
  bodyTemperature?: InputMaybe<Array<NumericValue>>
  dailyDistanceCycling?: InputMaybe<Array<NumericValue>>
  dailyDistanceSwimming?: InputMaybe<Array<NumericValue>>
  dailyDistanceWalkingRunning?: InputMaybe<Array<NumericValue>>
  dailyFlightsClimbed?: InputMaybe<Array<NumericValue>>
  dailyStepCount?: InputMaybe<Array<NumericValue>>
  heartRate?: InputMaybe<Array<NumericValue>>
  respiratoryRate?: InputMaybe<Array<NumericValue>>
  sleep?: InputMaybe<Array<StringValue>>
  source?: InputMaybe<Scalars['String']['input']>
  weight?: InputMaybe<Array<NumericValue>>
  workout?: InputMaybe<Array<Workout>>
}

export type HealthMetric = {
  __typename?: 'HealthMetric'
  date: Scalars['ISO8601Date']['output']
  id: Scalars['ID']['output']
  kind: HealthDataMetricBiomarker
  value: Scalars['Float']['output']
}

export type HealthMetricCollection = {
  __typename?: 'HealthMetricCollection'
  healthMetrics: Array<HealthMetric>
  measurements: Array<Measurement>
}

export type HealthMetricData = {
  kind: HealthDataMetricBiomarker
  value: Scalars['Float']['input']
}

export type HealthMetricType = {
  __typename?: 'HealthMetricType'
  key: HealthMetricsParam
  kind: HealthMetricsKind
  label: Scalars['String']['output']
  unit?: Maybe<Scalars['String']['output']>
}

export enum HealthMetricsKind {
  Biomarker = 'biomarker',
  Measurement = 'measurement',
}

export enum HealthMetricsParam {
  BloodPressure = 'blood_pressure',
  Bmi = 'bmi',
  FastedGlucose = 'fasted_glucose',
  FastedInsulin = 'fasted_insulin',
  HbA1c = 'hb_a1c',
  HdlCholesterol = 'hdl_cholesterol',
  Height = 'height',
  HsCrp = 'hs_crp',
  LdlCholesterol = 'ldl_cholesterol',
  TotalCholesterol = 'total_cholesterol',
  Triglycerides = 'triglycerides',
  Tsh = 'tsh',
  VitaminD = 'vitamin_d',
  Weight = 'weight',
}

export type HealthSettings = {
  __typename?: 'HealthSettings'
  dashboardGraphOptions: Array<StringSelect>
  fastingEnd: Scalars['Int']['output']
  fastingStart: Scalars['Int']['output']
  glucoseHighOptions: Array<NumericalSelect>
  glucoseLowOptions: Array<NumericalSelect>
  glucoseSourceOptions: Array<StringSelect>
  glucoseThresholdPresets: Array<GlucoseThresholdPreset>
  ketoneHighOptions: Array<NumericalSelect>
  ketoneLowOptions: Array<NumericalSelect>
  macroGoalSettings: MacroGoalSettings
  scanReminderOptions: Array<NumericalSelect>
  trackingModeOptions: Array<StringSelect>
  unitSystemOptions: Array<StringSelect>
}

export type History = {
  __typename?: 'History'
  filters: HistoryFilters
}

export type HistoryFilters = {
  __typename?: 'HistoryFilters'
  order: Array<Scalars['String']['output']>
  orderBy: Array<Scalars['String']['output']>
  types: Array<KeyValuePairStringArray>
}

export type HoursInterval = {
  __typename?: 'HoursInterval'
  glucoseCheckOptions: Array<NumericalSelect>
}

export type IconDef = {
  __typename?: 'IconDef'
  name: Scalars['String']['output']
  pack: Scalars['String']['output']
}

export type ImageDescription = {
  __typename?: 'ImageDescription'
  description: Scalars['String']['output']
}

export type Ingredient = {
  __typename?: 'Ingredient'
  calories: Scalars['Float']['output']
  createdAt: Scalars['ISO8601DateTime']['output']
  description: Scalars['String']['output']
  id: Scalars['ID']['output']
  nutrition: Array<KeyValuePairFloat>
  servingAmount: Scalars['Float']['output']
  servingUnits: Scalars['String']['output']
  thirdPartyIngredientId?: Maybe<Scalars['String']['output']>
  thirdPartyIngredientSource?: Maybe<IngredientSource>
}

export type IngredientCollection = {
  __typename?: 'IngredientCollection'
  ingredients: Array<Ingredient>
}

export type IngredientData = {
  calories: Scalars['Float']['input']
  description: Scalars['String']['input']
  ingredientId?: InputMaybe<Scalars['ID']['input']>
  mealId?: InputMaybe<Scalars['ID']['input']>
  nutrition: Array<KeyValueFloat>
  servingAmount: Scalars['Float']['input']
  servingUnits: Scalars['String']['input']
  thirdPartyIngredientId?: InputMaybe<Scalars['String']['input']>
  thirdPartyIngredientSource?: InputMaybe<IngredientSource>
}

export type IngredientFilter = {
  endDate?: InputMaybe<Scalars['String']['input']>
  endHour?: InputMaybe<Scalars['Int']['input']>
  excludeTypes?: InputMaybe<Array<Scalars['String']['input']>>
  favorite?: InputMaybe<Scalars['Boolean']['input']>
  graph?: InputMaybe<Scalars['String']['input']>
  includeTypes?: InputMaybe<Array<Scalars['String']['input']>>
  order?: InputMaybe<Scalars['String']['input']>
  orderBy?: InputMaybe<Scalars['String']['input']>
  query?: InputMaybe<Scalars['String']['input']>
  scope?: InputMaybe<Scalars['String']['input']>
  startDate?: InputMaybe<Scalars['String']['input']>
  startHour?: InputMaybe<Scalars['Int']['input']>
  types?: InputMaybe<Array<KeyValueStringArray>>
}

export enum IngredientSource {
  BiteAi = 'biteAI',
  Myfitnesspal = 'myfitnesspal',
  Nutritionix = 'nutritionix',
}

export type InlineAlert = {
  __typename?: 'InlineAlert'
  message: Scalars['String']['output']
  type: UiInlineAlert
}

export enum InsuranceBerryStreetAppointmentChargeType {
  Free = 'free',
  InsuranceCovered = 'insurance_covered',
  Paid = 'paid',
}

export enum InsuranceBerryStreetAppointmentMeetingStatus {
  Cancelled = 'cancelled',
  LateCancellation = 'late_cancellation',
  NoShow = 'no_show',
  Occurred = 'occurred',
  PendingReschedule = 'pending_reschedule',
  Rescheduled = 'rescheduled',
}

export enum InsuranceBerryStreetAppointmentSchedulingRule {
  FreeSingle = 'free_single',
  NoScheduling = 'no_scheduling',
  PaidBundle = 'paid_bundle',
  PaidSingle = 'paid_single',
}

export enum InsuranceBerryStreetAppointmentStatus {
  Deleted = 'deleted',
  InReview = 'in_review',
  ReviewCompleted = 'review_completed',
  SchedulingPending = 'scheduling_pending',
}

export enum InsuranceBerryStreetAppointmentType {
  DppSession = 'dpp_session',
  Followup = 'followup',
  Followup_30 = 'followup_30',
  Followup_45 = 'followup_45',
  Followup_90 = 'followup_90',
  Initial = 'initial',
  Initial_60 = 'initial_60',
  NoneCovered = 'none_covered',
}

export type InsuranceCardSubmission = {
  __typename?: 'InsuranceCardSubmission'
  id: Scalars['ID']['output']
  insurancePolicyStateId: Scalars['ID']['output']
}

export type InsuranceKind = {
  __typename?: 'InsuranceKind'
  insuranceSurveyAnswer: Scalars['String']['output']
  key: UserInsurancePolicyKind
  title: Scalars['String']['output']
}

export type InsuranceKinds = {
  __typename?: 'InsuranceKinds'
  kindDefs: Array<UserInsurancePolicyKindDef>
}

export type InsurancePolicy = {
  __typename?: 'InsurancePolicy'
  /** @deprecated User last_state instead */
  eligibilityStatus?: Maybe<UserInsurancePolicyEligibilityStatus>
  id: Scalars['ID']['output']
  initialAppointmentType?: Maybe<AppointmentType>
  initialAppointmentTypes: Array<AppointmentType>
  kind?: Maybe<InsuranceKind>
  last: Scalars['Boolean']['output']
  lastState?: Maybe<InsurancePolicyState>
  memberId: Scalars['String']['output']
  states?: Maybe<Array<InsurancePolicyState>>
  /** @deprecated User last_state instead */
  status?: Maybe<UserInsurancePolicyStatus>
  unitsAuthorized?: Maybe<Scalars['Int']['output']>
  unitsLeft?: Maybe<Scalars['Int']['output']>
  unitsOccurred?: Maybe<Scalars['Int']['output']>
  unitsUsed?: Maybe<Scalars['Int']['output']>
}

export type InsurancePolicyCollection = {
  __typename?: 'InsurancePolicyCollection'
  canCreateNewPolicy: Scalars['Boolean']['output']
  insurancePolicies: Array<InsurancePolicy>
}

export type InsurancePolicyState = {
  __typename?: 'InsurancePolicyState'
  createdAt: Scalars['ISO8601DateTime']['output']
  id: Scalars['ID']['output']
  kind: UserInsurancePolicyStateKind
  reason?: Maybe<Scalars['String']['output']>
}

export type IntegrationUser = {
  __typename?: 'IntegrationUser'
  accessToken: Scalars['String']['output']
  accessTokenExpirationDate: Scalars['ISO8601DateTime']['output']
  idToken: Scalars['String']['output']
  refreshToken: Scalars['String']['output']
}

export type IntegrationUserSetting = {
  accessToken: Scalars['String']['input']
  accessTokenExpirationDate: Scalars['ISO8601DateTime']['input']
  idToken?: InputMaybe<Scalars['String']['input']>
  refreshToken: Scalars['String']['input']
}

export type IntegrationsSync = {
  __typename?: 'IntegrationsSync'
  dexcomUser?: Maybe<IntegrationUser>
  ketoMojoUser?: Maybe<IntegrationUser>
  lastSyncedDexcom?: Maybe<Scalars['ISO8601DateTime']['output']>
  lastSyncedHealth?: Maybe<Scalars['ISO8601DateTime']['output']>
  lastSyncedKetoMojo?: Maybe<Scalars['ISO8601DateTime']['output']>
  lastSyncedLibreView?: Maybe<Scalars['ISO8601DateTime']['output']>
}

export type IntegrationsSyncSettings = {
  dexcomUser?: InputMaybe<IntegrationUserSetting>
  ketoMojoUser?: InputMaybe<IntegrationUserSetting>
  lastSyncedDexcom?: InputMaybe<Scalars['ISO8601DateTime']['input']>
  lastSyncedHealth?: InputMaybe<Scalars['ISO8601DateTime']['input']>
  lastSyncedKetoMojo?: InputMaybe<Scalars['ISO8601DateTime']['input']>
  lastSyncedLibreView?: InputMaybe<Scalars['ISO8601DateTime']['input']>
}

export type Interval = {
  __typename?: 'Interval'
  description: Scalars['String']['output']
  key: Scalars['String']['output']
  suffix: Scalars['String']['output']
}

export type IntroScreen = {
  __typename?: 'IntroScreen'
  footnote?: Maybe<Scalars['String']['output']>
  header: Scalars['String']['output']
  icon?: Maybe<UiIcon>
  imageUrl?: Maybe<Scalars['String']['output']>
  inlineAlert?: Maybe<InlineAlert>
  /** @deprecated use intro_markdown */
  intro?: Maybe<Scalars['String']['output']>
  introMarkdown?: Maybe<Scalars['String']['output']>
}

export type Invoice = {
  __typename?: 'Invoice'
  created: Scalars['ISO8601DateTime']['output']
  downloadUrl?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  latestCharge?: Maybe<Charge>
  paidAt?: Maybe<Scalars['ISO8601DateTime']['output']>
  periodStart: Scalars['ISO8601DateTime']['output']
  /** @deprecated not used anymore */
  primaryInvoiceLine?: Maybe<InvoiceLine>
  primaryProduct: ProductMin
  receiptNumber?: Maybe<Scalars['String']['output']>
  status: InvoiceStatus
  stripeId: Scalars['String']['output']
  /** @deprecated use status enum field */
  stripeStatus: Scalars['String']['output']
  subscription?: Maybe<Subscription>
  total?: Maybe<Scalars['Float']['output']>
  updatedAt: Scalars['ISO8601DateTime']['output']
  user: User
}

export type InvoiceLine = {
  __typename?: 'InvoiceLine'
  id: Scalars['ID']['output']
  plan?: Maybe<Plan>
}

export enum InvoiceStatus {
  Draft = 'draft',
  Open = 'open',
  Paid = 'paid',
  Uncollectible = 'uncollectible',
  Void = 'void',
}

export type JournalConfig = {
  __typename?: 'JournalConfig'
  measurementTypes: Array<MeasurementType>
  range: JournalRange
}

export type JournalEntry = {
  __typename?: 'JournalEntry'
  body: Scalars['String']['output']
  createdAt: Scalars['ISO8601DateTime']['output']
  createdBy: Scalars['String']['output']
  creatorId: Scalars['ID']['output']
  id: Scalars['ID']['output']
  kind: NoteKind
  measurements?: Maybe<Array<Measurement>>
  occurredAt: Scalars['ISO8601DateTime']['output']
  score?: Maybe<Scalars['Float']['output']>
  title: Scalars['String']['output']
}

export type JournalMeasurementsData = {
  id?: InputMaybe<Scalars['ID']['input']>
  type: Scalars['String']['input']
  value: Scalars['Int']['input']
}

export type JournalRange = {
  __typename?: 'JournalRange'
  max: Scalars['Int']['output']
  min: Scalars['Int']['output']
}

export type KetoMojoData = {
  readingId: Scalars['String']['input']
  readingTimestamp: Scalars['ISO8601DateTime']['input']
  readingType: Scalars['String']['input']
  readingUnit?: InputMaybe<Scalars['String']['input']>
  readingValue?: InputMaybe<Scalars['Float']['input']>
}

export type KeyValueFloat = {
  key: Scalars['String']['input']
  value: Scalars['Float']['input']
}

export type KeyValuePairFloat = {
  __typename?: 'KeyValuePairFloat'
  key: Scalars['String']['output']
  value: Scalars['Float']['output']
}

export type KeyValuePairString = {
  __typename?: 'KeyValuePairString'
  key: Scalars['String']['output']
  value: Scalars['String']['output']
}

export type KeyValuePairStringArray = {
  __typename?: 'KeyValuePairStringArray'
  key: Scalars['String']['output']
  value: Array<Scalars['String']['output']>
}

export type KeyValueString = {
  key: Scalars['String']['input']
  value: Scalars['String']['input']
}

export type KeyValueStringArray = {
  key: Scalars['String']['input']
  value: Array<Scalars['String']['input']>
}

export type LatestUserAppointment = {
  __typename?: 'LatestUserAppointment'
  addToGoogleCalendarLink?: Maybe<Scalars['String']['output']>
  appointmentType?: Maybe<InsuranceBerryStreetAppointmentType>
  chargeType?: Maybe<InsuranceBerryStreetAppointmentChargeType>
  coachProfile?: Maybe<CoachProfile>
  date?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  meetingStatus?: Maybe<InsuranceBerryStreetAppointmentMeetingStatus>
  nutritionistName?: Maybe<Scalars['String']['output']>
  status: InsuranceBerryStreetAppointmentStatus
  title?: Maybe<Scalars['String']['output']>
  unauthenticatedIcsLink?: Maybe<Scalars['String']['output']>
}

export type LegalPolicy = {
  __typename?: 'LegalPolicy'
  kind: LegalPolicyConsentKind
  markdown?: Maybe<Scalars['String']['output']>
  title: Scalars['String']['output']
  url?: Maybe<Scalars['String']['output']>
}

export type LegalPolicyConsent = {
  __typename?: 'LegalPolicyConsent'
  createdAt?: Maybe<Scalars['ISO8601DateTime']['output']>
  kind: LegalPolicyConsentKind
  state: LegalPolicyConsentState
}

export enum LegalPolicyConsentKind {
  CompoundedSemaglutide = 'compounded_semaglutide',
  ConsumerHealthDataPrivacyPolicy = 'consumer_health_data_privacy_policy',
  FloridaDigitalBillOfRights = 'florida_digital_bill_of_rights',
  FuturePayments = 'future_payments',
  HipaaAuthorization = 'hipaa_authorization',
  OngoingCareNonRefundable = 'ongoing_care_non_refundable',
  PhoneNumber = 'phone_number',
  PrescriptionRefill = 'prescription_refill',
  PrivacyPolicy = 'privacy_policy',
  Telehealth = 'telehealth',
  TermsOfUse = 'terms_of_use',
}

export enum LegalPolicyConsentState {
  Accepted = 'accepted',
  Invalidated = 'invalidated',
}

export type LegalPolicyConsentStatus = {
  __typename?: 'LegalPolicyConsentStatus'
  kind: LegalPolicyConsentKind
  lastPolicyConsent?: Maybe<LegalPolicyConsent>
  policy: LegalPolicy
  required: Scalars['Boolean']['output']
}

export type LegalPolicyConsentStatuses = {
  __typename?: 'LegalPolicyConsentStatuses'
  statuses: Array<LegalPolicyConsentStatus>
}

export type Lesson = {
  __typename?: 'Lesson'
  contentId: Scalars['ID']['output']
  id: Scalars['ID']['output']
  position?: Maybe<Scalars['Int']['output']>
  progress?: Maybe<Progress>
}

export type LibreAccount = {
  __typename?: 'LibreAccount'
  libreId: Scalars['String']['output']
}

export enum LibreAccountRegion {
  Ap = 'ap',
  Au = 'au',
  Ca = 'ca',
  De = 'de',
  Eu = 'eu',
  Fr = 'fr',
  Jp = 'jp',
  Me = 'me',
  Us = 'us',
}

export enum LibreLinkupConnectionStatus {
  AuthenticationInitiated = 'authentication_initiated',
  AuthorizationCodeSent = 'authorization_code_sent',
  Connected = 'connected',
  ConnectionAuthorized = 'connection_authorized',
  Dead = 'dead',
  Failed = 'failed',
  Inactive = 'inactive',
  InviteSent = 'invite_sent',
}

export type Link = {
  __typename?: 'Link'
  text: Scalars['String']['output']
  url: Scalars['String']['output']
}

export type LinkupConnection = {
  __typename?: 'LinkupConnection'
  active: Scalars['Boolean']['output']
  lastSyncedAt?: Maybe<Scalars['ISO8601DateTime']['output']>
  region?: Maybe<LibreAccountRegion>
  status: LibreLinkupConnectionStatus
}

export type ListFiles = {
  __typename?: 'ListFiles'
  files: Array<File>
  pageCount?: Maybe<Scalars['Int']['output']>
  totalCount: Scalars['Int']['output']
}

export type ListFilters = {
  __typename?: 'ListFilters'
  order: Array<Scalars['String']['output']>
  orderBy: Array<Scalars['String']['output']>
  types: Array<Scalars['String']['output']>
}

/** Autogenerated return type of ListPlansAction. */
export type ListPlansActionPayload = {
  __typename?: 'ListPlansActionPayload'
  insurancePlans: Array<InsuranceKind>
}

export type ListProviders = {
  __typename?: 'ListProviders'
  providers: Array<Provider>
}

export type Macro = {
  __typename?: 'Macro'
  indent: Scalars['Int']['output']
  name: Scalars['String']['output']
}

export type MacroGoalSettings = {
  __typename?: 'MacroGoalSettings'
  caloriesMax: Scalars['Int']['output']
  carbsMax: Scalars['Int']['output']
  fatMax: Scalars['Int']['output']
  proteinMax: Scalars['Int']['output']
}

export type MacroGoals = {
  __typename?: 'MacroGoals'
  calories: Scalars['Int']['output']
  carbs: Scalars['Int']['output']
  fat: Scalars['Int']['output']
  netCarbs?: Maybe<Scalars['Int']['output']>
  protein: Scalars['Int']['output']
}

export type MacroGoalsDaily = {
  calories?: InputMaybe<Scalars['Int']['input']>
  carbs?: InputMaybe<Scalars['Int']['input']>
  fat?: InputMaybe<Scalars['Int']['input']>
  netCarbs?: InputMaybe<Scalars['Int']['input']>
  protein?: InputMaybe<Scalars['Int']['input']>
}

export type MarketingInfo = {
  utm?: InputMaybe<Scalars['JSON']['input']>
}

export type Meal = {
  __typename?: 'Meal'
  avatar?: Maybe<Scalars['String']['output']>
  createdAt: Scalars['ISO8601DateTime']['output']
  createdBy: User
  description: Scalars['String']['output']
  favorite: Scalars['Boolean']['output']
  generatedOccurredAt?: Maybe<Scalars['Boolean']['output']>
  glucose: EntryGlucose
  id: Scalars['ID']['output']
  ingredients: Array<Ingredient>
  kind?: Maybe<MealKind>
  nutrition: Array<KeyValuePairFloat>
  occurredAt: Scalars['ISO8601DateTime']['output']
  source: MealSource
  statistics: EntryStatistics
  /** @deprecated use kind enum field */
  time?: Maybe<Scalars['String']['output']>
  title: Scalars['String']['output']
  /** @deprecated use kind enum field */
  type: Scalars['String']['output']
  updatedAt: Scalars['ISO8601DateTime']['output']
}

export type MealCollection = {
  __typename?: 'MealCollection'
  meals: Array<Meal>
}

export type MealFilter = {
  endDate?: InputMaybe<Scalars['String']['input']>
  endHour?: InputMaybe<Scalars['Int']['input']>
  excludeTypes?: InputMaybe<Array<Scalars['String']['input']>>
  favorite?: InputMaybe<Scalars['Boolean']['input']>
  graph?: InputMaybe<Scalars['String']['input']>
  includeTypes?: InputMaybe<Array<Scalars['String']['input']>>
  order?: InputMaybe<Scalars['String']['input']>
  orderBy?: InputMaybe<Scalars['String']['input']>
  query?: InputMaybe<Scalars['String']['input']>
  scope?: InputMaybe<Scalars['String']['input']>
  startDate?: InputMaybe<Scalars['String']['input']>
  startHour?: InputMaybe<Scalars['Int']['input']>
  types?: InputMaybe<Array<KeyValueStringArray>>
}

export enum MealKind {
  Breakfast = 'breakfast',
  Dessert = 'dessert',
  Dinner = 'dinner',
  Drink = 'drink',
  Lunch = 'lunch',
  Snack = 'snack',
  Supplement = 'supplement',
}

export enum MealSource {
  HealthPortal = 'health_portal',
  MobileApp = 'mobile_app',
  MyFitnessPal = 'my_fitness_pal',
}

export type Meals = {
  __typename?: 'Meals'
  filters: ListFilters
}

export type Measurement = {
  __typename?: 'Measurement'
  createdAt: Scalars['ISO8601DateTime']['output']
  cumulative: Scalars['Boolean']['output']
  description: Scalars['String']['output']
  externalSource?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  interpolated: Scalars['Boolean']['output']
  occurredAt: Scalars['ISO8601DateTime']['output']
  scan?: Maybe<Scan>
  timestamp: Scalars['ISO8601DateTime']['output']
  title: Scalars['String']['output']
  type: Scalars['String']['output']
  units: Scalars['String']['output']
  value?: Maybe<Scalars['Float']['output']>
  values?: Maybe<MeasurementMultiValue>
}

export type MeasurementCollection = {
  __typename?: 'MeasurementCollection'
  measurements: Array<Measurement>
}

export type MeasurementConfig = {
  __typename?: 'MeasurementConfig'
  imperial: MeasurementConfigUnit
  metric: MeasurementConfigUnit
  name: Scalars['String']['output']
}

export type MeasurementConfigUnit = {
  __typename?: 'MeasurementConfigUnit'
  maxThreshold?: Maybe<Scalars['Float']['output']>
  minThreshold?: Maybe<Scalars['Float']['output']>
  precision: Scalars['Int']['output']
  units: Scalars['String']['output']
}

export type MeasurementData = {
  id?: InputMaybe<Scalars['ID']['input']>
  time?: InputMaybe<Scalars['String']['input']>
  type: Scalars['String']['input']
  value?: InputMaybe<Scalars['Float']['input']>
  values?: InputMaybe<MultiValue>
}

export type MeasurementFilter = {
  endDate?: InputMaybe<Scalars['String']['input']>
  endHour?: InputMaybe<Scalars['Int']['input']>
  excludeTypes?: InputMaybe<Array<Scalars['String']['input']>>
  favorite?: InputMaybe<Scalars['Boolean']['input']>
  graph?: InputMaybe<Scalars['String']['input']>
  includeTypes?: InputMaybe<Array<Scalars['String']['input']>>
  order?: InputMaybe<Scalars['String']['input']>
  orderBy?: InputMaybe<Scalars['String']['input']>
  query?: InputMaybe<Scalars['String']['input']>
  scope?: InputMaybe<Scalars['String']['input']>
  startDate?: InputMaybe<Scalars['String']['input']>
  startHour?: InputMaybe<Scalars['Int']['input']>
  types?: InputMaybe<Array<KeyValueStringArray>>
}

export type MeasurementMultiValue = BloodPressureMeasurement | SingleValueMeasurement

export type MeasurementType = {
  __typename?: 'MeasurementType'
  reverse?: Maybe<Scalars['Boolean']['output']>
  type: Scalars['String']['output']
}

export enum MobileAppFeature {
  ActivateOwnSensor = 'activate_own_sensor',
  AdminAccess = 'admin_access',
  AdminImpersonate = 'admin_impersonate',
  DppDashboard = 'dpp_dashboard',
  InsuranceCoveredVideoCalls = 'insurance_covered_video_calls',
  ScanCgm = 'scan_cgm',
  ViewGlucoseChart = 'view_glucose_chart',
}

export type Module = {
  __typename?: 'Module'
  contentId: Scalars['ID']['output']
  id: Scalars['ID']['output']
  position?: Maybe<Scalars['Int']['output']>
  progress?: Maybe<Progress>
}

export type MultiValue = {
  diastolic?: InputMaybe<Scalars['Float']['input']>
  systolic?: InputMaybe<Scalars['Float']['input']>
  value?: InputMaybe<Scalars['Float']['input']>
}

export type MutationType = {
  __typename?: 'MutationType'
  acceptLegalPolicy: LegalPolicyConsent
  activatePrescriptionDexcom: ActivatePrescriptionDexcomActionPayload
  bookVideoCall: LatestUserAppointment
  cancelEhrAppointment: EhrAppointment
  cancelSubscription: Subscription
  cancelVideoCall?: Maybe<Appointment>
  claimFreeEhrAppointment: EhrAppointment
  claimFreeVideoCall: LatestUserAppointment
  completeLinkupConnection: LinkupConnection
  completeNotification: Notification
  completeSurvey: SurveyLink
  createActivation: Sensor
  createActivity: Activity
  createAppleAdsAttribution: CreateActionPayload
  createBluetoothScan: Scan
  createChatConversationNote: ChatConversationNote
  createChatToken: Token
  createChatbotDelimiter: Delimiter
  /** @deprecated user createChatToken instead */
  createChatbotToken: Token
  createCustomIngredient: Ingredient
  createDirectUpload: DirectUpload
  createDppHealthDataRecord: Success
  createEhrChartNote: Success
  createFileFromSignedId: File
  createHealthMetric: HealthMetric
  createInsurancePolicy?: Maybe<InsurancePolicy>
  createJournalEntry: JournalEntry
  createMeal: Meal
  createMeasurement: Measurement
  /** @deprecated Use :upsert_note */
  createNote: Note
  createOneTimePayment: CreatedOneTimePayment
  createProviderSetupIntent: ProviderSetupIntent
  createRefillConsent: UserRefillConsent
  createReminder: Reminder
  createScan: Scan
  createSubscription: CreatedSubscription
  createSubscriptionBonus: Success
  createThirdPartyUser: UserAuth
  createUser: UserAuth
  deauthTerraProvider: ListProviders
  deleteActivity: Activity
  deleteCustomIngredient: Ingredient
  deleteDexcomConnection?: Maybe<DexcomConnectionType>
  deleteEhrAppointmentRecurrence: Success
  deleteFile: File
  deleteHealthMetric: Success
  deleteJournalEntry: JournalEntry
  deleteMeal: Meal
  deleteMeasurement: Measurement
  deleteNote: Note
  deleteReminder: Reminder
  disableLinkupConnection: LinkupConnection
  dismissNotification: Notification
  ehrBookAppointment: EhrBookedAppointment
  initLinkupConnection: LinkupConnection
  invalidateRefillConsent: UserRefillConsent
  joinDppCourseCohort: Cohort
  markDailyActionComplete: Success
  markDailyActionIncomplete: Success
  pauseSubscription: Subscription
  recordLessonProgress: RecordedProgress
  registerNotificationToken: RegisterNotificationTokenActionPayload
  rescheduleEhrAppointment: EhrAppointment
  rescheduleVideoCall: Appointment
  resetPassword: Success
  saveSurveyResponse: SurveyResponse
  scheduleReminder: Reminder
  scheduleSubscriptionChange: Subscription
  /** @deprecated use select_sensor instead */
  selectDefaultSensorForDexcomResearch: Success
  selectSensor: Success
  sendChatConversationMessage: Success
  setPrimaryGoalMetrics: Array<GoalMetric>
  setSubscriptionBillingCycle: Subscription
  signInPasswordlessUser: UserAuth
  signInThirdPartyUser: UserAuth
  signOutUser: Success
  signinUser?: Maybe<UserAuth>
  startDexcomResearchParticipation: Success
  startSurvey: SurveyLink
  submitHealthMetrics: Success
  submitInsuranceCard: InsuranceCardSubmission
  submitInsurancePolicy: InsurancePolicy
  switchDppCourseCohortSession: Success
  switchSensorOnCancellation: FulfillmentConfiguration
  toggleProviderDataType: ListProviders
  uncancelSubscription: Subscription
  updateAddress: Address
  updateDailyMeasurementsSourcePriority: DailyMeasurementsSourcePriority
  updateDppCourseCohortSessionAttendance: CohortSessionAttendance
  updateEffectiveDailyMeasurement: EffectiveDailyMeasurement
  updateHealthData: Success
  updateHealthMetric: HealthMetric
  updateJournalEntry: JournalEntry
  updateKetoMojoData: Success
  updateReminder: Reminder
  updateSensorSettings: Sensor
  updateSensorStatus: Sensor
  updateSettings: UserSettings
  updateUser: User
  upgradeSubscriptionProduct: UpgradedSubscription
  upgradeSubscriptionTrial: UpgradedSubscription
  upsertDailyAction: DailyAction
  upsertDexcomConnection: DexcomConnectionType
  upsertEhrAppointmentRecurrence: EhrAppointmentRecurrence
  upsertNote: Note
  upsertPrimaryGoal: Goal
  upsertUiState: UiState
}

export type MutationTypeAcceptLegalPolicyArgs = {
  kind: LegalPolicyConsentKind
}

export type MutationTypeBookVideoCallArgs = {
  agenda?: InputMaybe<Scalars['String']['input']>
  appointmentType?: InputMaybe<InsuranceBerryStreetAppointmentType>
  coachId?: InputMaybe<Scalars['ID']['input']>
  date?: InputMaybe<Scalars['String']['input']>
  pendingAppointmentId?: InputMaybe<Scalars['ID']['input']>
  timeZone?: InputMaybe<Scalars['String']['input']>
}

export type MutationTypeCancelEhrAppointmentArgs = {
  id: Scalars['ID']['input']
}

export type MutationTypeCancelSubscriptionArgs = {
  id?: InputMaybe<Scalars['ID']['input']>
}

export type MutationTypeCancelVideoCallArgs = {
  id: Scalars['ID']['input']
}

export type MutationTypeCompleteLinkupConnectionArgs = {
  libre2faCode: Scalars['String']['input']
}

export type MutationTypeCompleteNotificationArgs = {
  id: Scalars['ID']['input']
}

export type MutationTypeCompleteSurveyArgs = {
  id: Scalars['ID']['input']
}

export type MutationTypeCreateActivationArgs = {
  data: ActivationData
}

export type MutationTypeCreateActivityArgs = {
  description?: InputMaybe<Scalars['String']['input']>
  endTime?: InputMaybe<Scalars['String']['input']>
  favorite?: InputMaybe<Scalars['Boolean']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  startTime?: InputMaybe<Scalars['String']['input']>
  type?: InputMaybe<Scalars['String']['input']>
}

export type MutationTypeCreateAppleAdsAttributionArgs = {
  anonymousId?: InputMaybe<Scalars['String']['input']>
  token?: InputMaybe<Scalars['String']['input']>
}

export type MutationTypeCreateBluetoothScanArgs = {
  data: BluetoothScanData
}

export type MutationTypeCreateChatConversationNoteArgs = {
  body?: InputMaybe<Scalars['String']['input']>
}

export type MutationTypeCreateChatbotDelimiterArgs = {
  providerConversationId: Scalars['ID']['input']
}

export type MutationTypeCreateCustomIngredientArgs = {
  calories?: InputMaybe<Scalars['Float']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  ingredientId?: InputMaybe<Scalars['ID']['input']>
  mealId?: InputMaybe<Scalars['ID']['input']>
  nutrition?: InputMaybe<Array<KeyValueFloat>>
  servingAmount?: InputMaybe<Scalars['Float']['input']>
  servingUnits?: InputMaybe<Scalars['String']['input']>
  thirdPartyIngredientId?: InputMaybe<Scalars['String']['input']>
  thirdPartyIngredientSource?: InputMaybe<IngredientSource>
}

export type MutationTypeCreateDirectUploadArgs = {
  file?: InputMaybe<FileMetadata>
}

export type MutationTypeCreateDppHealthDataRecordArgs = {
  kind?: InputMaybe<DppHealthDataRecordKind>
  userFileId?: InputMaybe<Scalars['ID']['input']>
  value?: InputMaybe<Scalars['Float']['input']>
}

export type MutationTypeCreateEhrChartNoteArgs = {
  appointmentId?: InputMaybe<Scalars['ID']['input']>
  data?: InputMaybe<Scalars['JSON']['input']>
  getHealthieInstanceKind?: InputMaybe<EhrGetHealthieInstanceKind>
  kind?: InputMaybe<EhrChartNoteKind>
  legacyAppointmentId?: InputMaybe<Scalars['ID']['input']>
}

export type MutationTypeCreateFileFromSignedIdArgs = {
  fileTag?: InputMaybe<Scalars['String']['input']>
  signedId?: InputMaybe<Scalars['String']['input']>
}

export type MutationTypeCreateHealthMetricArgs = {
  date?: InputMaybe<Scalars['ISO8601Date']['input']>
  kind?: InputMaybe<HealthDataMetricBiomarker>
  value?: InputMaybe<Scalars['Float']['input']>
}

export type MutationTypeCreateInsurancePolicyArgs = {
  groupId?: InputMaybe<Scalars['String']['input']>
  holderDateOfBirth?: InputMaybe<Scalars['ISO8601Date']['input']>
  holderFirstName?: InputMaybe<Scalars['String']['input']>
  holderLastName?: InputMaybe<Scalars['String']['input']>
  holderRelationship?: InputMaybe<UserInsurancePolicyHolderRelationship>
  kind?: InputMaybe<UserInsurancePolicyKind>
  memberId?: InputMaybe<Scalars['String']['input']>
}

export type MutationTypeCreateJournalEntryArgs = {
  body?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  kind?: InputMaybe<Scalars['String']['input']>
  measurements?: InputMaybe<Array<JournalMeasurementsData>>
  time?: InputMaybe<Scalars['String']['input']>
}

export type MutationTypeCreateMealArgs = {
  description?: InputMaybe<Scalars['String']['input']>
  favorite?: InputMaybe<Scalars['Boolean']['input']>
  favoriteId?: InputMaybe<Scalars['ID']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  imageUrl?: InputMaybe<Scalars['String']['input']>
  ingredients?: InputMaybe<Array<IngredientData>>
  kind?: InputMaybe<MealKind>
  photo?: InputMaybe<Scalars['Upload']['input']>
  tags?: InputMaybe<Array<Scalars['String']['input']>>
  time?: InputMaybe<Scalars['String']['input']>
  type?: InputMaybe<Scalars['String']['input']>
}

export type MutationTypeCreateMeasurementArgs = {
  id?: InputMaybe<Scalars['ID']['input']>
  time?: InputMaybe<Scalars['String']['input']>
  type?: InputMaybe<Scalars['String']['input']>
  value?: InputMaybe<Scalars['Float']['input']>
  values?: InputMaybe<MultiValue>
}

export type MutationTypeCreateNoteArgs = {
  body?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  kind?: InputMaybe<Scalars['String']['input']>
  pinned?: InputMaybe<Scalars['Boolean']['input']>
  time?: InputMaybe<Scalars['String']['input']>
}

export type MutationTypeCreateOneTimePaymentArgs = {
  address?: InputMaybe<OneTimePaymentAddress>
  email?: InputMaybe<Scalars['String']['input']>
  fullName?: InputMaybe<Scalars['String']['input']>
  metadata?: InputMaybe<Array<KeyValueString>>
  phoneNumber?: InputMaybe<Scalars['String']['input']>
  providerPaymentMethodId?: InputMaybe<Scalars['String']['input']>
  providerPromotionCodeId?: InputMaybe<Scalars['String']['input']>
  purchasableItems?: InputMaybe<Array<PurchasableItem>>
}

export type MutationTypeCreateProviderSetupIntentArgs = {
  email?: InputMaybe<Scalars['String']['input']>
  fullName?: InputMaybe<Scalars['String']['input']>
  partnerId?: InputMaybe<Scalars['String']['input']>
  phoneNumber?: InputMaybe<Scalars['String']['input']>
}

export type MutationTypeCreateReminderArgs = {
  dayOfWeek?: InputMaybe<NotificationEngineReminderDayOfWeek>
  frequency?: InputMaybe<NotificationEngineReminderFrequency>
  hoursInterval?: InputMaybe<Scalars['Int']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  kind?: InputMaybe<NotificationEngineReminderKind>
  sendOn?: InputMaybe<Scalars['ISO8601Date']['input']>
  timeOfDay?: InputMaybe<Scalars['String']['input']>
}

export type MutationTypeCreateScanArgs = {
  data: ScanData
}

export type MutationTypeCreateSubscriptionArgs = {
  address?: InputMaybe<SubscriptionAddress>
  bonusKinds?: InputMaybe<Array<SubscriptionBonusKind>>
  checkoutOptionKinds?: InputMaybe<Array<SubscriptionCheckoutOptionKind>>
  email?: InputMaybe<Scalars['String']['input']>
  fullName?: InputMaybe<Scalars['String']['input']>
  phoneNumber?: InputMaybe<Scalars['String']['input']>
  policyConsentKinds?: InputMaybe<Array<LegalPolicyConsentKind>>
  product?: InputMaybe<BillingProduct>
  providerPaymentMethodId?: InputMaybe<Scalars['String']['input']>
  providerPromotionCodeId?: InputMaybe<Scalars['String']['input']>
}

export type MutationTypeCreateSubscriptionBonusArgs = {
  bonusKind?: InputMaybe<Scalars['String']['input']>
  providerSubscriptionId?: InputMaybe<Scalars['ID']['input']>
  testGroup?: InputMaybe<Scalars['String']['input']>
}

export type MutationTypeCreateThirdPartyUserArgs = {
  authorization?: InputMaybe<ThirdPartySignIn>
  firstName?: InputMaybe<Scalars['String']['input']>
  lastName?: InputMaybe<Scalars['String']['input']>
  policyConsentKinds?: InputMaybe<Array<LegalPolicyConsentKind>>
}

export type MutationTypeCreateUserArgs = {
  dateOfBirth?: InputMaybe<Scalars['String']['input']>
  email?: InputMaybe<SignIn>
  firstName?: InputMaybe<Scalars['String']['input']>
  lastName?: InputMaybe<Scalars['String']['input']>
  marketingInfo?: InputMaybe<MarketingInfo>
  phoneNumber?: InputMaybe<Scalars['String']['input']>
  photo?: InputMaybe<Scalars['Upload']['input']>
  policyConsentKinds?: InputMaybe<Array<LegalPolicyConsentKind>>
  referralPartner?: InputMaybe<UserReferralPartner>
  sendResetPasswordEmail?: InputMaybe<Scalars['Boolean']['input']>
  sendSignInLinkEmail?: InputMaybe<Scalars['Boolean']['input']>
  sex?: InputMaybe<Scalars['String']['input']>
  source?: InputMaybe<UserSignUpSource>
}

export type MutationTypeDeauthTerraProviderArgs = {
  provider: Scalars['String']['input']
}

export type MutationTypeDeleteActivityArgs = {
  id?: InputMaybe<Scalars['ID']['input']>
}

export type MutationTypeDeleteCustomIngredientArgs = {
  id: Scalars['ID']['input']
}

export type MutationTypeDeleteFileArgs = {
  id: Scalars['ID']['input']
}

export type MutationTypeDeleteHealthMetricArgs = {
  id: Scalars['ID']['input']
}

export type MutationTypeDeleteJournalEntryArgs = {
  id?: InputMaybe<Scalars['ID']['input']>
}

export type MutationTypeDeleteMealArgs = {
  id?: InputMaybe<Scalars['ID']['input']>
}

export type MutationTypeDeleteMeasurementArgs = {
  id?: InputMaybe<Scalars['ID']['input']>
}

export type MutationTypeDeleteNoteArgs = {
  id?: InputMaybe<Scalars['ID']['input']>
}

export type MutationTypeDeleteReminderArgs = {
  id: Scalars['ID']['input']
}

export type MutationTypeDismissNotificationArgs = {
  id: Scalars['ID']['input']
}

export type MutationTypeEhrBookAppointmentArgs = {
  appointmentKind?: InputMaybe<EhrAppointmentKind>
  attendanceChargeKind?: InputMaybe<EhrAppointmentAttendanceChargeKind>
  coachId?: InputMaybe<Scalars['ID']['input']>
  date?: InputMaybe<Scalars['String']['input']>
  doctorId?: InputMaybe<Scalars['ID']['input']>
  isGroup?: InputMaybe<Scalars['Boolean']['input']>
  timeZone?: InputMaybe<Scalars['String']['input']>
}

export type MutationTypeInitLinkupConnectionArgs = {
  libreEmail: Scalars['String']['input']
  librePassword: Scalars['String']['input']
}

export type MutationTypeJoinDppCourseCohortArgs = {
  id: Scalars['ID']['input']
}

export type MutationTypeMarkDailyActionCompleteArgs = {
  dailyActionId?: InputMaybe<Scalars['ID']['input']>
  date?: InputMaybe<Scalars['ISO8601Date']['input']>
}

export type MutationTypeMarkDailyActionIncompleteArgs = {
  dailyActionId?: InputMaybe<Scalars['ID']['input']>
  date?: InputMaybe<Scalars['ISO8601Date']['input']>
}

export type MutationTypePauseSubscriptionArgs = {
  cycleCount?: InputMaybe<Scalars['Int']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
}

export type MutationTypeRecordLessonProgressArgs = {
  complete?: InputMaybe<Scalars['Boolean']['input']>
  lessonId?: InputMaybe<Scalars['ID']['input']>
}

export type MutationTypeRegisterNotificationTokenArgs = {
  id?: InputMaybe<Scalars['ID']['input']>
  token?: InputMaybe<Scalars['String']['input']>
  type?: InputMaybe<Scalars['String']['input']>
}

export type MutationTypeRescheduleEhrAppointmentArgs = {
  date?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
}

export type MutationTypeRescheduleVideoCallArgs = {
  date?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
}

export type MutationTypeResetPasswordArgs = {
  password?: InputMaybe<Scalars['String']['input']>
  passwordToken?: InputMaybe<Scalars['String']['input']>
}

export type MutationTypeSaveSurveyResponseArgs = {
  answer: Scalars['JSON']['input']
  questionKey: Scalars['String']['input']
  surveyLinkId: Scalars['ID']['input']
  warnBeforeStopping?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationTypeScheduleReminderArgs = {
  kind: NotificationEngineReminderKind
  sendOn: Scalars['ISO8601Date']['input']
}

export type MutationTypeScheduleSubscriptionChangeArgs = {
  id?: InputMaybe<Scalars['ID']['input']>
  product?: InputMaybe<BillingProduct>
}

export type MutationTypeSelectSensorArgs = {
  model: SensorModel
}

export type MutationTypeSendChatConversationMessageArgs = {
  body?: InputMaybe<Scalars['String']['input']>
}

export type MutationTypeSetPrimaryGoalMetricsArgs = {
  kinds?: InputMaybe<Array<UserGoalMetricKind>>
}

export type MutationTypeSetSubscriptionBillingCycleArgs = {
  id?: InputMaybe<Scalars['ID']['input']>
  newBillingCycleAnchor?: InputMaybe<Scalars['ISO8601DateTime']['input']>
}

export type MutationTypeSignInPasswordlessUserArgs = {
  signInToken?: InputMaybe<Scalars['String']['input']>
}

export type MutationTypeSignInThirdPartyUserArgs = {
  provider?: InputMaybe<ThirdPartySignInProvider>
  providerToken?: InputMaybe<Scalars['String']['input']>
  providerUserId?: InputMaybe<Scalars['String']['input']>
}

export type MutationTypeSigninUserArgs = {
  email?: InputMaybe<SignIn>
}

export type MutationTypeStartSurveyArgs = {
  kind: Scalars['String']['input']
}

export type MutationTypeSubmitHealthMetricsArgs = {
  date?: InputMaybe<Scalars['ISO8601Date']['input']>
  healthMetricsData?: InputMaybe<Array<HealthMetricData>>
  measurementsData?: InputMaybe<Array<MeasurementData>>
}

export type MutationTypeSubmitInsuranceCardArgs = {
  backPhotoFileId?: InputMaybe<Scalars['ID']['input']>
  frontPhotoFileId?: InputMaybe<Scalars['ID']['input']>
  insurancePolicyStateId?: InputMaybe<Scalars['ID']['input']>
}

export type MutationTypeSwitchDppCourseCohortSessionArgs = {
  currentSessionId: Scalars['ID']['input']
  switchSessionId: Scalars['ID']['input']
}

export type MutationTypeToggleProviderDataTypeArgs = {
  dataType: Scalars['String']['input']
  provider: Scalars['String']['input']
}

export type MutationTypeUncancelSubscriptionArgs = {
  id?: InputMaybe<Scalars['ID']['input']>
}

export type MutationTypeUpdateAddressArgs = {
  city?: InputMaybe<Scalars['String']['input']>
  country?: InputMaybe<AddressCountries>
  id?: InputMaybe<Scalars['ID']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  state?: InputMaybe<Scalars['String']['input']>
  street?: InputMaybe<Scalars['String']['input']>
  street2?: InputMaybe<Scalars['String']['input']>
  zipCode?: InputMaybe<Scalars['String']['input']>
}

export type MutationTypeUpdateDailyMeasurementsSourcePriorityArgs = {
  sources: Array<HealthDataMeasurementSource>
  type: HealthDataMeasurementType
}

export type MutationTypeUpdateDppCourseCohortSessionAttendanceArgs = {
  id: Scalars['ID']['input']
  rsvp: CoursesCohortSessionAttendanceRsvp
}

export type MutationTypeUpdateEffectiveDailyMeasurementArgs = {
  date: Scalars['String']['input']
  selectedSource?: InputMaybe<HealthDataMeasurementSource>
  type: HealthDataMeasurementType
}

export type MutationTypeUpdateHealthDataArgs = {
  healthData?: InputMaybe<HealthKitData>
}

export type MutationTypeUpdateHealthMetricArgs = {
  date?: InputMaybe<Scalars['ISO8601Date']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  value?: InputMaybe<Scalars['Float']['input']>
}

export type MutationTypeUpdateJournalEntryArgs = {
  body?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  kind?: InputMaybe<Scalars['String']['input']>
  measurements?: InputMaybe<Array<JournalMeasurementsData>>
  time?: InputMaybe<Scalars['String']['input']>
}

export type MutationTypeUpdateKetoMojoDataArgs = {
  data?: InputMaybe<Array<KetoMojoData>>
}

export type MutationTypeUpdateReminderArgs = {
  dayOfWeek?: InputMaybe<NotificationEngineReminderDayOfWeek>
  frequency?: InputMaybe<NotificationEngineReminderFrequency>
  hoursInterval?: InputMaybe<Scalars['Int']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  kind?: InputMaybe<NotificationEngineReminderKind>
  sendOn?: InputMaybe<Scalars['ISO8601Date']['input']>
  timeOfDay?: InputMaybe<Scalars['String']['input']>
}

export type MutationTypeUpdateSensorSettingsArgs = {
  baselineAdjustment?: InputMaybe<Scalars['Int']['input']>
}

export type MutationTypeUpdateSensorStatusArgs = {
  serialNumber?: InputMaybe<Scalars['String']['input']>
  status?: InputMaybe<SensorStatus>
}

export type MutationTypeUpdateSettingsArgs = {
  appVersionReminder?: InputMaybe<Scalars['Boolean']['input']>
  canScanExpired?: InputMaybe<Scalars['Boolean']['input']>
  dashboardGraph?: InputMaybe<Scalars['String']['input']>
  dexcomSync?: InputMaybe<Scalars['Boolean']['input']>
  displayActivityScore?: InputMaybe<Scalars['Boolean']['input']>
  displayDailyScore?: InputMaybe<Scalars['Boolean']['input']>
  displayMealScore?: InputMaybe<Scalars['Boolean']['input']>
  fastingEnd?: InputMaybe<Scalars['Int']['input']>
  fastingStart?: InputMaybe<Scalars['Int']['input']>
  glucoseHighThreshold?: InputMaybe<Scalars['Int']['input']>
  glucoseLowThreshold?: InputMaybe<Scalars['Int']['input']>
  glucoseSource?: InputMaybe<Scalars['String']['input']>
  googleFitSync?: InputMaybe<Scalars['Boolean']['input']>
  healthKitSync?: InputMaybe<Scalars['Boolean']['input']>
  integrationsSyncSettings?: InputMaybe<IntegrationsSyncSettings>
  ketoMojoSync?: InputMaybe<Scalars['Boolean']['input']>
  ketoneHighThreshold?: InputMaybe<Scalars['Float']['input']>
  ketoneLowThreshold?: InputMaybe<Scalars['Float']['input']>
  libreViewSync?: InputMaybe<Scalars['Boolean']['input']>
  macroGoalsDaily?: InputMaybe<MacroGoalsDaily>
  patternsNotifications?: InputMaybe<Scalars['Boolean']['input']>
  reminders?: InputMaybe<Scalars['Boolean']['input']>
  scanReminder?: InputMaybe<Scalars['Int']['input']>
  showNetCarbs?: InputMaybe<Scalars['Boolean']['input']>
  statsSettings?: InputMaybe<Array<StatsSettingsOrder>>
  trackingMode?: InputMaybe<TrackingMode>
  unitSystem?: InputMaybe<Scalars['String']['input']>
}

export type MutationTypeUpdateUserArgs = {
  dateOfBirth?: InputMaybe<Scalars['String']['input']>
  email?: InputMaybe<Scalars['String']['input']>
  firstName?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  lastName?: InputMaybe<Scalars['String']['input']>
  password?: InputMaybe<Scalars['String']['input']>
  phoneNumber?: InputMaybe<Scalars['String']['input']>
  photo?: InputMaybe<Scalars['Upload']['input']>
  sex?: InputMaybe<Scalars['String']['input']>
  timeZone?: InputMaybe<Scalars['String']['input']>
}

export type MutationTypeUpgradeSubscriptionProductArgs = {
  id?: InputMaybe<Scalars['ID']['input']>
  nextProduct?: InputMaybe<BillingProduct>
  providerPaymentMethodId?: InputMaybe<Scalars['String']['input']>
}

export type MutationTypeUpgradeSubscriptionTrialArgs = {
  id?: InputMaybe<Scalars['ID']['input']>
  providerPaymentMethodId?: InputMaybe<Scalars['String']['input']>
}

export type MutationTypeUpsertDailyActionArgs = {
  kind?: InputMaybe<UserDailyActionKind>
  status?: InputMaybe<UserDailyActionStatus>
}

export type MutationTypeUpsertDexcomConnectionArgs = {
  authorizationCode?: InputMaybe<Scalars['String']['input']>
}

export type MutationTypeUpsertEhrAppointmentRecurrenceArgs = {
  cadence?: InputMaybe<EhrAppointmentRecurrenceCadence>
  days?: InputMaybe<Array<EhrAppointmentRecurrenceDay>>
  numberOfCalls?: InputMaybe<Scalars['Int']['input']>
  timeSlots?: InputMaybe<Array<Scalars['ISO8601DateTime']['input']>>
}

export type MutationTypeUpsertNoteArgs = {
  body?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  kind?: InputMaybe<Scalars['String']['input']>
  pinned?: InputMaybe<Scalars['Boolean']['input']>
  time?: InputMaybe<Scalars['String']['input']>
}

export type MutationTypeUpsertPrimaryGoalArgs = {
  description?: InputMaybe<Scalars['String']['input']>
  kind?: InputMaybe<UserGoalKind>
}

export type MutationTypeUpsertUiStateArgs = {
  name?: InputMaybe<UiStateNames>
  value?: InputMaybe<Scalars['Boolean']['input']>
}

export type Note = {
  __typename?: 'Note'
  body: Scalars['String']['output']
  createdAt: Scalars['ISO8601DateTime']['output']
  createdBy: Scalars['String']['output']
  creator: User
  /** @deprecated Use creator instead */
  creatorId: Scalars['ID']['output']
  id: Scalars['ID']['output']
  kind: NoteKind
  occurredAt: Scalars['ISO8601DateTime']['output']
  pinnedAt?: Maybe<Scalars['ISO8601DateTime']['output']>
  title: Scalars['String']['output']
  updatedAt: Scalars['ISO8601DateTime']['output']
}

export type NoteCollection = {
  __typename?: 'NoteCollection'
  notes: Array<Note>
}

export type NoteDynamicFilter = {
  field: NoteDynamicFilterField
  operation: DynamicFilterItemOperation
  value: Scalars['String']['input']
}

export enum NoteDynamicFilterField {
  Kind = 'kind',
}

export type NoteDynamicFilterItem = {
  items: Array<NoteDynamicFilter>
  operator: DynamicFilterOperator
}

export enum NoteKind {
  DietitianNote = 'dietitian_note',
  DietitianUpNext = 'dietitian_up_next',
  GlucoseChart = 'glucose_chart',
  MemberNote = 'member_note',
  OpsAccountRequest = 'ops_account_request',
}

export type NoteSort = {
  direction: SortDirection
  field: NoteSortField
}

export enum NoteSortField {
  OccurredAt = 'occurred_at',
}

export type Notification = {
  __typename?: 'Notification'
  actionButtonLabel: Scalars['String']['output']
  actionKind: NotificationEngineNotificationActionKind
  canceledAt?: Maybe<Scalars['ISO8601DateTime']['output']>
  completedAt?: Maybe<Scalars['ISO8601DateTime']['output']>
  content: Scalars['String']['output']
  createdAt: Scalars['ISO8601DateTime']['output']
  deliveredAt?: Maybe<Scalars['ISO8601DateTime']['output']>
  dismissedAt?: Maybe<Scalars['ISO8601DateTime']['output']>
  id: Scalars['ID']['output']
  kind: NotificationEngineNotificationKind
  payload: Scalars['JSON']['output']
  screen: NotificationEngineScreen
  style: NotificationEngineNotificationStyle
  updatedAt: Scalars['ISO8601DateTime']['output']
}

export type NotificationCollection = {
  __typename?: 'NotificationCollection'
  notifications: Array<Notification>
}

export enum NotificationEngineNotificationActionKind {
  DeepLink = 'deep_link',
  EditExperimentScreen = 'edit_experiment_screen',
  EditJournalScreen = 'edit_journal_screen',
  ExternalLink = 'external_link',
  InsightsGlucoseScreen = 'insights_glucose_screen',
  InsightsMealScreen = 'insights_meal_screen',
  InsuranceEligibilityForm = 'insurance_eligibility_form',
  MealScreen = 'meal_screen',
  NewActivityScreen = 'new_activity_screen',
  NewJournalScreen = 'new_journal_screen',
  NewMealScreen = 'new_meal_screen',
  None = 'none',
  NoraPrompt = 'nora_prompt',
  ScanScreen = 'scan_screen',
}

export enum NotificationEngineNotificationKind {
  ActivityReminder = 'activity_reminder',
  CgmReviewVideoCallUpsell = 'cgm_review_video_call_upsell',
  ChatNewMessage = 'chat_new_message',
  CohortSessionAttendanceRsvp = 'cohort_session_attendance_rsvp',
  CohortSessionMakeUpMissed = 'cohort_session_make_up_missed',
  CohortSessionRsvpMissing = 'cohort_session_rsvp_missing',
  GlucoseCheckReminder = 'glucose_check_reminder',
  InsuranceOptionAvailable = 'insurance_option_available',
  InsurancePolicyPatientResponsibilityRequired = 'insurance_policy_patient_responsibility_required',
  JournalReminder = 'journal_reminder',
  LowDailyScoreVideoCallUpsell = 'low_daily_score_video_call_upsell',
  MealReminder = 'meal_reminder',
  MealScoreSpike = 'meal_score_spike',
  MeasurementReminder = 'measurement_reminder',
  MedicalConsultReminder = 'medical_consult_reminder',
  MedicalConsultStartingSoonReminder = 'medical_consult_starting_soon_reminder',
  NewCoachExperiment = 'new_coach_experiment',
  NewCoachNote = 'new_coach_note',
  NewEnrollableCohort = 'new_enrollable_cohort',
  PatternsGlucoseAverage = 'patterns_glucose_average',
  PatternsGlucoseMax = 'patterns_glucose_max',
  PatternsGlucoseMin = 'patterns_glucose_min',
  PatternsGlucoseMorningAverage = 'patterns_glucose_morning_average',
  PatternsGlucoseScore = 'patterns_glucose_score',
  PatternsGlucoseSleepAverage = 'patterns_glucose_sleep_average',
  PatternsGlucoseSpikeWithExercise = 'patterns_glucose_spike_with_exercise',
  PatternsGlucoseSpikeWithMeal = 'patterns_glucose_spike_with_meal',
  PatternsGlucoseTimeInRange = 'patterns_glucose_time_in_range',
  PatternsGlucoseVariability = 'patterns_glucose_variability',
  PatternsMealScore = 'patterns_meal_score',
  PatternsUnannotatedGlucoseSpike = 'patterns_unannotated_glucose_spike',
  ProactiveUpsell = 'proactive_upsell',
  ScanReminder = 'scan_reminder',
  SubmitLabResultsCustomDate = 'submit_lab_results_custom_date',
  SubmitLabResultsPeriodical = 'submit_lab_results_periodical',
  SurveyReminder = 'survey_reminder',
  VideoCallBookNow = 'video_call_book_now',
  VideoCallCheckInReminder = 'video_call_check_in_reminder',
  VideoCallClaimFreeCall = 'video_call_claim_free_call',
  VideoCallNoShowFeeChargeFailed = 'video_call_no_show_fee_charge_failed',
  VideoCallNoShowFeeCharged = 'video_call_no_show_fee_charged',
  VideoCallRecurrenceBookingCompleted = 'video_call_recurrence_booking_completed',
  VideoCallReminder = 'video_call_reminder',
  VideoCallStartingSoonReminder = 'video_call_starting_soon_reminder',
  VideoCallUpsell = 'video_call_upsell',
}

export enum NotificationEngineNotificationStyle {
  Chatlike = 'chatlike',
  Standard = 'standard',
}

export enum NotificationEngineReminderDayOfWeek {
  Friday = 'friday',
  Monday = 'monday',
  Saturday = 'saturday',
  Sunday = 'sunday',
  Thursday = 'thursday',
  Tuesday = 'tuesday',
  Wednesday = 'wednesday',
}

export enum NotificationEngineReminderFrequency {
  Bimonthly = 'bimonthly',
  Daily = 'daily',
  HoursInterval = 'hours_interval',
  Monthly = 'monthly',
  Quarterly = 'quarterly',
  Weekly = 'weekly',
}

export enum NotificationEngineReminderKind {
  Breakfast = 'breakfast',
  Dinner = 'dinner',
  Exercise = 'exercise',
  Fasting = 'fasting',
  GlucoseCheck = 'glucose_check',
  Journal = 'journal',
  Lunch = 'lunch',
  Meditation = 'meditation',
  OtherMeal = 'other_meal',
  Sleep = 'sleep',
  SubmitLabResults = 'submit_lab_results',
  Weight = 'weight',
}

export enum NotificationEngineScreen {
  Home = 'home',
  Insights = 'insights',
  None = 'none',
  NutritionistHub = 'nutritionist_hub',
}

export type NumericPair = {
  __typename?: 'NumericPair'
  x: Scalars['Float']['output']
  y?: Maybe<Scalars['Float']['output']>
}

export type NumericValue = {
  endDate: Scalars['ISO8601DateTime']['input']
  id?: InputMaybe<Scalars['String']['input']>
  metadata?: InputMaybe<Scalars['JSON']['input']>
  sourceId?: InputMaybe<Scalars['String']['input']>
  sourceName?: InputMaybe<Scalars['String']['input']>
  startDate: Scalars['ISO8601DateTime']['input']
  value?: InputMaybe<Scalars['Float']['input']>
}

export type NumericalSelect = {
  __typename?: 'NumericalSelect'
  text: Scalars['String']['output']
  value: Scalars['Float']['output']
}

export type NutritionCard = {
  __typename?: 'NutritionCard'
  nutrition: NutritionStatistics
  score: GlucoseDailyScore
  statistics: GlucoseStatistics
  updatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>
}

export type NutritionStatistics = {
  __typename?: 'NutritionStatistics'
  today: Array<KeyValuePairFloat>
}

export type Onboarding = {
  __typename?: 'Onboarding'
  tutorials: Array<Tutorials>
}

export type OneTimePaymentAddress = {
  city: Scalars['String']['input']
  country?: InputMaybe<AddressCountries>
  id?: InputMaybe<Scalars['ID']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  state?: InputMaybe<Scalars['String']['input']>
  street: Scalars['String']['input']
  street2?: InputMaybe<Scalars['String']['input']>
  zipCode: Scalars['String']['input']
}

export type Organization = {
  __typename?: 'Organization'
  id: Scalars['Int']['output']
  isNutrisense: Scalars['Boolean']['output']
  name: Scalars['String']['output']
}

export type Outcome = {
  __typename?: 'Outcome'
  id: Scalars['ID']['output']
  key: Scalars['String']['output']
  reason: Reason
}

export type Pagination = {
  endTime?: InputMaybe<Scalars['ISO8601DateTime']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  page?: InputMaybe<Scalars['Int']['input']>
  pageSize?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  startTime?: InputMaybe<Scalars['ISO8601DateTime']['input']>
  type?: InputMaybe<Scalars['String']['input']>
}

export type PaymentMethod = {
  __typename?: 'PaymentMethod'
  cardType: Scalars['String']['output']
  createdAt: Scalars['ISO8601DateTime']['output']
  expirationDate: Scalars['ISO8601Date']['output']
  last4: Scalars['String']['output']
  stripeId: Scalars['String']['output']
}

export enum Period {
  Day = 'day',
  Hour = 'hour',
  Month = 'month',
  Week = 'week',
}

export type Plan = {
  __typename?: 'Plan'
  amount: Scalars['Float']['output']
  id: Scalars['ID']['output']
  nickname: Scalars['String']['output']
  product: ProductMin
  stripeId: Scalars['String']['output']
}

export type Product = {
  __typename?: 'Product'
  addon: Scalars['Boolean']['output']
  billingDateChangeable: Scalars['Boolean']['output']
  commitmentMonths?: Maybe<Scalars['Int']['output']>
  core: Scalars['Boolean']['output']
  description?: Maybe<Scalars['String']['output']>
  details?: Maybe<Array<Scalars['String']['output']>>
  dietitian: Scalars['Boolean']['output']
  dietitianMenu: Scalars['Boolean']['output']
  /** @deprecated Field removed from product */
  dietitianTrialDays?: Maybe<Scalars['Int']['output']>
  eligibilityListItems: Array<Scalars['String']['output']>
  eligibleUpgradeProducts: Array<ProductMin>
  icon?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  includedOptions?: Maybe<Array<Scalars['String']['output']>>
  includesCgm: Scalars['Boolean']['output']
  incompatibleAddons: Array<ProductMin>
  insuranceCoverage: Scalars['Boolean']['output']
  interval: Interval
  key: Scalars['String']['output']
  order: Scalars['Int']['output']
  ownSensor: Scalars['Boolean']['output']
  /** @deprecated Use price instead */
  plan: ProductPlan
  price: Scalars['Float']['output']
  productGroup?: Maybe<ProductGroup>
  programsName?: Maybe<Scalars['String']['output']>
  recurring: Scalars['Boolean']['output']
  requiresShipping: Scalars['Boolean']['output']
  schedulingRule: SchedulingRule
  shortDescription?: Maybe<Scalars['String']['output']>
  title: Scalars['String']['output']
  trialDays?: Maybe<Scalars['Int']['output']>
  uncancelable: Scalars['Boolean']['output']
}

export type ProductCollection = {
  __typename?: 'ProductCollection'
  products: Array<Product>
}

export type ProductGroup = {
  __typename?: 'ProductGroup'
  description: Scalars['String']['output']
  details: Array<Scalars['String']['output']>
  id: Scalars['ID']['output']
  interval: Interval
  key: Scalars['String']['output']
  kind?: Maybe<BillingProductGroupKind>
  order: Scalars['Int']['output']
  shortDescription: Scalars['String']['output']
  title: Scalars['String']['output']
}

export type ProductMin = {
  __typename?: 'ProductMin'
  details?: Maybe<Array<Scalars['String']['output']>>
  eligibleUpgradeProducts: Array<ProductMin>
  id: Scalars['ID']['output']
  includesCgm: Scalars['Boolean']['output']
  incompatibleAddons: Array<ProductMin>
  interval: Interval
  key: Scalars['String']['output']
  ownSensor: Scalars['Boolean']['output']
  requiresShipping: Scalars['Boolean']['output']
  title: Scalars['String']['output']
  uncancelable: Scalars['Boolean']['output']
}

export type ProductPlan = {
  __typename?: 'ProductPlan'
  amount: Scalars['Int']['output']
  id: Scalars['ID']['output']
  stripeId: Scalars['String']['output']
}

export type Program = {
  __typename?: 'Program'
  contentId: Scalars['ID']['output']
  id: Scalars['ID']['output']
  position?: Maybe<Scalars['Int']['output']>
  progress?: Maybe<Progress>
}

export type ProgramCollection = {
  __typename?: 'ProgramCollection'
  programs: Array<ExpandableProgram>
}

export type Progress = {
  __typename?: 'Progress'
  complete: Scalars['Boolean']['output']
}

export type PromotionCode = {
  __typename?: 'PromotionCode'
  amountOff?: Maybe<Scalars['Float']['output']>
  applicable: Scalars['Boolean']['output']
  code: Scalars['String']['output']
  percentOff?: Maybe<Scalars['Float']['output']>
  providerId: Scalars['String']['output']
}

export type Provider = {
  __typename?: 'Provider'
  active: Scalars['Boolean']['output']
  availableTypes?: Maybe<Array<Scalars['String']['output']>>
  connectionId?: Maybe<Scalars['Int']['output']>
  disabledTypes?: Maybe<Array<Scalars['String']['output']>>
  errorReason?: Maybe<Scalars['String']['output']>
  name: Scalars['String']['output']
  provider: Scalars['String']['output']
  status?: Maybe<Scalars['String']['output']>
}

export type ProviderSetupIntent = {
  __typename?: 'ProviderSetupIntent'
  clientSecret: Scalars['String']['output']
}

export type PurchasableItem = {
  product: BillingProduct
  quantity: Scalars['Int']['input']
}

export type QueryType = {
  __typename?: 'QueryType'
  activeNotifications: NotificationCollection
  allActivities: ActivityCollection
  allAppointments: AppointmentCollection
  allCharts: Charts
  allCourses: ProgramCollection
  allCustomIngredients: IngredientCollection
  allEvents: EventsData
  allFiles: ListFiles
  allMeals: MealCollection
  allMeasurements: MeasurementCollection
  allNotes: NoteCollection
  allNutrition: NutritionCard
  allProducts: ProductCollection
  allStats: Stats
  allSubscriptionSchedules: ScheduleCollection
  allSurveyLinks: SurveyLinkCollection
  allTerraProviders: ListProviders
  allUsersAutocomplete: UserCollection
  availableDppCourseCohortSessions: Array<CohortSession>
  calendar: UserCalendar
  chartsData: ChartsData
  checklistItems: ChecklistItemCollection
  clientConfig: ClientConfig
  coachAssignments: CoachAssignmentCollection
  coachProfile: CoachProfile
  currentUser?: Maybe<User>
  dailyMeasurementsSourcePriority: DailyMeasurementsSourcePriority
  dataExport: Success
  dppCourseCohort?: Maybe<Cohort>
  dppEnrollableCourseCohorts?: Maybe<CohortCollection>
  dppHealthDataRecordsChecklist: DppHealthDataRecordsChecklist
  effectiveDailyMeasurement?: Maybe<EffectiveDailyMeasurement>
  ehrAppointmentRecurrence?: Maybe<EhrAppointmentRecurrence>
  ehrAppointments: EhrAppointmentCollection
  ehrAvailableDatesWithSlots: DatesWithSlots
  estimateTax: EstimatedTax
  generateTerraAuthUrl: GenerateAuthUrl
  generateTerraSdkToken: GenerateSdkToken
  getHealthieApiKey: GetHealthieApiKey
  healthMetrics: HealthMetricCollection
  insuranceCardSubmission?: Maybe<InsuranceCardSubmission>
  insurancePlans: ListPlansActionPayload
  insurancePolicies: InsurancePolicyCollection
  insurancePolicy?: Maybe<InsurancePolicy>
  insuranceSchedulingAvailableDates: AvailableDates
  insuranceSchedulingAvailableSlots: AvailableSlots
  insuranceSchedulingEarlierAvailableSlots: AvailableSlots
  invoice: Invoice
  latestCharge?: Maybe<Charge>
  latestUserAppointment?: Maybe<LatestUserAppointment>
  legalPolicyConsentStatuses: LegalPolicyConsentStatuses
  linkupConnection?: Maybe<LinkupConnection>
  listUserDailyActions: DailyActionCollection
  meal: Meal
  mealImageDescription: ImageDescription
  product: Product
  promotionCode: PromotionCode
  referralPartner?: Maybe<UserReferralPartnerDef>
  reminders: ReminderCollection
  resetPasswordRequest: Success
  schedulingAllAvailabilities: Array<Availability>
  schedulingAvailableDatesWithSlots: AvailableDatesWithSlots
  schedulingSuggestedAvailability?: Maybe<SuggestedAvailability>
  sendUserInvite: Success
  upcomingDppCourseCohortSession?: Maybe<CohortSession>
  validateGlucosePresenceTerraGoogleFit: Success
}

export type QueryTypeAllActivitiesArgs = {
  filter?: InputMaybe<ActivityFilter>
  pagination?: InputMaybe<Pagination>
}

export type QueryTypeAllAppointmentsArgs = {
  dynamicFilters?: InputMaybe<Array<AppointmentDynamicFilterItem>>
  page?: InputMaybe<Scalars['Int']['input']>
  perPage?: InputMaybe<Scalars['Int']['input']>
  sorts?: InputMaybe<Array<AppointmentSort>>
}

export type QueryTypeAllChartsArgs = {
  filter?: InputMaybe<DateFilter>
}

export type QueryTypeAllCustomIngredientsArgs = {
  filter?: InputMaybe<IngredientFilter>
  pagination?: InputMaybe<Pagination>
}

export type QueryTypeAllEventsArgs = {
  filter?: InputMaybe<DateFilter>
  pagination?: InputMaybe<Pagination>
}

export type QueryTypeAllFilesArgs = {
  dynamicFilters?: InputMaybe<Array<FileDynamicFilterItem>>
  page?: InputMaybe<Scalars['Int']['input']>
  perPage?: InputMaybe<Scalars['Int']['input']>
  sorts?: InputMaybe<Array<FileSort>>
}

export type QueryTypeAllMealsArgs = {
  filter?: InputMaybe<MealFilter>
  pagination?: InputMaybe<Pagination>
}

export type QueryTypeAllMeasurementsArgs = {
  filter?: InputMaybe<MeasurementFilter>
  pagination?: InputMaybe<Pagination>
}

export type QueryTypeAllNotesArgs = {
  dynamicFilters?: InputMaybe<Array<NoteDynamicFilterItem>>
  page?: InputMaybe<Scalars['Int']['input']>
  perPage?: InputMaybe<Scalars['Int']['input']>
  sorts?: InputMaybe<Array<NoteSort>>
}

export type QueryTypeAllNutritionArgs = {
  filter?: InputMaybe<DateFilter>
}

export type QueryTypeAllProductsArgs = {
  category?: InputMaybe<BillingProductCategory>
}

export type QueryTypeAllStatsArgs = {
  filter?: InputMaybe<DateFilter>
}

export type QueryTypeAllUsersAutocompleteArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>
  query: Scalars['String']['input']
}

export type QueryTypeCalendarArgs = {
  filter?: InputMaybe<DateFilter>
}

export type QueryTypeChartsDataArgs = {
  filter: ChartFilter
}

export type QueryTypeChecklistItemsArgs = {
  kind: UserChecklistItemsKind
}

export type QueryTypeCoachProfileArgs = {
  coachId: Scalars['ID']['input']
}

export type QueryTypeDailyMeasurementsSourcePriorityArgs = {
  type: HealthDataMeasurementType
}

export type QueryTypeEffectiveDailyMeasurementArgs = {
  date: Scalars['String']['input']
  type: HealthDataMeasurementType
}

export type QueryTypeEhrAppointmentsArgs = {
  category?: InputMaybe<EhrAppointmentCategory>
  filterKind?: InputMaybe<FilterAppointmentsKind>
  page?: InputMaybe<Scalars['Int']['input']>
  perPage?: InputMaybe<Scalars['Int']['input']>
  sorts?: InputMaybe<Array<EhrAppointmentSort>>
}

export type QueryTypeEhrAvailableDatesWithSlotsArgs = {
  appointmentKind?: InputMaybe<EhrAppointmentKind>
  getHealthieInstanceKind?: InputMaybe<EhrGetHealthieInstanceKind>
  providerKind?: InputMaybe<EhrAppointmentProviderKind>
  rescheduleAppointmentId?: InputMaybe<Scalars['ID']['input']>
  timeZone?: InputMaybe<Scalars['String']['input']>
}

export type QueryTypeEstimateTaxArgs = {
  product?: InputMaybe<BillingProduct>
  providerPromotionCodeId?: InputMaybe<Scalars['String']['input']>
}

export type QueryTypeGenerateTerraAuthUrlArgs = {
  authRedirectUrl?: InputMaybe<Scalars['String']['input']>
  provider: Scalars['String']['input']
}

export type QueryTypeGetHealthieApiKeyArgs = {
  getHealthieInstanceKind?: InputMaybe<EhrGetHealthieInstanceKind>
}

export type QueryTypeInsuranceCardSubmissionArgs = {
  insurancePolicyStateId: Scalars['ID']['input']
}

export type QueryTypeInsuranceSchedulingAvailableDatesArgs = {
  appointmentId?: InputMaybe<Scalars['ID']['input']>
  appointmentType?: InputMaybe<InsuranceBerryStreetAppointmentType>
  coachId?: InputMaybe<Scalars['ID']['input']>
  timeZone?: InputMaybe<Scalars['String']['input']>
  userState?: InputMaybe<AddressState>
}

export type QueryTypeInsuranceSchedulingAvailableSlotsArgs = {
  appointmentType?: InputMaybe<InsuranceBerryStreetAppointmentType>
  coachId?: InputMaybe<Scalars['ID']['input']>
  date?: InputMaybe<Scalars['String']['input']>
  timeZone?: InputMaybe<Scalars['String']['input']>
  userState?: InputMaybe<AddressState>
}

export type QueryTypeInsuranceSchedulingEarlierAvailableSlotsArgs = {
  appointmentId?: InputMaybe<Scalars['ID']['input']>
}

export type QueryTypeInvoiceArgs = {
  id: Scalars['ID']['input']
}

export type QueryTypeLatestChargeArgs = {
  providerSubscriptionId: Scalars['ID']['input']
}

export type QueryTypeListUserDailyActionsArgs = {
  endDate?: InputMaybe<Scalars['ISO8601Date']['input']>
  startDate?: InputMaybe<Scalars['ISO8601Date']['input']>
  status?: InputMaybe<UserDailyActionStatus>
}

export type QueryTypeMealArgs = {
  id: Scalars['ID']['input']
}

export type QueryTypeMealImageDescriptionArgs = {
  image: Scalars['Upload']['input']
}

export type QueryTypeProductArgs = {
  product: BillingProduct
}

export type QueryTypePromotionCodeArgs = {
  code?: InputMaybe<Scalars['String']['input']>
  product?: InputMaybe<BillingProduct>
}

export type QueryTypeReferralPartnerArgs = {
  partner: UserReferralPartner
}

export type QueryTypeResetPasswordRequestArgs = {
  email?: InputMaybe<Scalars['String']['input']>
}

export type QueryTypeSchedulingAvailableDatesWithSlotsArgs = {
  appointmentId?: InputMaybe<Scalars['ID']['input']>
  appointmentType?: InputMaybe<InsuranceBerryStreetAppointmentType>
  coachId?: InputMaybe<Scalars['ID']['input']>
  timeZone?: InputMaybe<Scalars['String']['input']>
  uniqueSlots?: InputMaybe<Scalars['Boolean']['input']>
  userState?: InputMaybe<AddressState>
}

export type QueryTypeSendUserInviteArgs = {
  email?: InputMaybe<Scalars['String']['input']>
}

export type Question = {
  __typename?: 'Question'
  imageUrl?: Maybe<Scalars['String']['output']>
  key: SurveysQuestionKey
  label?: Maybe<Scalars['String']['output']>
  note?: Maybe<Scalars['String']['output']>
  required: Scalars['Boolean']['output']
  response: Response
}

export type QuestionsGroup = {
  __typename?: 'QuestionsGroup'
  banner?: Maybe<Scalars['String']['output']>
  introScreen?: Maybe<IntroScreen>
  questions: Array<Question>
}

export type Range = {
  __typename?: 'Range'
  max: Scalars['Float']['output']
  min: Scalars['Float']['output']
}

export type RangePair = {
  __typename?: 'RangePair'
  x: Range
  y: Scalars['Float']['output']
}

export type RangeUnit = {
  __typename?: 'RangeUnit'
  from?: Maybe<Scalars['Float']['output']>
  rating: Scalars['String']['output']
  to?: Maybe<Scalars['Float']['output']>
  type: Scalars['String']['output']
}

export type Reason = {
  __typename?: 'Reason'
  headline: Scalars['String']['output']
  inlineAlert?: Maybe<InlineAlert>
  message: ReasonMessage
  notes: Array<Scalars['String']['output']>
}

export type ReasonMessage = {
  __typename?: 'ReasonMessage'
  content: Scalars['String']['output']
  links?: Maybe<Array<ReasonMessageLink>>
}

export type ReasonMessageLink = {
  __typename?: 'ReasonMessageLink'
  placeholder: Scalars['String']['output']
  url: Scalars['String']['output']
}

export type RecordedProgress = {
  __typename?: 'RecordedProgress'
  lesson: Lesson
  module: Module
  program: Program
}

export type Referral = {
  __typename?: 'Referral'
  credits: Scalars['Int']['output']
  description: Scalars['String']['output']
  discount: Scalars['Int']['output']
  message: Scalars['String']['output']
  title: Scalars['String']['output']
}

/** Autogenerated return type of RegisterNotificationTokenAction. */
export type RegisterNotificationTokenActionPayload = {
  __typename?: 'RegisterNotificationTokenActionPayload'
  success: Scalars['Boolean']['output']
}

export type Reminder = {
  __typename?: 'Reminder'
  dayOfWeek?: Maybe<NotificationEngineReminderDayOfWeek>
  frequency: NotificationEngineReminderFrequency
  hoursInterval?: Maybe<Scalars['Int']['output']>
  id: Scalars['ID']['output']
  kind: NotificationEngineReminderKind
  sendOn?: Maybe<Scalars['ISO8601Date']['output']>
  timeOfDay?: Maybe<Scalars['String']['output']>
}

export type ReminderCollection = {
  __typename?: 'ReminderCollection'
  reminders: Array<Reminder>
}

export type ReminderFrequency = {
  __typename?: 'ReminderFrequency'
  key: NotificationEngineReminderFrequency
}

export type ReminderKind = {
  __typename?: 'ReminderKind'
  allowedFrequencies: Array<ReminderFrequency>
  icon: Scalars['String']['output']
  key: NotificationEngineReminderKind
  title: Scalars['String']['output']
}

export type RemindersSettings = {
  __typename?: 'RemindersSettings'
  hoursInterval: HoursInterval
}

export type ResearchFaqs = {
  __typename?: 'ResearchFaqs'
  dataProtectionUrl: Scalars['String']['output']
  disableLibreScanUrl: Scalars['String']['output']
  eligibilityUrl: Scalars['String']['output']
  gettingStartedUrl: Scalars['String']['output']
  rootUrl: Scalars['String']['output']
  sensorComparisonUrl: Scalars['String']['output']
}

export type ResearchState = {
  __typename?: 'ResearchState'
  status: DexcomResearchStatus
}

export type Response = {
  __typename?: 'Response'
  answersGroups?: Maybe<Array<AnswersGroup>>
  kind: SurveysConfigObjectQuestionsGroupQuestionResponseKind
  kindMetadata?: Maybe<Scalars['JSON']['output']>
}

export type Scan = {
  __typename?: 'Scan'
  createdAt: Scalars['ISO8601DateTime']['output']
  id: Scalars['ID']['output']
  measurements: Array<Measurement>
  sensor?: Maybe<Sensor>
}

export type ScanData = {
  calibrationInfo?: InputMaybe<CalibrationInfo>
  history: Array<GlucoseData>
  isReady: Scalars['Boolean']['input']
  isValid: Scalars['Boolean']['input']
  maxLifeInMinutes?: InputMaybe<Scalars['Int']['input']>
  model: Scalars['String']['input']
  serialNumber: Scalars['String']['input']
  startTime: Scalars['Int']['input']
  status: Scalars['String']['input']
  timeSinceStart: Scalars['Int']['input']
  trend: Array<GlucoseData>
}

export type Schedule = {
  __typename?: 'Schedule'
  canceledAt?: Maybe<Scalars['ISO8601DateTime']['output']>
  completedAt?: Maybe<Scalars['ISO8601DateTime']['output']>
  createdAt: Scalars['ISO8601DateTime']['output']
  currentPhaseEndAt?: Maybe<Scalars['ISO8601DateTime']['output']>
  currentPhaseStartAt?: Maybe<Scalars['ISO8601DateTime']['output']>
  id: Scalars['ID']['output']
  phases: Array<SchedulePhase>
  releasedAt?: Maybe<Scalars['ISO8601DateTime']['output']>
  status: SubscriptionScheduleStatus
  stripeId: Scalars['String']['output']
  subscription?: Maybe<Subscription>
  updatedAt: Scalars['ISO8601DateTime']['output']
}

export type ScheduleCollection = {
  __typename?: 'ScheduleCollection'
  schedules: Array<Schedule>
}

export type SchedulePhase = {
  __typename?: 'SchedulePhase'
  billingCycleAnchorChange?: Maybe<SubscriptionSchedulePhaseBillingCycleAnchorChange>
  createdAt: Scalars['ISO8601DateTime']['output']
  description?: Maybe<Scalars['String']['output']>
  endAt: Scalars['ISO8601DateTime']['output']
  id: Scalars['ID']['output']
  price: Scalars['Float']['output']
  primaryProduct?: Maybe<ProductMin>
  startAt: Scalars['ISO8601DateTime']['output']
  updatedAt: Scalars['ISO8601DateTime']['output']
}

export type SchedulingRule = {
  __typename?: 'SchedulingRule'
  appointmentType?: Maybe<InsuranceBerryStreetAppointmentType>
  chargeType?: Maybe<InsuranceBerryStreetAppointmentChargeType>
  count?: Maybe<Scalars['Int']['output']>
  key: InsuranceBerryStreetAppointmentSchedulingRule
}

export type Score = {
  __typename?: 'Score'
  danger: Scalars['Int']['output']
  max: Scalars['Int']['output']
  warning: Scalars['Int']['output']
}

export type Sensor = {
  __typename?: 'Sensor'
  activationDate: Scalars['ISO8601Date']['output']
  activationTime: Scalars['ISO8601DateTime']['output']
  baselineAdjustment: Scalars['Int']['output']
  daysRemaining: Scalars['Int']['output']
  description: Scalars['String']['output']
  expirationDate: Scalars['ISO8601Date']['output']
  expirationTime: Scalars['ISO8601DateTime']['output']
  expired: Scalars['Boolean']['output']
  id: Scalars['ID']['output']
  lastStatusChange?: Maybe<SensorStatusChange>
  model: Scalars['String']['output']
  modelEnum?: Maybe<SensorModel>
  serialNumber: Scalars['String']['output']
  status?: Maybe<Scalars['String']['output']>
}

export type SensorMode = {
  __typename?: 'SensorMode'
  kind: UserExperienceSensorModeKind
}

export enum SensorModel {
  DexcomG6 = 'dexcom_g6',
  DexcomG7 = 'dexcom_g7',
  DexcomStelo = 'dexcom_stelo',
  Libre1 = 'libre1',
  Libre2 = 'libre2',
  Libre2Eu = 'libre2_eu',
  Libre3 = 'libre3',
  LibrePro = 'libre_pro',
  LibreUs = 'libre_us',
  Unknown = 'unknown',
}

export type SensorSettings = {
  __typename?: 'SensorSettings'
  baselineAdjustmentOptions: Array<NumericalSelect>
}

export enum SensorStatus {
  Active = 'active',
  Error = 'error',
  Expired = 'expired',
  Failure = 'failure',
  InsertionDetection = 'insertion_detection',
  InsertionFailed = 'insertion_failed',
  Paired = 'paired',
  Ready = 'ready',
  Shutdown = 'shutdown',
  Starting = 'starting',
  Unknown = 'unknown',
}

export type SensorStatusChange = {
  __typename?: 'SensorStatusChange'
  createdAt: Scalars['ISO8601Date']['output']
  failure: Scalars['Boolean']['output']
  status: SensorStatus
}

export type Settings = {
  __typename?: 'Settings'
  cancellationPolicy: Scalars['String']['output']
  consumerHealthDataPrivacyPolicy: Scalars['String']['output']
  privacyPolicy: Scalars['String']['output']
  telehealth: Scalars['String']['output']
  termsOfUse: Scalars['String']['output']
}

export type ShowAlertOnCloseBehavior = {
  __typename?: 'ShowAlertOnCloseBehavior'
  body: Scalars['String']['output']
  cancelTitle: Scalars['String']['output']
  confirmTitle: Scalars['String']['output']
  icon?: Maybe<UiIcon>
  title: Scalars['String']['output']
}

export type SignIn = {
  email: Scalars['String']['input']
  impersonate?: InputMaybe<Scalars['String']['input']>
  password?: InputMaybe<Scalars['String']['input']>
}

export enum SignUpState {
  Complete = 'complete',
  PendingPurchase = 'pending_purchase',
}

export type Signup = {
  __typename?: 'Signup'
  learnMore: Scalars['String']['output']
}

export type SingleValueMeasurement = {
  __typename?: 'SingleValueMeasurement'
  value: Scalars['Float']['output']
}

export type Slot = {
  __typename?: 'Slot'
  coachId: Scalars['ID']['output']
  time: Scalars['ISO8601DateTime']['output']
}

export enum SortDirection {
  Asc = 'asc',
  Desc = 'desc',
}

export type Stat = {
  __typename?: 'Stat'
  mean: Scalars['Float']['output']
}

export type StatMeta = {
  __typename?: 'StatMeta'
  key: Scalars['String']['output']
  section: Scalars['String']['output']
}

export type StatRange = {
  __typename?: 'StatRange'
  danger?: Maybe<Scalars['Float']['output']>
  max?: Maybe<Scalars['Float']['output']>
  min?: Maybe<Scalars['Float']['output']>
  reverse?: Maybe<Scalars['Boolean']['output']>
  warning?: Maybe<Scalars['Float']['output']>
}

export type State = {
  __typename?: 'State'
  abbreviation: Scalars['String']['output']
  name: Scalars['String']['output']
}

export type Statistic = {
  __typename?: 'Statistic'
  change: Scalars['Int']['output']
  description: Scalars['String']['output']
  meta: StatMeta
  range: StatRange
  title: Scalars['String']['output']
  type: Scalars['String']['output']
  units: Scalars['String']['output']
  value: Scalars['Float']['output']
  valuePrev: Scalars['Float']['output']
}

export type Statistics = {
  __typename?: 'Statistics'
  imperial: ConfigUnit
  metric: ConfigUnit
  name: Scalars['String']['output']
}

export type Stats = {
  __typename?: 'Stats'
  statistics: Array<Statistic>
}

export type StatsConfig = {
  __typename?: 'StatsConfig'
  filters: StatsFilters
}

export type StatsFilters = {
  __typename?: 'StatsFilters'
  types: Array<KeyValuePairStringArray>
}

export type StatsSettings = {
  __typename?: 'StatsSettings'
  key: Scalars['String']['output']
  order: Scalars['Int']['output']
  section: Scalars['String']['output']
  visible: Scalars['Boolean']['output']
}

export type StatsSettingsOrder = {
  key: Scalars['String']['input']
  order: Scalars['Int']['input']
  section: Scalars['String']['input']
  visible: Scalars['Boolean']['input']
}

export type StringPair = {
  __typename?: 'StringPair'
  /** @deprecated Removed in v3.0.0 */
  name?: Maybe<Scalars['String']['output']>
  x: Scalars['String']['output']
  y?: Maybe<Scalars['Float']['output']>
}

export type StringSelect = {
  __typename?: 'StringSelect'
  text: Scalars['String']['output']
  value: Scalars['String']['output']
}

export type StringValue = {
  endDate: Scalars['ISO8601DateTime']['input']
  id?: InputMaybe<Scalars['String']['input']>
  metadata?: InputMaybe<Scalars['JSON']['input']>
  sourceId?: InputMaybe<Scalars['String']['input']>
  sourceName?: InputMaybe<Scalars['String']['input']>
  startDate: Scalars['ISO8601DateTime']['input']
  value: Scalars['String']['input']
}

export type Subscription = {
  __typename?: 'Subscription'
  allowedPauseCycles: Scalars['Int']['output']
  attachedSchedule?: Maybe<Schedule>
  cancelAt?: Maybe<Scalars['ISO8601DateTime']['output']>
  canceledAt?: Maybe<Scalars['ISO8601DateTime']['output']>
  checkoutOptions: Array<CheckoutOption>
  commitmentEndAt?: Maybe<Scalars['ISO8601DateTime']['output']>
  createdAt: Scalars['ISO8601DateTime']['output']
  currentPeriodEndAt?: Maybe<Scalars['ISO8601DateTime']['output']>
  dietitianAddOn: Scalars['Boolean']['output']
  endAt?: Maybe<Scalars['ISO8601DateTime']['output']>
  id: Scalars['ID']['output']
  latestInvoice?: Maybe<Invoice>
  paidMonthsCount: Scalars['Int']['output']
  paused: Scalars['Boolean']['output']
  pausedMonthsCount: Scalars['Int']['output']
  /** @deprecated Removed usage in v2.7.0 */
  plans: Array<Plan>
  price: Scalars['Float']['output']
  /** @deprecated Use price and primary_product */
  primaryPlan: Plan
  primaryProduct: ProductMin
  resetBillingCycleAnchorAt?: Maybe<Scalars['ISO8601DateTime']['output']>
  resumesAt?: Maybe<Scalars['ISO8601DateTime']['output']>
  startAt: Scalars['ISO8601DateTime']['output']
  status: SubscriptionStatus
  stripeId: Scalars['String']['output']
  /** @deprecated Use status enum field */
  stripeStatus: Scalars['String']['output']
  subscribedAt: Scalars['ISO8601DateTime']['output']
  trialEndAt?: Maybe<Scalars['ISO8601DateTime']['output']>
  updatedAt: Scalars['ISO8601DateTime']['output']
  user: User
}

export type SubscriptionAddress = {
  city: Scalars['String']['input']
  country?: InputMaybe<AddressCountries>
  id?: InputMaybe<Scalars['ID']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  state?: InputMaybe<Scalars['String']['input']>
  street: Scalars['String']['input']
  street2?: InputMaybe<Scalars['String']['input']>
  zipCode: Scalars['String']['input']
}

export enum SubscriptionBonusKind {
  ComplimentarySupportFullSub_1mo = 'complimentary_support_full_sub_1mo',
  ComplimentarySupportFullSub_3mo = 'complimentary_support_full_sub_3mo',
  ComplimentarySupportFullSub_6mo = 'complimentary_support_full_sub_6mo',
  ComplimentarySupportFullSub_12mo = 'complimentary_support_full_sub_12mo',
  Free_2MonthDietitianFor_6And_12MonthsCommitment = 'free_2_month_dietitian_for_6_and_12_months_commitment',
  Free_2MonthsDietitianFor_3_6_12MonthsCommitment = 'free_2_months_dietitian_for_3_6_12_months_commitment',
  Free_3MonthsDietitianFor_3MonthsCommitment = 'free_3_months_dietitian_for_3_months_commitment',
  FreeDietitianFor_4thMonthOnRenewal = 'free_dietitian_for_4th_month_on_renewal',
  FreeDietitianFor_6MonthsCommitment = 'free_dietitian_for_6_months_commitment',
  FreeDietitianFor_7thMonthOnRenewal = 'free_dietitian_for_7th_month_on_renewal',
  FreeDietitianFor_12MonthsCommitment = 'free_dietitian_for_12_months_commitment',
  FreeDietitianFor_13thMonthOnRenewal = 'free_dietitian_for_13th_month_on_renewal',
  MedicalConsultAddon = 'medical_consult_addon',
  PaidDietitianSupport = 'paid_dietitian_support',
}

export enum SubscriptionCheckoutOptionKind {
  Prescription = 'prescription',
  Research = 'research',
}

export enum SubscriptionSchedulePhaseBillingCycleAnchorChange {
  Automatic = 'automatic',
  PhaseStart = 'phase_start',
}

export enum SubscriptionScheduleStatus {
  Active = 'active',
  Canceled = 'canceled',
  Completed = 'completed',
  NotStarted = 'not_started',
  Released = 'released',
}

export enum SubscriptionStatus {
  Active = 'active',
  Canceled = 'canceled',
  Incomplete = 'incomplete',
  IncompleteExpired = 'incomplete_expired',
  PastDue = 'past_due',
  Trialing = 'trialing',
  Unpaid = 'unpaid',
}

export type Success = {
  __typename?: 'Success'
  success: Scalars['Boolean']['output']
}

export type SuggestedAvailability = {
  __typename?: 'SuggestedAvailability'
  appointmentType: InsuranceBerryStreetAppointmentType
  meetingAt: Scalars['ISO8601DateTime']['output']
  nutritionistId: Scalars['ID']['output']
  nutritionistName?: Maybe<Scalars['String']['output']>
  title?: Maybe<Scalars['String']['output']>
}

export type Survey = {
  __typename?: 'Survey'
  /** @deprecated use survey_link.early_exit_deep_link instead */
  allowEarlyExitBehavior?: Maybe<AllowEarlyExitBehavior>
  behavior: Behavior
  completedScreen?: Maybe<CompletedScreen>
  completionFlashMessage?: Maybe<Scalars['String']['output']>
  followups: Array<Followup>
  header?: Maybe<Scalars['String']['output']>
  kind: SurveysConfigKind
  questionsGroups: Array<QuestionsGroup>
  research: Scalars['Boolean']['output']
  showAlertOnCloseBehavior?: Maybe<ShowAlertOnCloseBehavior>
  version: Scalars['String']['output']
}

export type SurveyLink = {
  __typename?: 'SurveyLink'
  completionDeepLink?: Maybe<Scalars['String']['output']>
  earlyExitDeepLink?: Maybe<Scalars['String']['output']>
  finished: Scalars['Boolean']['output']
  finishedAt?: Maybe<Scalars['ISO8601DateTime']['output']>
  id: Scalars['ID']['output']
  inProgress: Scalars['Boolean']['output']
  outcome?: Maybe<Outcome>
  responses: Array<SurveyResponse>
  startedAt: Scalars['ISO8601DateTime']['output']
  state: SurveysUserLinkState
  survey: Survey
  user?: Maybe<User>
}

export type SurveyLinkCollection = {
  __typename?: 'SurveyLinkCollection'
  surveyLinks: Array<SurveyLink>
}

export type SurveyResponse = {
  __typename?: 'SurveyResponse'
  answer: Scalars['JSON']['output']
  id: Scalars['ID']['output']
  questionKey: SurveysQuestionKey
  surveyLink: SurveyLink
}

export enum SurveysConfigKind {
  AccountInterest = 'account_interest',
  Cancellation = 'cancellation',
  CareEligibility = 'care_eligibility',
  CareIntake = 'care_intake',
  CareRenewal = 'care_renewal',
  CgmPrerequisite = 'cgm_prerequisite',
  Demographics = 'demographics',
  DexcomResearchConsent = 'dexcom_research_consent',
  DietaryHabits = 'dietary_habits',
  DppEligibility = 'dpp_eligibility',
  DppEnrollment = 'dpp_enrollment',
  DppEvidence = 'dpp_evidence',
  DppScheduling = 'dpp_scheduling',
  ExerciseHabits = 'exercise_habits',
  Goals = 'goals',
  GovernmentId = 'government_id',
  Health = 'health',
  HealthUpdate = 'health_update',
  Insurance = 'insurance',
  InsuranceEarlyExit = 'insurance_early_exit',
  InsuranceFallback = 'insurance_fallback',
  MedicalDisclaimer = 'medical_disclaimer',
  MedicalHistory = 'medical_history',
  MidpointPulse = 'midpoint_pulse',
  OneMonthActiveFeedback = 'one_month_active_feedback',
  OwnSensor = 'own_sensor',
  SleepHabits = 'sleep_habits',
  VideoCallFeedback = 'video_call_feedback',
}

export enum SurveysConfigObjectQuestionsGroupQuestionResponseAnswersGroupAnswerFollowupKind {
  Inline = 'inline',
  Standalone = 'standalone',
}

export enum SurveysConfigObjectQuestionsGroupQuestionResponseAnswersGroupAnswersOrder {
  Random = 'random',
  Serial = 'serial',
}

export enum SurveysConfigObjectQuestionsGroupQuestionResponseKind {
  Address = 'address',
  CheckboxMulti = 'checkbox_multi',
  Consent = 'consent',
  DateInput = 'date_input',
  FileUpload = 'file_upload',
  HeightImperial = 'height_imperial',
  PhoneNumber = 'phone_number',
  Radio = 'radio',
  SelectSingle = 'select_single',
  Slider = 'slider',
  SliderWithSkip = 'slider_with_skip',
  TabsTextInput = 'tabs_text_input',
  TextArea = 'text_area',
  TextAreaWithSkip = 'text_area_with_skip',
  TextInput = 'text_input',
}

export enum SurveysQuestionKey {
  Address = 'address',
  AdhesiveAllergy = 'adhesive_allergy',
  AetnaInsurancePlanType = 'aetna_insurance_plan_type',
  AgeRange = 'age_range',
  AlcoholicBeveragesFrequency = 'alcoholic_beverages_frequency',
  AnnualIncomeRange = 'annual_income_range',
  AnthemInsurancePlanType = 'anthem_insurance_plan_type',
  AnythingElse = 'anything_else',
  AppetiteChange = 'appetite_change',
  AppetiteLevel = 'appetite_level',
  AvoidingDiabetesPriority = 'avoiding_diabetes_priority',
  BedtimeRoutine = 'bedtime_routine',
  BedtimeRoutineOther = 'bedtime_routine_other',
  BetterStatementAboutOwnHealth = 'better_statement_about_own_health',
  BiologicalSex = 'biological_sex',
  BirthDate = 'birth_date',
  BloodPressureRisk = 'blood_pressure_risk',
  BloodTestRisk = 'blood_test_risk',
  BlueCrossBlueShieldInsurancePlanType = 'blue_cross_blue_shield_insurance_plan_type',
  Bmi = 'bmi',
  BmiRange = 'bmi_range',
  BodyFat = 'body_fat',
  BodyPhoto = 'body_photo',
  BreakfastFrequency = 'breakfast_frequency',
  CancellationReason = 'cancellation_reason',
  CancellationReasonOther = 'cancellation_reason_other',
  CancerTreatment = 'cancer_treatment',
  CgmDevice = 'cgm_device',
  CgmDeviceOther = 'cgm_device_other',
  CignaInsurancePlanType = 'cigna_insurance_plan_type',
  CoachBetterSupport = 'coach_better_support',
  CoachFocus = 'coach_focus',
  CoachValueSoFar = 'coach_value_so_far',
  CoachingStyle = 'coaching_style',
  CommunicationFrequency = 'communication_frequency',
  ComplimentarySupportDecision = 'complimentary_support_decision',
  CompoundedSemaglutideConsent = 'compounded_semaglutide_consent',
  ConfidentInterpretGlucoseData = 'confident_interpret_glucose_data',
  ConfirmReject = 'confirm_reject',
  CurrentHba1cLevel = 'current_hba1c_level',
  DairyProductsFrequency = 'dairy_products_frequency',
  DefineProgress = 'define_progress',
  DescribesYou = 'describes_you',
  DescribesYouOther = 'describes_you_other',
  DexcomAppCompatibility = 'dexcom_app_compatibility',
  DexcomResearchConsent = 'dexcom_research_consent',
  Diabetes = 'diabetes',
  DiabetesDiagnosis = 'diabetes_diagnosis',
  DiabetesMedication = 'diabetes_medication',
  DiagnosedConditions = 'diagnosed_conditions',
  DiagnosedConditions2 = 'diagnosed_conditions2',
  DiagnosedConditionsOther = 'diagnosed_conditions_other',
  Diet = 'diet',
  DietOther = 'diet_other',
  DietaryGuidelinesRestrictions = 'dietary_guidelines_restrictions',
  DietaryGuidelinesRestrictionsOther = 'dietary_guidelines_restrictions_other',
  DietaryHabitsChanges = 'dietary_habits_changes',
  DietaryHabitsChangesDetails = 'dietary_habits_changes_details',
  DietaryHabitsChangesDetailsOther = 'dietary_habits_changes_details_other',
  DinnerFrequency = 'dinner_frequency',
  Disabilities = 'disabilities',
  DiscoverNs = 'discover_ns',
  DiscoverNsBlog = 'discover_ns_blog',
  DiscoverNsDoctor = 'discover_ns_doctor',
  DiscoverNsFacebook = 'discover_ns_facebook',
  DiscoverNsFamily = 'discover_ns_family',
  DiscoverNsFriend = 'discover_ns_friend',
  DiscoverNsInfluencer = 'discover_ns_influencer',
  DiscoverNsInstagram = 'discover_ns_instagram',
  DiscoverNsLinkedin = 'discover_ns_linkedin',
  DiscoverNsMagazine = 'discover_ns_magazine',
  DiscoverNsMedia = 'discover_ns_media',
  DiscoverNsNewsletter = 'discover_ns_newsletter',
  DiscoverNsOther = 'discover_ns_other',
  DiscoverNsPinterest = 'discover_ns_pinterest',
  DiscoverNsPodcast = 'discover_ns_podcast',
  DiscoverNsRadio = 'discover_ns_radio',
  DiscoverNsReddit = 'discover_ns_reddit',
  DiscoverNsSearchEngine = 'discover_ns_search_engine',
  DiscoverNsTikTok = 'discover_ns_tik_tok',
  DiscoverNsTwitter = 'discover_ns_twitter',
  DiscoverNsYoutube = 'discover_ns_youtube',
  DppCommercialInsurancePlan = 'dpp_commercial_insurance_plan',
  DppCommercialInsurancePlanOther = 'dpp_commercial_insurance_plan_other',
  DppHbA1cEvidence = 'dpp_hb_a1c_evidence',
  DppHealthcareProfessionalRecommendation = 'dpp_healthcare_professional_recommendation',
  DppInsurancePlan = 'dpp_insurance_plan',
  DppPreferredCallDays = 'dpp_preferred_call_days',
  DppPreferredCallTimes = 'dpp_preferred_call_times',
  DppProgramMotivation = 'dpp_program_motivation',
  EarliestWakeUpTime = 'earliest_wake_up_time',
  EatOutFrequency = 'eat_out_frequency',
  EatingDisorder = 'eating_disorder',
  EatingDisorderType = 'eating_disorder_type',
  EducationLevel = 'education_level',
  EducationLevelOther = 'education_level_other',
  ElectronicDevicesBeforeBedtime = 'electronic_devices_before_bedtime',
  EmploymentStatus = 'employment_status',
  EmploymentStatusOther = 'employment_status_other',
  EnergyLevelsChanges = 'energy_levels_changes',
  Ethnicity = 'ethnicity',
  EthnicityOther = 'ethnicity_other',
  ExerciseDuration = 'exercise_duration',
  ExerciseHabitsChanges = 'exercise_habits_changes',
  ExerciseHabitsChangesDetails = 'exercise_habits_changes_details',
  ExerciseHabitsChangesDetailsOther = 'exercise_habits_changes_details_other',
  ExerciseLocation = 'exercise_location',
  ExerciseLocationOther = 'exercise_location_other',
  ExerciseMotivation = 'exercise_motivation',
  ExerciseMotivationOther = 'exercise_motivation_other',
  ExerciseTypes = 'exercise_types',
  ExerciseTypesOther = 'exercise_types_other',
  FallAsleepTime = 'fall_asleep_time',
  FamiliarPrediabeticsConfirmation = 'familiar_prediabetics_confirmation',
  FamilyDiabetes = 'family_diabetes',
  FamilyMedicalConditions = 'family_medical_conditions',
  FastingGlucoseToleranceBloodTest = 'fasting_glucose_tolerance_blood_test',
  FishSeafoodFrequency = 'fish_seafood_frequency',
  FoodAllergies = 'food_allergies',
  FoodAllergiesIntolerances = 'food_allergies_intolerances',
  FoodAllergiesIntolerancesOther = 'food_allergies_intolerances_other',
  FoodAllergiesOther = 'food_allergies_other',
  FoodCravings = 'food_cravings',
  FoodCravingsChange = 'food_cravings_change',
  FoodCravingsDescription = 'food_cravings_description',
  FoodSymptoms = 'food_symptoms',
  FoodSymptomsOther = 'food_symptoms_other',
  FruitsVegetablesFrequency = 'fruits_vegetables_frequency',
  Gender = 'gender',
  GestationalDiabetesDiagnosis = 'gestational_diabetes_diagnosis',
  GlpAdditionalHealthConditions = 'glp_additional_health_conditions',
  GlpCommonSideEffects = 'glp_common_side_effects',
  GlpConditions = 'glp_conditions',
  GlpConsiderPregnant = 'glp_consider_pregnant',
  GlpCurrentDose = 'glp_current_dose',
  GlpDosePreference = 'glp_dose_preference',
  GlpDoseScheduleAdherence = 'glp_dose_schedule_adherence',
  GlpDoseScheduleAdherenceDescription = 'glp_dose_schedule_adherence_description',
  GlpElevatedTriglycerides = 'glp_elevated_triglycerides',
  GlpElevatedTriglyceridesValue = 'glp_elevated_triglycerides_value',
  GlpGallbladderDisease = 'glp_gallbladder_disease',
  GlpGallbladderRemoved = 'glp_gallbladder_removed',
  GlpGastricBypassSurgery = 'glp_gastric_bypass_surgery',
  GlpGastricBypassSurgeryDate = 'glp_gastric_bypass_surgery_date',
  GlpGastricBypassSurgeryType = 'glp_gastric_bypass_surgery_type',
  GlpGoal = 'glp_goal',
  GlpGoalOther = 'glp_goal_other',
  GlpHeaviestWeightInPounds = 'glp_heaviest_weight_in_pounds',
  GlpHistoryBipolarDepression = 'glp_history_bipolar_depression',
  GlpIncreasePhysicalActivity = 'glp_increase_physical_activity',
  GlpIndianAsianDescent = 'glp_indian_asian_descent',
  GlpKidneyDisease = 'glp_kidney_disease',
  GlpKidneyDiseaseGfr = 'glp_kidney_disease_gfr',
  GlpLastInPersonMedicalEvaluationDate = 'glp_last_in_person_medical_evaluation_date',
  GlpMedicationChallenges = 'glp_medication_challenges',
  GlpMedicationChallengesDescription = 'glp_medication_challenges_description',
  GlpMedicationQualityOfLife = 'glp_medication_quality_of_life',
  GlpOtherDose = 'glp_other_dose',
  GlpOtherSideEffects = 'glp_other_side_effects',
  GlpPhysicianQuestions = 'glp_physician_questions',
  GlpPrescriptionMedications = 'glp_prescription_medications',
  GlpPrescriptionMedicationsList = 'glp_prescription_medications_list',
  GlpProgressRate = 'glp_progress_rate',
  GlpProviderQuestions = 'glp_provider_questions',
  GlpPsychiatricFacility = 'glp_psychiatric_facility',
  GlpReduceCaloricIntake = 'glp_reduce_caloric_intake',
  GlpRestrictedConditions = 'glp_restricted_conditions',
  GlpSevereSideEffects = 'glp_severe_side_effects',
  GlpTakingWeightMedication = 'glp_taking_weight_medication',
  GlpTriedWeightManagement = 'glp_tried_weight_management',
  GlpTriedWeightManagementOther = 'glp_tried_weight_management_other',
  GlpWeightGoalInPounds = 'glp_weight_goal_in_pounds',
  GlucoseManagementKnowledge = 'glucose_management_knowledge',
  GoalsPrevention = 'goals_prevention',
  GoalsPreventionOther = 'goals_prevention_other',
  GovernmentIdCard = 'government_id_card',
  GroupCoachingParticipation = 'group_coaching_participation',
  HavingOwnHealthUpsAndDownsConfirmation = 'having_own_health_ups_and_downs_confirmation',
  Hba1cBloodTest = 'hba1c_blood_test',
  Hba1cBloodTestRange = 'hba1c_blood_test_range',
  Hba1cBloodTestResult = 'hba1c_blood_test_result',
  Hba1cMeasurementTime = 'hba1c_measurement_time',
  HealthConditions = 'health_conditions',
  HealthConditionsImpactExercise = 'health_conditions_impact_exercise',
  HealthConditionsOther = 'health_conditions_other',
  HealthGoal = 'health_goal',
  HealthInsuranceCard = 'health_insurance_card',
  Height = 'height',
  HeightInInches = 'height_in_inches',
  HighmarkInsurancePlanType = 'highmark_insurance_plan_type',
  HipCircumferenceInInches = 'hip_circumference_in_inches',
  HorizonInsurancePlanType = 'horizon_insurance_plan_type',
  InjuriesImpactExercise = 'injuries_impact_exercise',
  Insulin = 'insulin',
  InsulinOrCancerTreatment = 'insulin_or_cancer_treatment',
  InsuranceEarlyExitReason = 'insurance_early_exit_reason',
  InsuranceEarlyExitReasonOther = 'insurance_early_exit_reason_other',
  InsuranceGroupNumber = 'insurance_group_number',
  InsuranceMemberId = 'insurance_member_id',
  InsurancePlan = 'insurance_plan',
  InsurancePlanOther = 'insurance_plan_other',
  InsurancePolicyHolder = 'insurance_policy_holder',
  InsurancePolicyHolderDateOfBirth = 'insurance_policy_holder_date_of_birth',
  InsurancePolicyHolderFirstName = 'insurance_policy_holder_first_name',
  InsurancePolicyHolderLastName = 'insurance_policy_holder_last_name',
  InsurancePolicyHolderOther = 'insurance_policy_holder_other',
  InsuranceReimbursementPossibility = 'insurance_reimbursement_possibility',
  InsuranceVideoCallGoal = 'insurance_video_call_goal',
  InsuranceVideoCallGoalOther = 'insurance_video_call_goal_other',
  LabWork = 'lab_work',
  LabWorkResults = 'lab_work_results',
  LearnSpecificFoodsAffectDiabetes = 'learn_specific_foods_affect_diabetes',
  LifestyleChanges = 'lifestyle_changes',
  LifestyleConfidence = 'lifestyle_confidence',
  LikelihoodFollowWorkoutPlan = 'likelihood_follow_workout_plan',
  LikelihoodToChangeSleepHabits = 'likelihood_to_change_sleep_habits',
  LongTermWeightGoalEffort = 'long_term_weight_goal_effort',
  LunchFrequency = 'lunch_frequency',
  MedicalDisclaimerConsent = 'medical_disclaimer_consent',
  MedicalHistoryChanges = 'medical_history_changes',
  MedicalHistoryChangesDescription = 'medical_history_changes_description',
  MedicationChanges = 'medication_changes',
  Medications = 'medications',
  MetabolismDisorder = 'metabolism_disorder',
  MobileAppMedicalDisclaimer = 'mobile_app_medical_disclaimer',
  MorningRestfulness = 'morning_restfulness',
  NfcSupported = 'nfc_supported',
  NotUnderstandingOwnHealthConfirmation = 'not_understanding_own_health_confirmation',
  NsValueInPastTwoWeeks = 'ns_value_in_past_two_weeks',
  NutritionKnowledge = 'nutrition_knowledge',
  NutritionistContinueReachGoals = 'nutritionist_continue_reach_goals',
  NutritionistStartStopReachGoals = 'nutritionist_start_stop_reach_goals',
  Occupation = 'occupation',
  OneMonthActiveFeedbackDietitian = 'one_month_active_feedback_dietitian',
  OtherAllergies = 'other_allergies',
  OtherAllergiesList = 'other_allergies_list',
  OtherInsurancePlanType = 'other_insurance_plan_type',
  OwnGlucometer = 'own_glucometer',
  PainOrDiscomfortDuringSleep = 'pain_or_discomfort_during_sleep',
  PastHealthConditions = 'past_health_conditions',
  PharmacyServicesConsent = 'pharmacy_services_consent',
  PhoneNumber = 'phone_number',
  PhysicalActivity = 'physical_activity',
  PhysicalActivityFrequency = 'physical_activity_frequency',
  PoultryFrequency = 'poultry_frequency',
  PreExistingMedicalConditions = 'pre_existing_medical_conditions',
  PreExistingMedicalConditionsList = 'pre_existing_medical_conditions_list',
  Pregnancy = 'pregnancy',
  PregnancyTerms = 'pregnancy_terms',
  PrescriptionMedications = 'prescription_medications',
  PrescriptionMedicationsList = 'prescription_medications_list',
  PrimaryJoiningGoal = 'primary_joining_goal',
  PrimaryJoiningGoalOther = 'primary_joining_goal_other',
  ProcessedFoodFrequency = 'processed_food_frequency',
  RecurringSubscriptionConsent = 'recurring_subscription_consent',
  RedMeatFrequency = 'red_meat_frequency',
  ReversedConditions = 'reversed_conditions',
  ReversedConditionsOther = 'reversed_conditions_other',
  SanaBenefitsInsurancePlanType = 'sana_benefits_insurance_plan_type',
  Sex = 'sex',
  ShareWithCoach = 'share_with_coach',
  SittingHours = 'sitting_hours',
  SleepChanges = 'sleep_changes',
  SleepChangesDetails = 'sleep_changes_details',
  SleepChangesDetailsOther = 'sleep_changes_details_other',
  SleepDisorderDiagnosis = 'sleep_disorder_diagnosis',
  SleepHours = 'sleep_hours',
  SnackFrequency = 'snack_frequency',
  StepsPerDay = 'steps_per_day',
  Stress = 'stress',
  SubstancesBeforeBedtime = 'substances_before_bedtime',
  SuccessLookLike = 'success_look_like',
  SugaryDrinksFrequency = 'sugary_drinks_frequency',
  Supplements = 'supplements',
  SurgeriesHospitalizations = 'surgeries_hospitalizations',
  TelehealthConsent = 'telehealth_consent',
  Type_2DiabetesComplications = 'type_2_diabetes_complications',
  Type_2DiabetesDate = 'type_2_diabetes_date',
  Type_2DiabetesManagement = 'type_2_diabetes_management',
  UnfamiliarPrediabeticsConfirmation = 'unfamiliar_prediabetics_confirmation',
  UnitedHealthcareInsurancePlanType = 'united_healthcare_insurance_plan_type',
  VideoCallFeedbackConfidence = 'video_call_feedback_confidence',
  VideoCallFeedbackContinuation = 'video_call_feedback_continuation',
  VideoCallFeedbackImprovements = 'video_call_feedback_improvements',
  VideoCallFeedbackMostValuable = 'video_call_feedback_most_valuable',
  VideoCallFeedbackUsefulness = 'video_call_feedback_usefulness',
  VideoCallFeedbackWhy = 'video_call_feedback_why',
  WaistCircumferenceInInches = 'waist_circumference_in_inches',
  WakeUpFrequency = 'wake_up_frequency',
  Weight = 'weight',
  WeightChange = 'weight_change',
  WeightGainedInPounds = 'weight_gained_in_pounds',
  WeightGoalEffortWorksForYou = 'weight_goal_effort_works_for_you',
  WeightInPounds = 'weight_in_pounds',
  WeightLostInPounds = 'weight_lost_in_pounds',
  WorkedWithTrainer = 'worked_with_trainer',
}

export enum SurveysUserLinkState {
  Completed = 'completed',
  Processed = 'processed',
  Restarted = 'restarted',
  Started = 'started',
  Stopped = 'stopped',
}

export type ThirdPartySignIn = {
  provider: ThirdPartySignInProvider
  providerToken: Scalars['String']['input']
  providerUserId?: InputMaybe<Scalars['String']['input']>
}

export enum ThirdPartySignInProvider {
  Apple = 'apple',
  Google = 'google',
}

export enum TicketKind {
  Subscription = 'subscription',
  Troubleshooting = 'troubleshooting',
}

export enum TicketReason {
  Accounts = 'accounts',
  Addons = 'addons',
  AppLogin = 'app_login',
  AppMealLogging = 'app_meal_logging',
  AppQuestionnaires = 'app_questionnaires',
  Billing = 'billing',
  Cancellation = 'cancellation',
  GeneralInquiries = 'general_inquiries',
  GeneralNutritionSupport = 'general_nutrition_support',
  InformationUpdate = 'information_update',
  Insurance = 'insurance',
  IntegrationHealth = 'integration_health',
  IntegrationsDexcom = 'integrations_dexcom',
  IntegrationsOther = 'integrations_other',
  Patches = 'patches',
  Pauses = 'pauses',
  Payments = 'payments',
  SensorActivation = 'sensor_activation',
  SensorCalibration = 'sensor_calibration',
  SensorFellOff = 'sensor_fell_off',
  SensorOther = 'sensor_other',
  SensorPainSkinIrritation = 'sensor_pain_skin_irritation',
  SensorScanning = 'sensor_scanning',
  Shipments = 'shipments',
  SubscriptionUpdate = 'subscription_update',
  VideoCalls = 'video_calls',
}

export type TicketReasonDef = {
  __typename?: 'TicketReasonDef'
  key: TicketReason
  kind: TicketKind
  title: Scalars['String']['output']
}

export type Tickets = {
  __typename?: 'Tickets'
  reasonDefs: Array<TicketReasonDef>
}

export type TimePair = {
  __typename?: 'TimePair'
  interpolated: Scalars['Boolean']['output']
  x: Scalars['ISO8601DateTime']['output']
  y: Scalars['Float']['output']
}

export type Token = {
  __typename?: 'Token'
  value: Scalars['String']['output']
}

export enum TrackingMode {
  Cgm = 'cgm',
  Logging = 'logging',
}

export type TutorialStep = {
  __typename?: 'TutorialStep'
  description?: Maybe<Scalars['String']['output']>
  moreUrl?: Maybe<Scalars['String']['output']>
  photoUrlDark?: Maybe<Scalars['String']['output']>
  photoUrlLight?: Maybe<Scalars['String']['output']>
  title: Scalars['String']['output']
  videoUrl?: Maybe<Scalars['String']['output']>
}

export type Tutorials = {
  __typename?: 'Tutorials'
  key: Scalars['String']['output']
  value: Array<TutorialStep>
}

export enum UiIcon {
  Avocado = 'avocado',
  Bed = 'bed',
  Bluetooth = 'bluetooth',
  BookmarkSimple = 'bookmark_simple',
  Carrot = 'carrot',
  ChartLine = 'chart_line',
  Coaching = 'coaching',
  Confetti = 'confetti',
  Done = 'done',
  Drop = 'drop',
  Fish = 'fish',
  Food = 'food',
  Footprints = 'footprints',
  ForkKnife = 'fork_knife',
  Gift = 'gift',
  Glucose = 'glucose',
  Goals = 'goals',
  Hamburger = 'hamburger',
  HandHeart = 'hand_heart',
  Health = 'health',
  Lifecycle = 'lifecycle',
  MoonStars = 'moon_stars',
  Percent = 'percent',
  PersonSimpleBike = 'person_simple_bike',
  PersonSimpleRun = 'person_simple_run',
  PersonSimpleSwim = 'person_simple_swim',
  PersonSimpleWalk = 'person_simple_walk',
  Plant = 'plant',
  Scales = 'scales',
  Sleep = 'sleep',
  Steps = 'steps',
  Thermometer = 'thermometer',
  Timer = 'timer',
  Warning = 'warning',
}

export enum UiInlineAlert {
  Danger = 'danger',
  Info = 'info',
  Neutral = 'neutral',
  Success = 'success',
  Warning = 'warning',
}

export type UiState = {
  __typename?: 'UIState'
  createdAt: Scalars['ISO8601DateTime']['output']
  name: UiStateNames
  value: Scalars['Boolean']['output']
}

export enum UiStateNames {
  CheckoutCompleted = 'checkout_completed',
  DexcomSensorPrompt = 'dexcom_sensor_prompt',
  OwnSensorGlucoseDetected = 'own_sensor_glucose_detected',
  OwnSensorOnboardingCompleted = 'own_sensor_onboarding_completed',
  OwnSensorTutorialCompleted = 'own_sensor_tutorial_completed',
  PrescriptionDexcomG7Allowed = 'prescription_dexcom_g7_allowed',
  SyncedLinkup = 'synced_linkup',
  ViewedTrends = 'viewed_trends',
}

export type UpgradedSubscription = {
  __typename?: 'UpgradedSubscription'
  subscription: Subscription
}

export type User = {
  __typename?: 'User'
  activeRefillConsent?: Maybe<UserRefillConsent>
  addonLimits: Array<AddonLimit>
  address?: Maybe<Address>
  age?: Maybe<Scalars['String']['output']>
  avatar?: Maybe<Scalars['String']['output']>
  coachAssignmentInfo: CoachAssignmentInfo
  coachAssignments?: Maybe<Array<CoachAssignment>>
  coachChatConversation?: Maybe<ChatConversation>
  coachProfile?: Maybe<CoachProfile>
  compoundedSemaglutideConsent: Scalars['Boolean']['output']
  createdAt: Scalars['ISO8601DateTime']['output']
  /** @deprecated use ongoing_dietitian_subscription */
  currentDietitianSubscription?: Maybe<Subscription>
  dateOfBirth?: Maybe<Scalars['String']['output']>
  dexcomConnection?: Maybe<DexcomConnectionType>
  dexcomSensorPromptUiState?: Maybe<Scalars['Boolean']['output']>
  eligibleForDexcomResearch: Scalars['Boolean']['output']
  eligibleForInsuranceCoverage: Scalars['Boolean']['output']
  email: Scalars['String']['output']
  features: Array<MobileAppFeature>
  firstName: Scalars['String']['output']
  floridaDigitalBillOfRightsConsent: Scalars['Boolean']['output']
  fulfillmentConfiguration: FulfillmentConfiguration
  fullName: Scalars['String']['output']
  futurePaymentsConsent: Scalars['Boolean']['output']
  goalMetrics: Array<GoalMetric>
  governmentIdCard?: Maybe<Scalars['String']['output']>
  healthReject?: Maybe<Scalars['String']['output']>
  healthieId?: Maybe<Scalars['Int']['output']>
  id: Scalars['ID']['output']
  intercomDigest?: Maybe<Scalars['String']['output']>
  isCoach: Scalars['Boolean']['output']
  isSupport: Scalars['Boolean']['output']
  kind: UserKind
  lastAnsweredHealthSurveyQuestionKey?: Maybe<SurveysQuestionKey>
  lastAnsweredMedicalHistorySurveyQuestionKey?: Maybe<SurveysQuestionKey>
  lastAppPlatform?: Maybe<Scalars['String']['output']>
  lastAppVersion?: Maybe<Scalars['String']['output']>
  /** @deprecated use last_prescription_approval_request */
  lastApprovalRequest?: Maybe<ApprovalRequest>
  lastAsyncChatAssignment?: Maybe<CoachAssignment>
  /** @deprecated use last_async_chat_assignment */
  lastCoachAssignment?: Maybe<CoachAssignment>
  lastCoreSubscription?: Maybe<Subscription>
  lastDexcomResearchState?: Maybe<ResearchState>
  lastDietitianSubscription?: Maybe<Subscription>
  lastDoctorAssignment?: Maybe<DoctorAssignment>
  lastDppApprovalRequest?: Maybe<ApprovalRequest>
  lastGlpApprovalRequest?: Maybe<ApprovalRequest>
  lastGlpRenewalApprovalRequest?: Maybe<ApprovalRequest>
  lastGovernmentIdApprovalRequest?: Maybe<ApprovalRequest>
  lastMedicalAppointment?: Maybe<EhrAppointment>
  lastName: Scalars['String']['output']
  lastPrescriptionApprovalRequest?: Maybe<ApprovalRequest>
  lastResearchApprovalRequest?: Maybe<ApprovalRequest>
  lastVideoChatAssignment?: Maybe<CoachAssignment>
  /** @deprecated use core subscription */
  latestInvoice?: Maybe<Invoice>
  libreAccount?: Maybe<LibreAccount>
  migrationFromInsuranceEnabled: Scalars['Boolean']['output']
  nutrisenseAdmin: Scalars['Boolean']['output']
  nutrisenseGetHealthieId?: Maybe<Scalars['Int']['output']>
  ongoingCareNonRefundableConsent: Scalars['Boolean']['output']
  ongoingCoreSubscription?: Maybe<Subscription>
  ongoingDietitianSubscription?: Maybe<Subscription>
  ongoingSubscriptions: Array<Subscription>
  organization: Organization
  /** @deprecated intents are deprecated */
  paymentFailedProviderErrorMessage?: Maybe<Scalars['String']['output']>
  paymentMethod?: Maybe<PaymentMethod>
  phoneNumber?: Maybe<Scalars['String']['output']>
  phoneNumberConsent?: Maybe<Scalars['Boolean']['output']>
  primaryGoal?: Maybe<Goal>
  referralPartner: UserReferralPartnerDef
  referralUrl: Scalars['String']['output']
  refillConsentNeeded: Scalars['Boolean']['output']
  role: UserRole
  sensor?: Maybe<Sensor>
  sensorInfo?: Maybe<UserSensorInfo>
  settings: UserSettings
  sex?: Maybe<Scalars['String']['output']>
  /** @deprecated deprecated in 2.8 */
  signUpState: SignUpState
  stripeCreditBalance: Scalars['Float']['output']
  stripeId?: Maybe<Scalars['String']['output']>
  subscriptions: Array<Subscription>
  surveyLinks: Array<SurveyLink>
  telehealthConsent: Scalars['Boolean']['output']
  timeZone: Scalars['String']['output']
  truepillPatientToken?: Maybe<Scalars['String']['output']>
  uiStates: Array<UiState>
  userProperties?: Maybe<UserProperties>
  uxSensorMode: SensorMode
}

export enum UserApprovalRequestKind {
  Dpp = 'dpp',
  Glp = 'glp',
  GlpRenewal = 'glp_renewal',
  GovernmentId = 'government_id',
  Prescription = 'prescription',
  Research = 'research',
}

export enum UserApprovalRequestState {
  Approved = 'approved',
  ConsentRejected = 'consent_rejected',
  ConsentWithdrawn = 'consent_withdrawn',
  DidNotComplete = 'did_not_complete',
  Init = 'init',
  PendingApproval = 'pending_approval',
  PendingMember = 'pending_member',
  PendingRejection = 'pending_rejection',
  PendingUpdate = 'pending_update',
  Rejected = 'rejected',
  Stale = 'stale',
}

export type UserAuth = {
  __typename?: 'UserAuth'
  /** @deprecated Use user_presenter instead */
  features: Array<MobileAppFeature>
  role?: Maybe<UserRole>
  status?: Maybe<Scalars['String']['output']>
  success: Scalars['Boolean']['output']
  token?: Maybe<Scalars['String']['output']>
  user?: Maybe<User>
}

export type UserCalendar = {
  __typename?: 'UserCalendar'
  dates: Array<CalendarDate>
}

export enum UserChecklistItem {
  CompleteGoalsQuestionnaire = 'complete_goals_questionnaire',
  CompleteVideoCall = 'complete_video_call',
  LogMeal = 'log_meal',
  ProvideInsuranceInformation = 'provide_insurance_information',
  ShareLabResults = 'share_lab_results',
  SignInToTheApp = 'sign_in_to_the_app',
  StartLesson = 'start_lesson',
  ViewTrends = 'view_trends',
}

export enum UserChecklistItemsKind {
  Onboarding = 'onboarding',
}

export type UserCollection = {
  __typename?: 'UserCollection'
  users: Array<UserMin>
}

export enum UserDailyActionCategory {
  Activity = 'activity',
  Experiment = 'experiment',
  Journal = 'journal',
  Nutrition = 'nutrition',
  Trends = 'trends',
}

export enum UserDailyActionKind {
  Carbs_15Grams_30MinutesBeforeExercise = 'carbs_15_grams_30_minutes_before_exercise',
  Carbs_30GramsFromBread = 'carbs_30_grams_from_bread',
  Carbs_30GramsFromDessert = 'carbs_30_grams_from_dessert',
  Carbs_30GramsFromFruit = 'carbs_30_grams_from_fruit',
  Carbs_30GramsFromRice = 'carbs_30_grams_from_rice',
  Carbs_30GramsFromStarchyVegetable = 'carbs_30_grams_from_starchy_vegetable',
  CompareMeals = 'compare_meals',
  DigestionQuality = 'digestion_quality',
  FastingWindow = 'fasting_window',
  Fiber_25Grams = 'fiber_25_grams',
  Fiber_38Grams = 'fiber_38_grams',
  GlucoseAverage = 'glucose_average',
  GlucoseMax = 'glucose_max',
  GlucoseMorningAverage = 'glucose_morning_average',
  GlucoseSleepAverage = 'glucose_sleep_average',
  GlucoseTimeInRange = 'glucose_time_in_range',
  GlucoseVariability = 'glucose_variability',
  HalfOfPlateNonStarchyVegetables = 'half_of_plate_non_starchy_vegetables',
  HighFiberAndProteinBreakfast = 'high_fiber_and_protein_breakfast',
  HungerLevels = 'hunger_levels',
  LastMeal_3HoursBeforeBed = 'last_meal_3_hours_before_bed',
  LowCarbDinner = 'low_carb_dinner',
  NoNakedCarbs = 'no_naked_carbs',
  NoScreenTime_1HourBeforeBed = 'no_screen_time_1_hour_before_bed',
  NonStarchVegetables_3To_5Cups = 'non_starch_vegetables_3_to_5_cups',
  Protein_30GramsPerMeal = 'protein_30_grams_per_meal',
  ProteinAfterExercise = 'protein_after_exercise',
  QuarterOfPlateProtein = 'quarter_of_plate_protein',
  Sleep_7To_9Hours = 'sleep_7_to_9_hours',
  SleepQuality = 'sleep_quality',
  Steps_7500 = 'steps_7500',
  StressLevels = 'stress_levels',
  VegetableOrProteinBeforeStarchyCarbs = 'vegetable_or_protein_before_starchy_carbs',
  Walk_15To_30MinutesAfterMeals = 'walk_15_to_30_minutes_after_meals',
  Water_8Glasses = 'water_8_glasses',
  Weight = 'weight',
}

export enum UserDailyActionStatus {
  Active = 'active',
  Inactive = 'inactive',
}

export enum UserExperienceSensorModeKind {
  ActivePrescriptionDexcomG7 = 'active_prescription_dexcom_g7',
  ActiveResearchDexcom = 'active_research_dexcom',
  Default = 'default',
  LibreLinkup = 'libre_linkup',
  OwnSensorDexcomG6 = 'own_sensor_dexcom_g6',
  OwnSensorDexcomG7 = 'own_sensor_dexcom_g7',
  OwnSensorDexcomStelo = 'own_sensor_dexcom_stelo',
  OwnSensorLibre1 = 'own_sensor_libre1',
  OwnSensorLibre2 = 'own_sensor_libre2',
  OwnSensorLibre3 = 'own_sensor_libre3',
  PendingLibre3 = 'pending_libre3',
  PendingPrescriptionDexcomG7 = 'pending_prescription_dexcom_g7',
  PendingResearchDexcom = 'pending_research_dexcom',
}

export enum UserFileTag {
  BodyPhoto = 'body_photo',
  DexcomResearchConsent = 'dexcom_research_consent',
  DppHbA1cEvidence = 'dpp_hb_a1c_evidence',
  DppWeightEvidence = 'dpp_weight_evidence',
  GovernmentIdCard = 'government_id_card',
  HealthInsuranceCard = 'health_insurance_card',
  InsuranceCardBackPhoto = 'insurance_card_back_photo',
  InsuranceCardFrontPhoto = 'insurance_card_front_photo',
  IntercomAttachment = 'intercom_attachment',
  LabWorkResults = 'lab_work_results',
}

export enum UserGoalKind {
  ImproveGlucoseValues = 'improve_glucose_values',
  LearnAboutGlucoseLevels = 'learn_about_glucose_levels',
  LoseWeight = 'lose_weight',
  ManageChronicCondition = 'manage_chronic_condition',
  OptimizeFitnessPerformance = 'optimize_fitness_performance',
  Other = 'other',
  PreventDisease = 'prevent_disease',
  PromoteLongevity = 'promote_longevity',
}

export enum UserGoalMetricKind {
  GlucoseMax = 'glucose_max',
  GlucoseMean = 'glucose_mean',
  GlucoseMin = 'glucose_min',
  GlucoseMorningAverage = 'glucose_morning_average',
  GlucoseSleepAverage = 'glucose_sleep_average',
  GlucoseStandardDeviation = 'glucose_standard_deviation',
  GlucoseTimeWithinRange = 'glucose_time_within_range',
  JournalAppetite = 'journal_appetite',
  JournalEnergy = 'journal_energy',
  JournalFocus = 'journal_focus',
  JournalMood = 'journal_mood',
  JournalStress = 'journal_stress',
  MeasurementsWeight = 'measurements_weight',
}

export enum UserInsurancePolicyEligibilityStatus {
  Coinsurance = 'coinsurance',
  Copay = 'copay',
  Deductible = 'deductible',
  Eligible = 'eligible',
  NotEligible = 'not_eligible',
  NotSupported = 'not_supported',
  Pending = 'pending',
}

export enum UserInsurancePolicyHolderRelationship {
  Child = 'child',
  Other = 'other',
  Self = 'self',
  Spouse = 'spouse',
}

export enum UserInsurancePolicyKind {
  Aetna = 'aetna',
  Anthem = 'anthem',
  BlueCrossBlueShield = 'blue_cross_blue_shield',
  Cigna = 'cigna',
  Empire = 'empire',
  Highmark = 'highmark',
  Horizon = 'horizon',
  SanaBenefits = 'sana_benefits',
  UnitedHealthcare = 'united_healthcare',
}

export type UserInsurancePolicyKindDef = {
  __typename?: 'UserInsurancePolicyKindDef'
  key: UserInsurancePolicyKind
  title: Scalars['String']['output']
}

export enum UserInsurancePolicyStateKind {
  Canceled = 'canceled',
  CanceledInsuranceNotAccepted = 'canceled_insurance_not_accepted',
  CanceledNoProvidersInn = 'canceled_no_providers_inn',
  Created = 'created',
  Eligible = 'eligible',
  EligiblePatientResponsibility = 'eligible_patient_responsibility',
  EligiblePreAuth = 'eligible_pre_auth',
  Hold = 'hold',
  InReview = 'in_review',
  NotEligible = 'not_eligible',
  NotSupported = 'not_supported',
}

export enum UserInsurancePolicyStatus {
  Created = 'created',
  InReview = 'in_review',
  Reviewed = 'reviewed',
  Submitted = 'submitted',
}

export enum UserKind {
  Influencer = 'influencer',
  Internal = 'internal',
  Investor = 'investor',
  Regular = 'regular',
}

export type UserMin = {
  __typename?: 'UserMin'
  email: Scalars['String']['output']
  fullName: Scalars['String']['output']
  id: Scalars['ID']['output']
  phoneNumber: Scalars['String']['output']
  timeZone: Scalars['String']['output']
}

export type UserProperties = {
  __typename?: 'UserProperties'
  communicationFrequency?: Maybe<UserProperty>
  complimentarySupportDecision?: Maybe<UserProperty>
  dietitianRequest?: Maybe<UserProperty>
  dietitianSensorCountNotes?: Maybe<UserProperty>
  healthGoal?: Maybe<UserProperty>
  nutritionistGroup?: Maybe<UserProperty>
  oneMonthActiveFeedbackDietitian?: Maybe<UserProperty>
  scheduledVideoCallStatus?: Maybe<UserProperty>
  testGroup?: Maybe<UserProperty>
  videoCallRescheduleFreePassUsed?: Maybe<UserProperty>
}

export type UserProperty = {
  __typename?: 'UserProperty'
  id: Scalars['Int']['output']
  kind: UserPropertyKind
  userId: Scalars['ID']['output']
  value: Scalars['String']['output']
}

export enum UserPropertyKind {
  ActiveInterventions = 'active_interventions',
  Background = 'background',
  BmiRange = 'bmi_range',
  CoachingStyle = 'coaching_style',
  CommunicationFrequency = 'communication_frequency',
  ComplimentarySupportDecision = 'complimentary_support_decision',
  CustomKeyPoint = 'custom_key_point',
  Diet = 'diet',
  DietitianNotes = 'dietitian_notes',
  DietitianRequest = 'dietitian_request',
  DietitianSensorCountNotes = 'dietitian_sensor_count_notes',
  Education = 'education',
  FoodAllergies = 'food_allergies',
  FutureInterventions = 'future_interventions',
  GlucoseManagementKnowledge = 'glucose_management_knowledge',
  HealthConditions = 'health_conditions',
  HealthGoal = 'health_goal',
  Height = 'height',
  InitialInsuranceCallDuration = 'initial_insurance_call_duration',
  InsuranceReimbursementInterest = 'insurance_reimbursement_interest',
  InsuranceSurveySkipReason = 'insurance_survey_skip_reason',
  InsuranceVideoCallGoal = 'insurance_video_call_goal',
  Libre_3Outage = 'libre_3_outage',
  Libre_3OutagePreShipmentNotice = 'libre_3_outage_pre_shipment_notice',
  Medications = 'medications',
  NutritionistGroup = 'nutritionist_group',
  OneMonthActiveFeedbackDietitian = 'one_month_active_feedback_dietitian',
  OwnCgmDevice = 'own_cgm_device',
  OwnGlucometer = 'own_glucometer',
  PrimaryJoiningGoal = 'primary_joining_goal',
  ScheduledVideoCallStatus = 'scheduled_video_call_status',
  StepsPerDay = 'steps_per_day',
  TestGroup = 'test_group',
  VideoCallRescheduleFreePassUsed = 'video_call_reschedule_free_pass_used',
  Weight = 'weight',
}

export enum UserReferralPartner {
  Bm = 'bm',
  Bp = 'bp',
  Influencer = 'influencer',
  It = 'it',
  Lg = 'lg',
  LoseIt = 'lose_it',
  Lpd = 'lpd',
  Mfp = 'mfp',
  Mindypelz = 'mindypelz',
  NoPartner = 'no_partner',
  Pnmag = 'pnmag',
  Sc = 'sc',
  Tovala = 'tovala',
  Vip = 'vip',
}

export type UserReferralPartnerDef = {
  __typename?: 'UserReferralPartnerDef'
  key: UserReferralPartner
  logoUrl?: Maybe<Scalars['String']['output']>
  name: Scalars['String']['output']
  requiresProtocol: Scalars['Boolean']['output']
  schedulingWithoutPaymentMethod: Scalars['Boolean']['output']
}

export type UserRefillConsent = {
  __typename?: 'UserRefillConsent'
  givenAt: Scalars['ISO8601DateTime']['output']
  id: Scalars['ID']['output']
  invalidatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>
}

export enum UserRole {
  Admin = 'admin',
  CustomerSupport = 'customer_support',
  Engineer = 'engineer',
  ExternalAdmin = 'external_admin',
  Nutritionist = 'nutritionist',
  NutritionistManager = 'nutritionist_manager',
  Operations = 'operations',
  User = 'user',
}

export type UserSensorInfo = {
  __typename?: 'UserSensorInfo'
  firstActivationDate?: Maybe<Scalars['ISO8601Date']['output']>
  firstStartTime?: Maybe<Scalars['ISO8601DateTime']['output']>
  lastSensor?: Maybe<Sensor>
  replacementRequestsCount: Scalars['Int']['output']
  totalCount: Scalars['Int']['output']
}

export enum UserSensorKind {
  DexcomG7 = 'dexcom_g7',
  Libre1 = 'libre1',
  Libre2 = 'libre2',
  Libre3 = 'libre3',
  PrescriptionDexcomG7 = 'prescription_dexcom_g7',
}

export type UserSettings = {
  __typename?: 'UserSettings'
  /** @deprecated Removed in v2.6.0 */
  appVersionReminder: Scalars['Boolean']['output']
  canScanExpired: Scalars['Boolean']['output']
  dashboardGraph: Scalars['String']['output']
  dexcomSync: Scalars['Boolean']['output']
  displayActivityScore: Scalars['Boolean']['output']
  displayDailyScore: Scalars['Boolean']['output']
  displayMealScore: Scalars['Boolean']['output']
  fastingEnd: Scalars['Int']['output']
  fastingStart: Scalars['Int']['output']
  glucoseHighThreshold: Scalars['Int']['output']
  glucoseLowThreshold: Scalars['Int']['output']
  googleFitSync: Scalars['Boolean']['output']
  healthKitSync: Scalars['Boolean']['output']
  id: Scalars['ID']['output']
  integrationsSyncSettings: IntegrationsSync
  journalNotifications: Scalars['Boolean']['output']
  ketoMojoSync: Scalars['Boolean']['output']
  ketoneHighThreshold: Scalars['Float']['output']
  ketoneLowThreshold: Scalars['Float']['output']
  libreViewSync: Scalars['Boolean']['output']
  macroGoalsDaily: MacroGoals
  patternsNotifications: Scalars['Boolean']['output']
  reminders: Scalars['Boolean']['output']
  scanReminder: Scalars['Int']['output']
  showNetCarbs: Scalars['Boolean']['output']
  statsSettings: Array<StatsSettings>
  /** @deprecated Removed in v2.6.0 */
  trackingMode: TrackingMode
  unitSystem: Scalars['String']['output']
  updatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>
}

export enum UserSignUpSource {
  Checkout = 'checkout',
  DppWebFlow = 'dpp_web_flow',
  GlpWebFlow = 'glp_web_flow',
  InsuranceFormSubmission = 'insurance_form_submission',
  InsuranceWebFlow = 'insurance_web_flow',
  MobileApp = 'mobile_app',
  OwnSensorWebFlow = 'own_sensor_web_flow',
}

export type ValuePair = NumericPair | RangePair | StringPair | TimePair

export type Workout = {
  activityId?: InputMaybe<Scalars['Float']['input']>
  activityName: Scalars['String']['input']
  calories?: InputMaybe<Scalars['Float']['input']>
  device?: InputMaybe<Scalars['String']['input']>
  distance?: InputMaybe<Scalars['Float']['input']>
  end?: InputMaybe<Scalars['ISO8601DateTime']['input']>
  endDate?: InputMaybe<Scalars['ISO8601DateTime']['input']>
  id?: InputMaybe<Scalars['String']['input']>
  metadata?: InputMaybe<Scalars['JSON']['input']>
  sourceId?: InputMaybe<Scalars['String']['input']>
  sourceName?: InputMaybe<Scalars['String']['input']>
  start?: InputMaybe<Scalars['ISO8601DateTime']['input']>
  startDate?: InputMaybe<Scalars['ISO8601DateTime']['input']>
  tracked?: InputMaybe<Scalars['Boolean']['input']>
}
