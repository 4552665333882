import { createSelector } from 'reselect'
import { RootStoreState } from '@src/models/app.types'

export const dailyActionsStoreStateSelector = (state: RootStoreState) => state.dailyActions

export const dailyActionsSelector = createSelector(
  dailyActionsStoreStateSelector,
  (dailyActions) => dailyActions.dailyActions,
)

const dailyActionsCompletionsSelector = createSelector(
  dailyActionsStoreStateSelector,
  (dailyActions) => dailyActions.dailyActionsCompletions,
)

export const preferredHeroChartSelector = createSelector(
  dailyActionsStoreStateSelector,
  (dailyActions) => dailyActions.preferredHeroChart,
)

export const dailyActionsWithCompletionsSelector = createSelector(
  [dailyActionsSelector, dailyActionsCompletionsSelector],
  (actions, completions) => {
    const completionsByActionIdLookup = completions.reduce((acc, completion) => {
      acc[completion.dailyActionId] = completion.completed
      return acc
    }, {} as Record<number, boolean>)

    return actions.map((action) => ({
      ...action,
      completed: !!completionsByActionIdLookup[Number(action.id)],
    }))
  },
)
