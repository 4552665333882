import React from 'react'
import { View } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Button, Icon, Text } from '@src/components/base'
import { ScrollViewWithFade } from '@src/components'
import { AppStackParamList } from '@src/navigation/types'

export const FinalizePrescriptionDexcomSwitch = () => {
  const styles = useStyleSheet(themedStyles)
  const navigation = useNavigation<StackNavigationProp<AppStackParamList>>()
  const insets = useSafeAreaInsets()

  const onConfirmPress = () => {
    navigation.replace('SensorSettings')
  }

  const onDeclinePress = () => {
    navigation.replace('Drawer', { screen: 'Dashboard' })
  }

  return (
    <View style={styles.container}>
      <ScrollViewWithFade scrollViewStyle={styles.scrollViewContent}>
        <Icon name="seal-check" weight="fill" style={styles.checkmark} size={48} />
        <Text type="title-2" style={styles.text}>
          Success
        </Text>
        <Text type="regular" style={styles.text}>
          You successfully turned off the FreeStyle Libre integration. When ready, please proceed to
          set up your new Dexcom G7.
        </Text>
      </ScrollViewWithFade>
      <View style={[styles.buttonsWrapper, { marginBottom: insets.bottom + 16 }]}>
        <Button type="primary" size="block" onPress={onConfirmPress} accessibilityLabel="Confirm">
          Activate Dexcom Sensor
        </Button>
        <Button
          type="outline"
          size="block"
          onPress={onDeclinePress}
          accessibilityLabel="Not yet"
          style={styles.secondaryButton}
        >
          Do It Later
        </Button>
      </View>
    </View>
  )
}

const themedStyles = StyleService.create({
  container: {
    flex: 1,
    backgroundColor: 'theme.background',
    paddingTop: 72,
  },
  scrollViewContent: {
    paddingHorizontal: 32,
    paddingVertical: 24,
  },
  secondaryButton: {
    marginTop: 8,
  },
  buttonsWrapper: {
    paddingHorizontal: 16,
  },
  checkmark: {
    alignSelf: 'center',
    marginBottom: 24,
    color: 'theme.success.base',
  },
  text: {
    textAlign: 'center',
    marginBottom: 24,
  },
})
