import { useSelector } from 'react-redux'
import moment from 'moment'
import { useCallback } from 'react'
import { SensorTypes } from 'react-native-freestyle-libre'
import { Feature, useFeatureFlag } from '@src/components'
import { userSelector } from '@src/selectors/app'
import { Storage } from '@src/utils'
import { useStorageValue } from '@src/utils/storage'
import { SCAN_DISABLED_SENSORS } from '../containers/types'

export interface UseShowScanMigrationOnScanParams {
  model: SensorTypes
}

// Show migration screen at most once a day
export const useShowScanMigrationOnScan = () => {
  const user = useSelector(userSelector)
  const showScanDisableNoticeOnScan = useFeatureFlag(Feature.ScanDisableNoticeOnScan)
  const [scanDisabledNoticeShownAt] = useStorageValue(
    `${Storage.SCAN_DISABLED_NOTICE_SHOWN_AT_KEY}_${user?.id}`,
  )

  return useCallback(
    ({ model }: UseShowScanMigrationOnScanParams) => {
      if (!showScanDisableNoticeOnScan) {
        return false
      }

      if (!SCAN_DISABLED_SENSORS.includes(model)) {
        return false
      }

      // they have never seen the notice after a scan
      if (!scanDisabledNoticeShownAt) {
        return true
      }

      const dismissedToday = moment(scanDisabledNoticeShownAt).isSame(moment(), 'day')

      // they already saw the notice today
      if (dismissedToday) {
        return false
      }

      return true
    },
    [showScanDisableNoticeOnScan, scanDisabledNoticeShownAt],
  )
}
