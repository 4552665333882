const loadingOpts = {
  exceptGlobal: [
    'activities/delete',
    'activities/submit',
    'app/fetchCalendarData',
    'app/fetchEventsCalendarData',
    'app/exportData',
    'app/fetchProducts',
    'dailyActions/markComplete',
    'dailyActions/upsert',
    'dailyActions/markIncomplete',
    'dailyActions/list',
    'checklist/fetchOnboardingItems',
    'history/fetch',
    'ingredients/delete',
    'ingredients/fetch',
    'ingredients/submit',
    'insights/fetchChartsData',
    'insights/fetchStats',
    'meals/addIngredient',
    'meals/delete',
    'meals/deleteIngredient',
    'meals/fetch',
    'meals/fetchMeal',
    'meals/getMealImageDescription',
    'meals/submit',
    'measurements/delete',
    'measurements/submit',
    'journalEntries/create',
    'journalEntries/update',
    'journalEntries/delete',
    'scans/fetch',
    'sensor/updateSettings',
    'users/autocomplete',
    'users/update',
    'settings/fetchLibreLinkupConnection',
    'settings/fetchTerraProviders',
    'settings/update',
    'courses/fetchPrograms',
    'courses/fetchModules',
    'courses/fetchLessons',
    'courses/recordLessonProgress',
    'chatbot/createChatbotToken',
    'notifications/fetch',
    'notifications/dismiss',
    'notifications/complete',
    'nutritionistHub/fetchInsurancePolicy',
    'nutritionistHub/fetchVideoCallInfo',
    'nutritionistHub/fetchUpcomingAppointments',
    'nutritionistHub/fetchSuggestedAvailability',
    'marketplace/purchaseOneTimePayment',
    'marketplace/fetchInvoice',
  ],
  except: [
    'activities/fetchFavorites',
    'activities/fetchRecentActivities',
    'activities/updateFavorite',
    'app/changeDateRange',
    'app/clearCaches',
    'app/config',
    'app/createDirectUpload',
    'app/createFileFromSignedId',
    'app/logout',
    'app/startQuestionnaire',
    'app/surveyQuestionnaire',
    'app/updateHealthData',
    'app/updateKetoMojoData',
    'dailyActions/markComplete',
    'dailyActions/updateStatus',
    'dailyActions/markIncomplete',
    'courses/fetchCourses',
    'events/changeDateRange',
    'events/fetch',
    'events/fetchCharts',
    'events/fetchNutrition',
    'marketplace/createProviderSetupIntent',
    'meals/fetchFavorites',
    'meals/fetchRecentMeals',
    'meals/updateFavorite',
    'scans/submitBluetooth',
    'sensor/updateStatus',
    'users/fetch',
    'users/registerToken',
    'notifications/dismiss',
    'notifications/complete',
    'nutritionistHub/fetchAvailableDays',
    'nutritionistHub/fetchAvailableSlots',
    'nutritionistHub/fetchEarlierAvailableSlots',
    'nutritionistHub/submitInsurancePolicy',
    'settings/generateTerraSdkToken',
    'policyConsents/statuses',
    'nutritionistHub/fetchMemberNotes',
  ],
}

export default loadingOpts
