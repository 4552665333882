import { useSelector } from 'react-redux'
import { groupBy, xor } from 'lodash'
import { useMemo } from 'react'
import { dailyActionsConfigSelector } from '@src/selectors/app'
import { DailyActionKind, MobileAppFeature } from '@src/types'
import { dailyActionsSelector } from '@src/selectors/dailyActions'
import { User } from '@src/utils'

export const TODAY_SECTION_ID = 0

export interface DailyActionsSection {
  id: number
  title: string
  data: DailyActionKind[]
}

export const NUTRITION_SUMMARY_CARD_KEY = 'nutrition-summary'

const nutritionSummaryCard = {
  description: 'Set and track nutrient goals, like calories, protein, fat, and carbs.',
  title: 'Nutrition Summary',
  key: NUTRITION_SUMMARY_CARD_KEY,
} as DailyActionKind

export const useEditDailyActionsSections = (): DailyActionsSection[] => {
  const shouldShowNutritionSummaryCard = User.hasFeature(MobileAppFeature.ViewGlucoseChart)
  const allDailyActionKinds = useSelector(dailyActionsConfigSelector)
  const activeDailyActionsKinds = useSelector(dailyActionsSelector).map((action) => action?.kind)

  return useMemo(() => {
    const todaySectionItems = activeDailyActionsKinds.reduce((acc, action) => {
      allDailyActionKinds.find((actionKind) => {
        if (actionKind.key === action) {
          acc.push(actionKind)
        }
      })
      return acc
    }, [] as DailyActionKind[])

    const dailyActionsCategories = groupBy(xor(allDailyActionKinds, todaySectionItems), 'category')

    const groupedAllDailyActions = Object.keys(dailyActionsCategories).map((category, i) => ({
      id: i + 1,
      title: category,
      data: dailyActionsCategories[category],
    }))

    return [
      {
        id: TODAY_SECTION_ID,
        title: 'Selected focus Items',
        data: shouldShowNutritionSummaryCard
          ? [nutritionSummaryCard, ...todaySectionItems]
          : todaySectionItems,
      },
      ...groupedAllDailyActions,
    ]
  }, [activeDailyActionsKinds, allDailyActionKinds, shouldShowNutritionSummaryCard])
}
