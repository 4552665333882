import React from 'react'
import { Pressable, View } from 'react-native'
import { startCase } from 'lodash'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Icon, Text } from '@src/components/base'

export interface SectionItemProps {
  title: string
  onPress: () => void
  expanded: boolean
}

export const SectionHeader = ({ title, onPress, expanded }: SectionItemProps) => {
  const styles = useStyleSheet(themedStyle)

  return (
    <Pressable accessibilityLabel="Daily Action Item" onPress={onPress}>
      <View style={styles.sectionItem}>
        <Text type="large" bold>
          {startCase(title)}
        </Text>
        <Icon name={expanded ? 'caret-up' : 'caret-down'} style={styles.caretRight} />
      </View>
    </Pressable>
  )
}

const themedStyle = StyleService.create({
  sectionItem: {
    flex: 1,
    backgroundColor: 'theme.background',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: 12,
    paddingTop: 24,
    paddingHorizontal: 16,
  },
  caretRight: {
    width: 24,
    height: 24,
    color: 'theme.text.primary',
  },
})
