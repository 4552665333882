import React from 'react'
import { TouchableOpacity, View, useWindowDimensions } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { StyleService } from '@src/style/service'
import { useStyleSheet } from '@src/style/service'
import { Text } from '@src/components/base'
import { useMacroData } from '@src/screens/Events/utils/useMacroData'
import { WithWalkthrough } from '@src/components/WithWalkthrough'
import { Analytics, CustomEventTypes } from '@src/config'
import {
  HomeScreenWalkthroughSteps,
  useHomeScreenWalkthrough,
} from '@src/context/homeScreenWalkthroughContext'
import { WalkthroughTooltip } from '@src/components/WalkthroughTooltip'
import { ProgressBarChart } from './charts/ProgressBarChart'
import { CaloriesArcChart } from './charts/CaloriesArcChart'

export const NUTRITION_CHART_COLORS = {
  calories: {
    progress: 'rgb(73, 144, 228)',
    background: 'rgba(73, 144, 228, 0.2)',
  },
  carbs: {
    progress: 'rgb(254, 126, 90)',
    background: 'rgba(254, 126, 90, 0.2)',
  },
  netCarbs: {
    progress: 'rgb(254, 126, 90)',
    background: 'rgba(254, 126, 90, 0.2)',
  },
  protein: {
    progress: 'rgb(93, 202, 147)',
    background: 'rgba(93, 202, 147, 0.2)',
  },
  fat: {
    progress: 'rgb(253, 195, 85)',
    background: 'rgba(253, 195, 85, 0.2)',
  },
}

export const NutritionSummaryChart = () => {
  const styles = useStyleSheet(themedStyles)
  const deviceWidth = useWindowDimensions().width
  const progressBarWidth = 0.25 * deviceWidth
  const { values, goals, showNetCarbs } = useMacroData()
  const navigation = useNavigation()
  const { shouldShow, advanceWalkthroughToNextStep, isStepVisible } = useHomeScreenWalkthrough()

  const customizeAction = (
    <TouchableOpacity
      accessibilityLabel="Customize Daily Actions"
      onPress={() => {
        navigation.navigate('EditDailyActionsModal')
      }}
    >
      <Text type="regular" bold lineSpacing="none" style={styles.customizeActionText}>
        Customize
      </Text>
    </TouchableOpacity>
  )

  const handleChartPress = () => {
    Analytics.track(CustomEventTypes.HomeScreenNutritionSummaryChartTapped)
    navigation.navigate('NutritionSummaryModal')
  }

  const customizeActionWithWalkthrough = (
    <WithWalkthrough
      isVisible={isStepVisible(HomeScreenWalkthroughSteps.Customize)}
      onClose={() => {
        Analytics.track(CustomEventTypes.HomeScreenWalkthroughCustomizeTapped)
        advanceWalkthroughToNextStep()
      }}
      allowChildInteraction={false}
      accessibilityLabel="Daily Actions Section"
      content={
        <WalkthroughTooltip
          title="Personalize Your Actions"
          subtitle="Customize these actions to fit your health goals."
        />
      }
      placement="bottom"
    >
      <TouchableOpacity
        accessibilityLabel="Customize Daily Actions"
        style={styles.walkthroughFocusContainer}
      >
        <Text type="regular" bold lineSpacing="none" style={styles.customizeActionText}>
          Customize
        </Text>
      </TouchableOpacity>
    </WithWalkthrough>
  )

  return (
    <View style={styles.container}>
      <View style={styles.header}>
        <Text type="large" bold lineSpacing="none">
          Nutrition Summary
        </Text>
        {shouldShow ? customizeActionWithWalkthrough : customizeAction}
      </View>
      <TouchableOpacity accessibilityLabel="Nutrition Summary Chart" onPress={handleChartPress}>
        <View style={styles.chartContainer}>
          <CaloriesArcChart
            title="Calories"
            minValue={0}
            maxValue={goals.calories}
            value={Math.round(values.calories)}
          />
          <View style={styles.progressBarContainer}>
            <ProgressBarChart
              title="Protein"
              value={Math.round(values.protein)}
              max={goals.protein}
              width={progressBarWidth}
              progressColor={NUTRITION_CHART_COLORS.protein.progress}
              backgroundColor={NUTRITION_CHART_COLORS.protein.background}
            />
            <ProgressBarChart
              title="Fat"
              value={Math.round(values.fat)}
              max={goals.fat}
              width={progressBarWidth}
              progressColor={NUTRITION_CHART_COLORS.fat.progress}
              backgroundColor={NUTRITION_CHART_COLORS.fat.background}
            />
            <ProgressBarChart
              title={showNetCarbs ? 'Net Carbs' : 'Carbs'}
              value={showNetCarbs ? Math.round(values.netCarbs) : Math.round(values.carbs)}
              max={showNetCarbs ? goals.netCarbs : goals.carbs}
              width={progressBarWidth}
              progressColor={NUTRITION_CHART_COLORS.carbs.progress}
              backgroundColor={NUTRITION_CHART_COLORS.carbs.background}
            />
          </View>
        </View>
      </TouchableOpacity>
    </View>
  )
}

const themedStyles = StyleService.create({
  container: {
    marginHorizontal: 16,
    marginTop: 16,
  },
  customizeActionText: { color: 'theme.text.link', padding: 8 },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  walkthroughFocusContainer: {
    borderRadius: 24,
    backgroundColor: 'theme.background',
  },
  chartContainer: {
    alignItems: 'center',
    borderWidth: 1,
    borderRadius: 12,
    borderColor: 'theme.surface.base',
    paddingVertical: 16,
  },
  progressBarContainer: {
    marginTop: 32,
    flexDirection: 'row',
    justifyContent: 'space-around',
    width: '100%',
  },
})
