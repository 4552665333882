import React, { useRef, useState } from 'react'
import { KeyboardAvoidingView, View } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { useDispatch } from 'react-redux'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Button, Input, InputRef, Text } from '@src/components/base'
import { InlineAlert } from '@src/components'
import { AppStackParamList } from '@src/navigation/types'
import { validateIntegerNumberInput } from '@src/utils/validators'
import { useSnack } from '@src/utils'
import { ErrorMessages } from '@src/config'
import { UiInlineAlert } from '@src/types'

export const RemainingSensorsQuestion = () => {
  const styles = useStyleSheet(themedStyles)
  const navigation = useNavigation<StackNavigationProp<AppStackParamList>>()
  const insets = useSafeAreaInsets()
  const showSnack = useSnack()
  const [remainingSensorsCount, setRemainingSensorsCount] = useState<number | undefined>()
  const dispatch = useDispatch()
  const inputRef = useRef<InputRef>(null)

  const onConfirmPress = () => {
    if (remainingSensorsCount === 0) {
      dispatch({
        type: 'prescriptionDexcom/activatePrescriptionDexcom',
        failure: (error: any) => {
          const errorMessage = error?.message || ErrorMessages.ServerError
          showSnack(errorMessage, null, 'error')
        },
        success: () => {
          dispatch({ type: 'users/fetch' })
          navigation.replace('FinalizePrescriptionDexcomSwitchScreen')
        },
      })

      return
    }

    if (remainingSensorsCount === undefined) {
      inputRef.current?.focus()
      return
    } else {
      navigation.replace('SwitchToDexcomNotSuccessfulScreen')
    }
  }

  const onDeclinePress = () => {
    navigation.replace('Drawer', { screen: 'Dashboard' })
  }

  return (
    <View
      style={[styles.container, { paddingTop: 32 + insets.top, paddingBottom: insets.bottom + 16 }]}
    >
      <KeyboardAvoidingView behavior="padding" style={styles.contentContainer}>
        <Text type="regular" style={styles.descriptionText} bold>
          Please confirm how many Libre sensors you have remaining
        </Text>
        <Input
          keyboardType="numeric"
          inputMode="numeric"
          ref={inputRef}
          value={remainingSensorsCount?.toString() ?? ''}
          style={styles.remainingSensorCountInput}
          onChangeText={(text) => {
            const value = validateIntegerNumberInput(text)
            if (value !== null && value.length !== 0) {
              const numericValue = Number(value)
              setRemainingSensorsCount(numericValue)
            } else {
              setRemainingSensorsCount(undefined)
            }
          }}
        />
        {remainingSensorsCount === 0 && (
          <InlineAlert
            category={UiInlineAlert.Neutral}
            message="By pressing Continue we will turn off your Libre sensor and begin Dexcom"
            style={styles.sensorDeactivationAlert}
          />
        )}
        <View style={styles.buttonsWrapper}>
          <Button
            type="primary"
            size="block"
            onPress={onConfirmPress}
            accessibilityLabel="Confirm"
            disabled={remainingSensorsCount === undefined}
          >
            Continue
          </Button>
          <Button
            type="outline"
            size="block"
            onPress={onDeclinePress}
            accessibilityLabel="Not yet"
            style={styles.secondaryButton}
          >
            Cancel
          </Button>
        </View>
      </KeyboardAvoidingView>
    </View>
  )
}

const themedStyles = StyleService.create({
  container: {
    flex: 1,
    backgroundColor: 'theme.background',
  },
  contentContainer: {
    flex: 1,
    paddingHorizontal: 32,
  },
  secondaryButton: {
    marginTop: 8,
  },
  buttonsWrapper: {
    flex: 1,
    paddingHorizontal: 16,
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
  },
  descriptionText: {
    textAlign: 'center',
  },
  remainingSensorCountInput: {
    marginTop: 24,
    width: 100,
    alignSelf: 'center',
    textAlign: 'center',
  },
  sensorDeactivationAlert: {
    marginVertical: 24,
  },
})
