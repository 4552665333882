import React, { useEffect, useState } from 'react'
import { View } from 'react-native'
import moment from 'moment'
import { useNavigation } from '@react-navigation/native'
import { useDispatch, useSelector } from 'react-redux'
import { StackNavigationProp } from '@react-navigation/stack'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Button, IconButton, Pill, Text } from '@src/components/base'
import { SuggestedAvailability } from '@src/types'
import {
  VIDEO_CALL_CARD_DATE_FORMAT,
  VIDEO_CALL_CARD_TIME_FORMAT,
} from '@src/screens/NutritionistHub/constants'
import { useGetRequiredPolicyConsents } from '@src/screens/PolicyConsents/hooks/useGetRequiredPolicyConsents'
import { useVideoCallDurations } from '@screens/NutritionistHub/hooks/useVideoCallDurations.ts'
import { AppStackParamList } from '@navigation/types'
import {
  insurancePolicySelector,
  pendingAppointmentsSelector,
} from '@screens/NutritionistHub/models/nutritionistHub.selectors.ts'
import {
  hasUnusedCalls,
  isInsuranceEligible,
  isInsuranceReviewInProgress,
} from '@screens/NutritionistHub/utils.ts'
import { CustomEventTypes, ErrorMessages } from '@config'
import { useSnack } from '@utils'
import { useNutritionistHubTracker } from '@screens/NutritionistHub/hooks.ts'

export interface SuggestedCallCardProps {
  suggestedAvailability: SuggestedAvailability
}

const SLOT_TAKEN_ERROR = 'slot is already taken'

export const SuggestedCallCard = ({ suggestedAvailability }: SuggestedCallCardProps) => {
  const navigation = useNavigation<StackNavigationProp<AppStackParamList>>()
  const styles = useStyleSheet(themedStyles)
  const { getVideoCallDuration } = useVideoCallDurations()
  const dispatch = useDispatch()
  const track = useNutritionistHubTracker()
  const showSnack = useSnack()
  const [canBookCall, setCanBookCall] = useState(false)
  const insurancePolicy = useSelector(insurancePolicySelector)
  const pendingAppointments = useSelector(pendingAppointmentsSelector)
  const { title, meetingAt, nutritionistName, appointmentType } = suggestedAvailability
  const { getRequiredPolicyConsents, loading } = useGetRequiredPolicyConsents()

  useEffect(() => {
    dispatch({ type: 'nutritionistHub/fetchPendingAppointments' })
  }, [dispatch])

  useEffect(() => {
    setCanBookCall(
      pendingAppointments.length > 0 ||
        isInsuranceReviewInProgress(insurancePolicy) ||
        (isInsuranceEligible(insurancePolicy) && hasUnusedCalls(insurancePolicy)),
    )
  }, [insurancePolicy, pendingAppointments])

  const openScheduler = () => {
    track(CustomEventTypes.NutritionistHubSuggestedCallEditTapped)

    if (canBookCall) {
      navigation.navigate('CallScheduling', {
        appointmentType,
        allowBackNavigation: true,
      })
    } else {
      navigation.navigate('VideoCallCheckout', {})
    }
  }

  const bookSuggestedCall = () => {
    track(CustomEventTypes.NutritionistHubSuggestedCallConfirmTapped)

    if (!canBookCall) {
      navigation.navigate('VideoCallCheckout', { suggestedCallFlow: true })
      return
    }

    dispatch({
      type: 'nutritionistHub/bookVideoCall',
      payload: {
        appointmentType,
        date: suggestedAvailability.meetingAt,
        coachId: suggestedAvailability.nutritionistId,
        pendingAppointmentId: pendingAppointments[0]?.id,
      },
      success: async () => {
        dispatch({ type: 'nutritionistHub/fetchUpcomingAppointments' })
        dispatch({ type: 'nutritionistHub/fetchSuggestedAvailability' })
        dispatch({ type: 'nutritionistHub/fetchInsurancePolicy' })

        const requiredPolicies = await getRequiredPolicyConsents()
        if (requiredPolicies.length > 0) {
          navigation.navigate('PolicyConsent', { policy: requiredPolicies[0] })
        }
      },
      failure: (error: Error) => {
        const errorMessage = error?.message || ErrorMessages.ServerError
        if (errorMessage.includes(SLOT_TAKEN_ERROR)) {
          navigation.navigate('CallScheduling', {
            appointmentType,
            allowBackNavigation: true,
            errorMessage:
              'The suggested appointment time is no longer available. Please choose a different time slot',
          })
        } else {
          showSnack(errorMessage, null, 'error')
        }
      },
    })
  }

  return (
    <View style={[styles.container]}>
      <Pill text="Suggested" status="info" style={styles.pill} />
      <View style={styles.titleRow}>
        <Text type="regular" bold style={styles.titleText}>
          {title || ''} with {nutritionistName?.split(' ')[0] || ''}
        </Text>
        <View style={styles.titleButtons}>
          <IconButton
            iconName="pencil-simple"
            size="m"
            type="outline"
            accessibilityLabel="Open Scheduler"
            onPress={openScheduler}
          />
          <Button
            accessibilityLabel="Book Suggested Call"
            type="primary"
            size="small"
            onPress={bookSuggestedCall}
            disabled={loading}
          >
            Schedule
          </Button>
        </View>
      </View>
      <Text type="small" style={styles.time}>
        {moment(meetingAt).format(VIDEO_CALL_CARD_DATE_FORMAT)} -{' '}
        {moment(meetingAt)
          .add(getVideoCallDuration(appointmentType), 'minutes')
          .format(VIDEO_CALL_CARD_TIME_FORMAT)}
      </Text>
    </View>
  )
}

const themedStyles = StyleService.create({
  container: {
    borderLeftWidth: 3,
    borderColor: 'theme.info.light_',
    paddingLeft: 12,
  },
  titleRow: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingVertical: 12,
    borderBottomWidth: 1,
    borderColor: 'theme.surface.base1',
  },
  titleText: {
    color: 'theme.text.tertiary',
    maxWidth: '60%',
  },
  titleButtons: {
    flexDirection: 'row',
    gap: 8,
  },
  time: {
    marginTop: 12,
    color: 'theme.text.tertiary',
  },
  pill: {
    width: 110,
  },
})
