import React from 'react'
import { View, StyleSheet } from 'react-native'
import Svg, { Rect } from 'react-native-svg'
import { useTheme } from '@ui-kitten/components'
import { Text } from '@src/components/base'

interface ProgressBarChartProps {
  value: number
  max: number
  width: number
  height?: number
  title: string
  backgroundColor?: string
  progressColor: string
}

export const ProgressBarChart = ({
  title,
  value,
  max,
  width,
  height = 8,
  backgroundColor,
  progressColor,
}: ProgressBarChartProps) => {
  const progressWidth = (Math.min(value, max) / max) * width
  const theme = useTheme()

  const targetOvershotColor =
    !!value && value > max ? theme['theme.error.base'] : theme['theme.text.primary']

  return (
    <View style={styles.container}>
      <Text type="small" style={styles.title}>
        {title}
      </Text>
      <Svg width={width} height={height}>
        <Rect
          width={width}
          height={height}
          strokeLinecap="round"
          rx={height / 2}
          fill={backgroundColor}
        />
        <Rect
          width={progressWidth}
          height={height}
          strokeLinecap="round"
          rx={height / 2}
          fill={progressColor}
        />
      </Svg>
      <View style={styles.valueContainer}>
        <Text type="regular" bold style={{ color: targetOvershotColor }}>
          {value}
        </Text>
        <Text
          type="regular"
          bold
          style={{ color: theme['theme.text.tertiary'] }}
        >{`${max} g`}</Text>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    justifyContent: 'center',
  },
  title: {
    textAlign: 'center',
  },
  valueContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
})
