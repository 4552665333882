import { UserExperienceSensorModeKind } from '@src/types'

export const OWN_SENSOR_MODES = [
  UserExperienceSensorModeKind.OwnSensorDexcomG6,
  UserExperienceSensorModeKind.OwnSensorDexcomG7,
  UserExperienceSensorModeKind.OwnSensorDexcomStelo,
  UserExperienceSensorModeKind.OwnSensorLibre1,
  UserExperienceSensorModeKind.OwnSensorLibre2,
  UserExperienceSensorModeKind.OwnSensorLibre3,
] as const

export const LIBRE_SENSORS = [
  UserExperienceSensorModeKind.OwnSensorLibre1,
  UserExperienceSensorModeKind.OwnSensorLibre2,
  UserExperienceSensorModeKind.OwnSensorLibre3,
]
export const DEXCOM_SENSORS = [
  UserExperienceSensorModeKind.OwnSensorDexcomG6,
  UserExperienceSensorModeKind.OwnSensorDexcomG7,
  UserExperienceSensorModeKind.OwnSensorDexcomStelo,
]

export const THIRD_PARTY_OWN_SENSOR_MODES = [
  UserExperienceSensorModeKind.OwnSensorLibre1,
  UserExperienceSensorModeKind.OwnSensorLibre2,
  UserExperienceSensorModeKind.OwnSensorDexcomG6,
  UserExperienceSensorModeKind.OwnSensorDexcomG7,
  UserExperienceSensorModeKind.OwnSensorDexcomStelo,
]

export const SENSOR_NAMES: Record<OwnSensorMode, string> = {
  [UserExperienceSensorModeKind.OwnSensorDexcomG6]: 'Dexcom G6',
  [UserExperienceSensorModeKind.OwnSensorDexcomG7]: 'Dexcom G7',
  [UserExperienceSensorModeKind.OwnSensorDexcomStelo]: 'Stelo',
  [UserExperienceSensorModeKind.OwnSensorLibre1]: 'FreeStyle Libre 14-Day System (Libre 1)',
  [UserExperienceSensorModeKind.OwnSensorLibre2]: 'FreeStyle Libre 2',
  [UserExperienceSensorModeKind.OwnSensorLibre3]: 'FreeStyle Libre 3',
}

export type OwnSensorMode = typeof OWN_SENSOR_MODES[number]
