import React from 'react'
import MultiSlider, { MultiSliderProps } from '@ptomasroos/react-native-multi-slider'
import { Dimensions, LayoutChangeEvent, View, ViewStyle } from 'react-native'
import { StyleService, useStyleSheet } from '@src/style/service'

const MARKER_WIDTH = 24
const INITIAL_WIDTH = Dimensions.get('screen').width - MARKER_WIDTH * 2
export interface SliderProps extends MultiSliderProps {
  wrapperStyle?: ViewStyle
  testID?: string
  trackColor?: ViewStyle
  progressColor?: ViewStyle
}

const touchDimensions: MultiSliderProps['touchDimensions'] = {
  height: 50,
  width: 80,
  borderRadius: 20,
  slipDisplacement: 40,
}

export const Slider = ({
  wrapperStyle,
  testID,
  trackColor = {},
  progressColor = {},
  ...multiSliderProps
}: SliderProps): JSX.Element => {
  const styles = useStyleSheet(themeStyle)

  const [sliderLength, setSliderLength] = React.useState(INITIAL_WIDTH)

  const onLayout = (event: LayoutChangeEvent) => {
    setSliderLength(event.nativeEvent.layout.width)
  }

  return (
    <View testID={testID} onLayout={onLayout} style={wrapperStyle}>
      <MultiSlider
        sliderLength={sliderLength}
        trackStyle={styles.trackStyle}
        markerStyle={styles.marker}
        containerStyle={styles.containerStyle}
        selectedStyle={{ ...styles.selectedTrackStyle, ...progressColor }}
        unselectedStyle={{ ...styles.unselectedTrackStyle, ...trackColor }}
        touchDimensions={touchDimensions}
        stepMarkerStyle={styles.stepMarker}
        // @ts-ignore `markerSize` prop is missed in type definition
        markerSize={24}
        {...multiSliderProps}
      />
    </View>
  )
}

const themeStyle = StyleService.create({
  containerStyle: {
    height: 24,
  },
  selectedTrackStyle: {
    backgroundColor: 'theme.primary.base',
  },
  unselectedTrackStyle: {
    backgroundColor: 'theme.surface.base1',
  },
  marker: {
    backgroundColor: 'theme.solid.white',
    marginTop: 4,
    width: MARKER_WIDTH,
    height: MARKER_WIDTH,
    borderWidth: 1,
    borderColor: 'theme.primary.base',
  },
  trackStyle: {
    height: 4,
  },
  stepMarker: {
    backgroundColor: 'theme.background',
    width: 2,
    height: 4,
    borderRadius: 0,
  },
})
