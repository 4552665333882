import React from 'react'
import { View } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { DrawerNavigationHelpers } from '@react-navigation/drawer/lib/typescript/src/types'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Text } from '@components/base'
import { AccountAvatar } from '@src/components/AccountAvatar'

export interface DrawerHeaderProps {
  title: string
  imageSource: any
  navigation: DrawerNavigationHelpers
}

export const DRAWER_ACCOUNT_AVATAR_TOP_PADDING = 16

export const DrawerHeader = ({ title }: DrawerHeaderProps) => {
  const styles = useStyleSheet(headerThemedStyle)
  const insets = useSafeAreaInsets()

  return (
    <View
      style={[styles.container, { paddingTop: insets.top + DRAWER_ACCOUNT_AVATAR_TOP_PADDING }]}
    >
      <View style={styles.avatarContainer}>
        <View style={{ marginTop: 2 }}>
          <AccountAvatar />
        </View>
        <View style={styles.nameContainer}>
          <Text type="large">{title}</Text>
          <Text type="regular" style={styles.viewAccountLink}>
            View account
          </Text>
        </View>
      </View>
    </View>
  )
}

const headerThemedStyle = StyleService.create({
  container: {
    padding: 16,
  },
  avatarContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  avatar: {
    height: 48,
    width: 48,
    marginRight: 16,
    borderRadius: 24,
  },
  nameContainer: {
    flexShrink: 1,
    justifyContent: 'space-between',
  },
  viewAccountLink: {
    color: 'theme.text.secondary',
  },
})
