/* eslint-disable max-len */
import * as React from 'react'
import Svg, { Rect, Path, G, Circle, Defs, LinearGradient, Stop } from 'react-native-svg'

export const GlucoseChartPreview = () => (
  <Svg width="74" height="41" viewBox="0 0 74 41" fill="none">
    <G id="Group 102757">
      <Path id="Line 33" d="M2 39.5H72" stroke="#9DAEAE" strokeWidth="3" strokeLinecap="round" />
      <Path
        id="Line 34"
        opacity="0.5"
        d="M3 34H72"
        stroke="#9DAEAE"
        strokeLinecap="square"
        strokeDasharray="2 2"
      />
      <Path
        id="Line 35"
        opacity="0.5"
        d="M3 27H72"
        stroke="#9DAEAE"
        strokeLinecap="square"
        strokeDasharray="2 2"
      />
      <Path
        id="Line 36"
        opacity="0.5"
        d="M3 20H72"
        stroke="#9DAEAE"
        strokeLinecap="square"
        strokeDasharray="2 2"
      />
      <Path
        id="Line 37"
        opacity="0.5"
        d="M3 6H72"
        stroke="#9DAEAE"
        strokeLinecap="square"
        strokeDasharray="2 2"
      />
      <Path
        id="Line 38"
        opacity="0.5"
        d="M3 13H72"
        stroke="#9DAEAE"
        strokeLinecap="square"
        strokeDasharray="2 2"
      />
      <Rect id="Rectangle 2552" opacity="0.2" x="2" y="14" width="70" height="16" fill="#4CD471" />
      <Path
        id="Vector 16"
        d="M1.5 27.5001C4.37586 22.1668 13.8648 23.6 20 30C27.669 38 34.892 28.9997 40.1111 24.9992C44.9348 21.3019 50.869 2.00017 57.5793 17.0001C61.1209 24.9166 67.4851 25.8332 71 24.9997"
        stroke="url(#paint0_linear_4096_41543)"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <Path id="Line 32" d="M1.5 2L1.5 39" stroke="#9DAEAE" strokeWidth="3" strokeLinecap="round" />
      <Circle id="Ellipse 460" cx="52.5" cy="11.5" r="4.5" fill="#11494F" />
      <Circle id="Ellipse 461" cx="52.5" cy="11.5" r="2.5" fill="white" />
    </G>
    <Defs>
      <LinearGradient
        id="paint0_linear_4096_41543"
        x1="1.5"
        y1="19.4346"
        x2="71"
        y2="19.4346"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset="0.482181" stopColor="#FFB114" />
        <Stop offset="0.491525" stopColor="#4CD571" />
      </LinearGradient>
    </Defs>
  </Svg>
)
