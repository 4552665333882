import React from 'react'
import { StyleProp, ViewStyle } from 'react-native'
import { View } from 'react-native'
import { StyleService, useStyleSheet } from '@src/style/service'

interface ProgressSliderProps {
  progress: number
  trackColor?: StyleProp<ViewStyle>
  progressColor?: StyleProp<ViewStyle>
}

/**
 * A simple progress indicator
 *
 * @prop {number} progress - The current progress represented as a number between 0 and 1
 */
export const ProgressSlider = ({
  progress,
  trackColor = {},
  progressColor = {},
}: ProgressSliderProps) => {
  const styles = useStyleSheet(themedStyles)

  return (
    <View style={[styles.bar, styles.container, trackColor]}>
      <View
        style={[
          styles.bar,
          styles.defaultTintStyle,
          progressColor,
          { width: `${progress * 100}%` },
        ]}
      />
    </View>
  )
}

const themedStyles = StyleService.create({
  container: {
    backgroundColor: 'theme.surface.base',
    overflow: 'hidden',
  },
  bar: {
    flexGrow: 1,
    height: 8,
    borderRadius: 4,
  },
  defaultTintStyle: {
    backgroundColor: 'theme.primary.base',
  },
})
