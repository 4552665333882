import { DailyAction, UserDailyActionKind } from '@src/types'

export interface DailyActionsStoreState {
  dailyActions: DailyAction[]
  dailyActionsCompletions: DailyActionCompletion[]
  orderedActiveDailyActionKinds: UserDailyActionKind[]
  preferredHeroChart: HeroChartType
}

export interface DailyActionCompletion {
  dailyActionId: number
  completed: boolean
}

export enum HeroChartType {
  GlucoseChart = 'glucoseChart',
  DialChart = 'dialChart',
}
