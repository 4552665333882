import { gql } from '@apollo/client'

export const FETCH_EARLIER_AVAILABLE_SLOTS = gql`
  query insuranceSchedulingEarlierAvailableSlots($appointmentId: ID!) {
    insuranceSchedulingEarlierAvailableSlots(appointmentId: $appointmentId) {
      slots {
        time
        coachId
      }
    }
  }
`
