import moment from 'moment'
import { useSelector } from 'react-redux'
import { useContext, useEffect, useMemo, useRef } from 'react'
import { useNavigation } from '@react-navigation/core'
import { SensorTypes } from 'react-native-freestyle-libre'
import { useIsFocused } from '@react-navigation/native'
import { useFlagsStatus } from '@unleash/proxy-client-react'
import { AnyAction } from 'redux'
import Config from 'react-native-config'
import { sensorSelector } from '@src/selectors/sensor'
import Storage from '@src/utils/storage'
import { shouldRequestInAppReview } from '@src/utils/appReview'
import { refillConsentNeededSelector } from '@src/selectors/policyConsents'
import { useShouldShowPurchaseFlow } from '@src/utils/hooks'
import {
  ApprovalRequest,
  UserApprovalRequestState,
  Sensor,
  SurveysConfigKind,
  SurveyLinkCollection,
  SurveysQuestionKey,
  Subscription,
} from '@src/types'
import { TempAppStateContext } from '@src/context/tempAppStateContext'
import { skippedSurveysSelector, userSelector } from '@src/selectors/app'
import { User, useDispatchAsync } from '@src/utils'
import { markModalAsShown, shouldShowModalOnce } from '@src/hooks/useOneTimeVisitedState'
import { useActiveSubscriptions } from '@src/hooks/useActiveSubscriptions'
import { Feature, useFeatureFlag } from '@src/components'
import { BuildType } from '@src/config/app'
import { Device } from '@src/config'
import { useHasOwnSensorSubscription } from '@src/hooks/useHasOwnSensorSubscription'
import { useHasMedicalSubscription } from '@src/hooks/useHasMedicalSubscription'
import { useGetRequiredPolicyConsents } from '@src/screens/PolicyConsents/hooks/useGetRequiredPolicyConsents'
import {
  useIsByosLinkupSensorMode,
  useIsByosScannableSensorMode,
} from '@src/screens/OwnSensorOnboarding/hooks'
import { useHomeScreenWalkthrough } from '@src/context/homeScreenWalkthroughContext'
import { useShowScanDisableAnnouncement } from '@src/screens/Scans/hooks/useShowScanDisableAnnouncement'

const getStoreReviewPromptStateInteractions = ({ sensor }: { sensor: Sensor | undefined }) => ({
  shouldShow: () => {
    const lastPromptDate = Storage.get<string>(Storage.LAST_REVIEW_PROMPT_DATE_KEY)
    const hasLeftReview = Storage.get(Storage.REVIEW_LEFT_KEY, false)
    const reviewPromptsCount = Storage.get(Storage.REVIEW_PROMPTS_COUNT_KEY, 0)

    return shouldRequestInAppReview({
      hasLeftReview,
      reviewPromptsCount,
      lastPromptDate,
      sensorExpirationTime: sensor?.expirationTime,
    })
  },

  markAsShown: () => {
    const reviewPromptsCount = Storage.get(Storage.REVIEW_PROMPTS_COUNT_KEY, 0)
    Storage.set(Storage.LAST_REVIEW_PROMPT_DATE_KEY, moment().toISOString())
    Storage.set(Storage.REVIEW_PROMPTS_COUNT_KEY, reviewPromptsCount + 1)
  },
})

const getSensorInfoModalStateInteractions = (sensor: Sensor | undefined) => {
  const isLibreUS = sensor?.model === SensorTypes.LibreUS
  const sensorSerialNumber = sensor?.serialNumber
  const daysAfterActivation = sensor?.activationTime
    ? moment().diff(moment(sensor.activationTime), 'days')
    : 0

  return {
    shouldShow: () =>
      isLibreUS &&
      !!sensorSerialNumber &&
      daysAfterActivation >= 1 &&
      shouldShowModalOnce(Storage.SENSOR_INFO_MODAL_VISITED_KEY),
    markAsShown: () => markModalAsShown(Storage.SENSOR_INFO_MODAL_VISITED_KEY),
  }
}

const getScanDisableAnnouncementStateInteractions = (
  showScanDisableAnnouncement: boolean,
  userId?: string,
) => {
  return {
    shouldShow: () => {
      return (
        showScanDisableAnnouncement &&
        !!userId &&
        shouldShowModalOnce(`${Storage.SCAN_DISABLE_ANNOUNCEMENT_VISITED_KEY}_${userId}`)
      )
    },
    markAsShown: () =>
      markModalAsShown(`${Storage.SCAN_DISABLE_ANNOUNCEMENT_VISITED_KEY}_${userId}`),
  }
}

export const getPermissionsModalStateInteractions = () => {
  return {
    shouldShow: () => shouldShowModalOnce(Storage.PERMISSIONS_MODAL_VISITED_KEY) && !Device.web,
    markAsShown: () => markModalAsShown(Storage.PERMISSIONS_MODAL_VISITED_KEY),
  }
}

export const getHealthPermissionsModalStateInteractions = () => {
  return {
    shouldShow: () => shouldShowModalOnce(Storage.HEALTH_PERMISSIONS_MODAL_VISITED_KEY),
    markAsShown: () => markModalAsShown(Storage.HEALTH_PERMISSIONS_MODAL_VISITED_KEY),
  }
}

const getHealthQuestionnaireApprovalModalStateInteractions = ({
  approvalRequest,
  healthQuestionnaireWasSkipped,
}: {
  approvalRequest: Pick<ApprovalRequest, 'id' | 'state'> | null | undefined
  healthQuestionnaireWasSkipped?: boolean
}) => {
  const asyncStorageKey = `${Storage.HEALTH_QUESTIONNAIRE_APPROVAL_MODAL_VISITED_KEY}_${approvalRequest?.id}`
  return {
    shouldShow: () =>
      !healthQuestionnaireWasSkipped &&
      !!approvalRequest &&
      (approvalRequest.state === UserApprovalRequestState.PendingApproval ||
        approvalRequest.state === UserApprovalRequestState.PendingRejection) &&
      shouldShowModalOnce(asyncStorageKey),
    markAsShown: () => markModalAsShown(asyncStorageKey),
  }
}

const DELAY_TIME_IN_MILLISECONDS = 72 * 60 * 60 * 1000 // 72 hours in milliseconds
const TESTING_DELAY_TIME_IN_MILLISECONDS = 5 * 60 * 1000 // 5 minutes in milliseconds

const shouldShowGoalsQuestionnaireModal = async (
  dispatchAsync: <T>(action: AnyAction) => Promise<T>,
  isImpersonating: boolean,
  userId?: string,
) => {
  const MAX_GOALS_QUESTIONNAIRE_MODAL_DISPLAY_COUNT = 1

  const goalsQuestionnaireAsyncStorageKey = `${Storage.GOALS_QUESTIONNAIRE_MODAL_DISPLAY_COUNT_KEY}_${userId}`

  if (isImpersonating) {
    return false
  }

  const goalsQuestionnaireModalDisplayCount = Storage.get(goalsQuestionnaireAsyncStorageKey, 0)

  if (goalsQuestionnaireModalDisplayCount >= MAX_GOALS_QUESTIONNAIRE_MODAL_DISPLAY_COUNT) {
    // we've already shown this modal MAX_GOALS_QUESTIONNAIRE_MODAL_DISPLAY_COUNT times
    return false
  }

  const { surveyLinks }: SurveyLinkCollection = await dispatchAsync({
    type: 'app/surveyQuestionnaire',
  })

  const surveyLink = surveyLinks.find((link) => link.survey.kind === SurveysConfigKind.Goals)

  if (surveyLink?.finished) {
    // goals questionnaire is filled
    return false
  }

  if (!surveyLink) {
    const firstLogin = Storage.get<string>(Storage.APP_FIRST_LOGIN_TIMESTAMP_KEY)

    // use a shorter delay for development builds
    const build = Config.ENV as BuildType
    const delay =
      build === 'production' ? DELAY_TIME_IN_MILLISECONDS : TESTING_DELAY_TIME_IN_MILLISECONDS

    if (!firstLogin || delay > moment().diff(firstLogin)) {
      return false
    }

    // filling goals questionnaire for the first time
    Storage.set(goalsQuestionnaireAsyncStorageKey, goalsQuestionnaireModalDisplayCount + 1)
    return true
  }

  return false
}

const VIDEO_CALLS_UPSELL_MODAL_DELAY_DAYS = 3
const getVideoCallsUpsellRouteParams = async ({
  userId,
  dispatchAsync,
  lastCoreSubscription,
  isEligibleVideoCallUpsellSensorMode,
  videoCallFunnelOptimizationEnabled,
}: {
  userId?: string
  dispatchAsync: <T>(action: AnyAction) => Promise<T>
  lastCoreSubscription?: Pick<Subscription, 'id' | 'subscribedAt'> | null
  isEligibleVideoCallUpsellSensorMode: boolean
  videoCallFunnelOptimizationEnabled: boolean
}) => {
  if (!userId) {
    return
  }

  const surveyParams = {
    name: 'Questionnaire',
    params: { questionnaire: SurveysConfigKind.Insurance, allowEarlyExit: true },
  }
  const modalParams = { name: 'VideoCallsUpsellModal' }

  const { surveyLinks }: SurveyLinkCollection = await dispatchAsync({
    type: 'app/surveyQuestionnaire',
  })

  const insuranceSurveyLink = surveyLinks.find(
    (link) => link.survey.kind === SurveysConfigKind.Insurance,
  )

  const visitedRecentSubscriptionKey = `${Storage.VIDEO_CALLS_UPSELL_MODAL_VISITED_RECENT_SUBSCRIPTION}_${userId}`
  const visitedDueToRecentSubscription = Storage.get(visitedRecentSubscriptionKey, false)
  const modalVisitedCountKey = `${Storage.VIDEO_CALLS_UPSELL_MODAL_VISITED_COUNT_KEY}_${userId}`
  const modalVisitedCount = Storage.get(modalVisitedCountKey, 0)

  if (
    !insuranceSurveyLink &&
    isEligibleVideoCallUpsellSensorMode &&
    modalVisitedCount === 0 &&
    !visitedDueToRecentSubscription
  ) {
    Storage.set(visitedRecentSubscriptionKey, true)
    return surveyParams
  }

  if (!videoCallFunnelOptimizationEnabled) {
    return
  }

  const firstLogin = Storage.get<string>(`${Storage.APP_FIRST_LOGIN_TIMESTAMP_KEY}_${userId}`)
  if (firstLogin && moment().diff(firstLogin, 'days') < VIDEO_CALLS_UPSELL_MODAL_DELAY_DAYS) {
    return
  }

  if (lastCoreSubscription && !visitedDueToRecentSubscription) {
    const oneWeekAgo = moment().subtract(1, 'week')
    const subscribedAt = moment(lastCoreSubscription.subscribedAt)

    if (subscribedAt.isSameOrAfter(oneWeekAgo)) {
      if (!insuranceSurveyLink) {
        Storage.set(visitedRecentSubscriptionKey, true)
        return modalParams
      }

      const earlyExitReason = insuranceSurveyLink.responses?.find(
        (response) => response.questionKey === SurveysQuestionKey.InsuranceEarlyExitReason,
      )

      if (earlyExitReason?.answer.value !== 'no_insurance') {
        Storage.set(visitedRecentSubscriptionKey, true)
        return modalParams
      }
    }
  }

  if (modalVisitedCount > 0) {
    return
  }

  if (insuranceSurveyLink && insuranceSurveyLink.finished) {
    // questionnaire is filled
    return
  }

  const insuranceSkipSurveyLink = surveyLinks.find(
    (link) => link.survey.kind === SurveysConfigKind.InsuranceEarlyExit,
  )

  if (
    insuranceSkipSurveyLink?.responses?.find(
      (response) => response.questionKey === SurveysQuestionKey.InsuranceEarlyExitReason,
    )?.answer?.value === 'no_insurance'
  ) {
    return
  }

  return modalParams
}

const shouldShowMedicalDisclaimerSurvey = async ({
  isImpersonating,
  dispatchAsync,
}: {
  isImpersonating: boolean
  userId?: string
  dispatchAsync: <T>(action: AnyAction) => Promise<T>
}) => {
  if (isImpersonating) {
    return false
  }

  const { surveyLinks }: SurveyLinkCollection = await dispatchAsync({
    type: 'app/surveyQuestionnaire',
  })

  const medicalDisclaimerSurveyLink = surveyLinks.find(
    (link) => link.survey.kind === SurveysConfigKind.MedicalDisclaimer,
  )

  if (!medicalDisclaimerSurveyLink) {
    return true
  }

  if (medicalDisclaimerSurveyLink.finished) {
    return false
  }

  return true
}

export const useOnboardingModals = () => {
  const user = useSelector(userSelector)
  const isFocused = useIsFocused()
  const { flagsReady, flagsError } = useFlagsStatus()
  const flagsLoaded = flagsReady || flagsError
  const videoCallFunnelOptimizationEnabled = useFeatureFlag(Feature.VideoCallFunnelOptimization)
  const markedNotificationPermissionsShown = useRef(false)
  const medicalDisclaimerSurveyEnabled = useFeatureFlag(Feature.MedicalDisclaimerSurvey)
  const isByosScannableSensorMode = useIsByosScannableSensorMode()
  const isByosLinkupSensorMode = useIsByosLinkupSensorMode()
  const showScanDisableAnnouncement = useShowScanDisableAnnouncement()
  const isEligibleVideoCallUpsellSensorMode = isByosScannableSensorMode || isByosLinkupSensorMode

  const skippedSurveys = useSelector(skippedSurveysSelector)
  const {
    shouldShow: showHomeWalkthroughModal,
    walkthroughInProgress: homeWalkthroughInProgress,
  } = useHomeScreenWalkthrough()

  const {
    tempAppState: { goalsQuestionnaireWasShown },
    setTempAppState: markGoalsQuestionnaireAsShown,
  } = useContext(TempAppStateContext)

  const navigation = useNavigation()
  const dispatchAsync = useDispatchAsync()

  const refillConsentNeeded = useSelector(refillConsentNeededSelector)
  const shouldShowPurchaseFlow = useShouldShowPurchaseFlow()

  const sensor = useSelector(sensorSelector)

  const activeSubscriptions = useActiveSubscriptions()

  const showRefillConsentModal = refillConsentNeeded && !shouldShowPurchaseFlow

  const subscriptionIncludesCgm = activeSubscriptions.some(
    (subscription) => subscription.primaryProduct.includesCgm,
  )
  const lastCoreSubscription = user?.lastCoreSubscription as Subscription

  const hasOwnSensorSubscription = useHasOwnSensorSubscription()
  const hasMedicalSubscription = useHasMedicalSubscription()
  const userId = user?.id

  const healthQuestionnaireWasSkipped = skippedSurveys[SurveysConfigKind.Health]

  const {
    shouldShow: shouldShowStoreReviewPrompt,
    markAsShown: markStoreReviewPromptAsShown,
  } = useMemo(() => getStoreReviewPromptStateInteractions({ sensor }), [sensor])

  const {
    shouldShow: shouldShowSensorInfoModal,
    markAsShown: markSensorInfoModalAsShown,
  } = useMemo(() => getSensorInfoModalStateInteractions(sensor), [sensor])

  const {
    shouldShow: shouldShowPermissionsModal,
    markAsShown: markPermissionsModalAsShown,
  } = useMemo(() => getPermissionsModalStateInteractions(), [])

  const {
    shouldShow: shouldShowHealthQuestionnaireApprovalModal,
    markAsShown: markHealthQuestionnaireApprovalModalAsShown,
  } = useMemo(
    () =>
      getHealthQuestionnaireApprovalModalStateInteractions({
        approvalRequest: user?.lastPrescriptionApprovalRequest,
        healthQuestionnaireWasSkipped,
      }),
    [healthQuestionnaireWasSkipped, user?.lastPrescriptionApprovalRequest],
  )

  const {
    shouldShow: shouldShowScanDisableAnnouncement,
    markAsShown: markScanDisableAnnouncementAsShown,
  } = useMemo(
    () => getScanDisableAnnouncementStateInteractions(showScanDisableAnnouncement, userId),
    [showScanDisableAnnouncement, userId],
  )

  const { getRequiredPolicyConsents } = useGetRequiredPolicyConsents()

  useEffect(() => {
    const handleModals = async () => {
      if (!flagsLoaded || !isFocused) {
        return
      }

      // using the useImpersonationContext leads to a race condition that shows the welcome tour during impersonation
      const isImpersonating = User.isImpersonating()

      if (isImpersonating) {
        return
      }

      const getRoutes = async () => {
        const routes: { name: string; params?: any }[] = []

        // keep it here as we do not want it to bleed through other modals
        if (showHomeWalkthroughModal && !homeWalkthroughInProgress) {
          routes.push({ name: 'HomeScreenWalkthroughModal' })
          return routes
        }

        if (medicalDisclaimerSurveyEnabled && hasMedicalSubscription) {
          const shouldShowMedicalDisclaimer = await shouldShowMedicalDisclaimerSurvey({
            isImpersonating,
            dispatchAsync,
          })

          if (shouldShowMedicalDisclaimer) {
            routes.push({
              name: 'Questionnaire',
              params: { questionnaire: SurveysConfigKind.MedicalDisclaimer },
            })
          }
        }

        const showStoreReviewPrompt = shouldShowStoreReviewPrompt()

        if (showStoreReviewPrompt) {
          routes.push({ name: 'RateOurAppModal' })
          markStoreReviewPromptAsShown()
        }

        const insuranceUpsellRouteParams = await getVideoCallsUpsellRouteParams({
          userId,
          dispatchAsync,
          lastCoreSubscription,
          isEligibleVideoCallUpsellSensorMode,
          videoCallFunnelOptimizationEnabled,
        })

        if (insuranceUpsellRouteParams) {
          routes.push(insuranceUpsellRouteParams)
          const visitedCountKey = `${Storage.VIDEO_CALLS_UPSELL_MODAL_VISITED_COUNT_KEY}_${userId}`
          Storage.set(visitedCountKey, 1)
        }

        const showSensorInfoModal = shouldShowSensorInfoModal()

        if (showSensorInfoModal) {
          routes.push({ name: 'SensorInfoModal' })
          markSensorInfoModalAsShown()
        }
        if (showRefillConsentModal) {
          routes.push({ name: 'RefillConsent' })
        }

        const showGoalsQuestionnaireModal = await shouldShowGoalsQuestionnaireModal(
          dispatchAsync,
          isImpersonating,
          userId,
        )
        if (showGoalsQuestionnaireModal) {
          routes.push({
            name: 'Questionnaire',
            params: { questionnaire: SurveysConfigKind.Goals },
          })
        }

        const showPermissionsModal = shouldShowPermissionsModal()

        if (showPermissionsModal && !markedNotificationPermissionsShown.current) {
          // preventing a race condition where the modal is shown twice
          markedNotificationPermissionsShown.current = true
          routes.push({ name: 'PermissionsCarousel' })
        }

        if (shouldShowScanDisableAnnouncement()) {
          routes.push({ name: 'ScanDisableAnnouncement' })
          markScanDisableAnnouncementAsShown()
        }

        const showHealthQuestionnaireApprovalModal = shouldShowHealthQuestionnaireApprovalModal()

        if (showHealthQuestionnaireApprovalModal) {
          routes.push({ name: 'HQApproval' })
          markHealthQuestionnaireApprovalModalAsShown()
        }

        // Routes will be pushed in reverse order and we always want the consent modals to show first if present.
        // So, this should be the last item added to the stack.
        //
        const requiredPolicies = await getRequiredPolicyConsents()
        if (requiredPolicies.length > 0) {
          routes.push({ name: 'RequiredPolicyConsent' })
        }

        return routes
      }

      const routes = await getRoutes()

      routes.forEach(({ name, params }) => {
        navigation.navigate(name as any, params)
      })
    }

    handleModals()
  }, [
    videoCallFunnelOptimizationEnabled,
    medicalDisclaimerSurveyEnabled,
    dispatchAsync,
    flagsLoaded,
    goalsQuestionnaireWasShown,
    lastCoreSubscription,
    hasOwnSensorSubscription,
    hasMedicalSubscription,
    isFocused,
    markGoalsQuestionnaireAsShown,
    markHealthQuestionnaireApprovalModalAsShown,
    markPermissionsModalAsShown,
    markSensorInfoModalAsShown,
    markStoreReviewPromptAsShown,
    navigation,
    shouldShowHealthQuestionnaireApprovalModal,
    shouldShowPermissionsModal,
    shouldShowSensorInfoModal,
    shouldShowStoreReviewPrompt,
    showRefillConsentModal,
    subscriptionIncludesCgm,
    userId,
    getRequiredPolicyConsents,
    isByosScannableSensorMode,
    showHomeWalkthroughModal,
    homeWalkthroughInProgress,
    shouldShowScanDisableAnnouncement,
    markScanDisableAnnouncementAsShown,
    isEligibleVideoCallUpsellSensorMode,
  ])
}
