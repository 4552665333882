import React, { ReactNode } from 'react'
import { useNavigation } from '@react-navigation/native'
import { ViewStyle } from 'react-native'
import { NavigationContainer } from '@src/screens/Common/containers'
import { AccountAvatar } from '@src/components/AccountAvatar'
import { NavigationBarProps } from '@src/components/navigationBar/NavigationBar'
import { useWebPresentationModeLogoutIcon } from '@src/hooks/useWebPresentationModeLogoutIcon'

interface AccountAvatarNavigationBarProps {
  children: ReactNode | ReactNode[]
  title?: string | ReactNode
  hideLeftIcon?: boolean
  rightAccessories?: NavigationBarProps['rightAccessories']
  style?: ViewStyle
  navigationBarProps?: Omit<NavigationBarProps, 'setHeaderHeight'>
}

export const AccountAvatarNavigationBar = ({
  children,
  title,
  hideLeftIcon,
  rightAccessories,
  style,
  navigationBarProps = {},
}: AccountAvatarNavigationBarProps) => {
  const navigation = useNavigation()
  const webPresentationModeLogoutIcon = useWebPresentationModeLogoutIcon()

  const goBack = () => {
    navigation.navigate('Account')
  }

  const props = hideLeftIcon
    ? null
    : {
        leftIcon: webPresentationModeLogoutIcon || <AccountAvatar />,
        goBack,
      }

  return (
    <NavigationContainer
      showLoadingIndicator
      title={title}
      rightAccessories={rightAccessories}
      style={style}
      navigationBarProps={navigationBarProps}
      allowBackNavigation={!hideLeftIcon}
      {...props}
    >
      {children}
    </NavigationContainer>
  )
}
