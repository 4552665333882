import React from 'react'
import { TouchableOpacity, ViewStyle, TextStyle, View, StyleProp } from 'react-native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { IconName, Icon } from '@components/base'
import { Text } from '@components/base'

interface TouchableSectionProps {
  title: string
  subtitle?: string
  labelStyle?: StyleProp<TextStyle>
  subtitleStyle?: StyleProp<TextStyle>
  style?: StyleProp<ViewStyle>
  iconName?: IconName
  tagComponent?: React.ReactNode
  accessoryLeft?: React.ReactNode
  showRightArrow?: boolean
  children?: React.ReactNode
  onPress?: () => void
  disabled?: boolean
}

export const TouchableSection = (props: TouchableSectionProps) => {
  const {
    style,
    labelStyle,
    subtitleStyle,
    subtitle,
    iconName,
    title,
    children,
    showRightArrow = false,
    onPress,
    disabled = false,
    tagComponent,
    accessoryLeft,
  } = props
  const styles = useStyleSheet(themedStyle)

  let leftAccessory = null

  if (accessoryLeft) {
    leftAccessory = accessoryLeft
  } else if (iconName) {
    leftAccessory = <Icon name={iconName} style={styles.icon} />
  }

  return (
    <TouchableOpacity
      disabled={disabled}
      style={[styles.container, style]}
      activeOpacity={onPress ? 0.65 : 1}
      accessibilityLabel={title}
      onPress={onPress}
    >
      <View style={styles.left}>
        {leftAccessory}
        <View>
          <Text type="regular" bold lineSpacing="tight" style={[styles.titleLabel, labelStyle]}>
            {title}
          </Text>
          {!!subtitle && (
            <Text type="regular" lineSpacing="tight" style={[styles.subtitle, subtitleStyle]}>
              {subtitle}
            </Text>
          )}
        </View>
      </View>
      {children}
      <View style={styles.left}>
        {tagComponent}
        {showRightArrow && <Icon name="caret-right" />}
      </View>
    </TouchableOpacity>
  )
}

const themedStyle = StyleService.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 64,
    paddingHorizontal: 16,
  },
  left: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  icon: {
    marginRight: 12,
  },
  titleLabel: {
    minWidth: 128,
  },
  subtitle: {
    marginTop: 4,
  },
})
