import React from 'react'
import { TouchableOpacity, View } from 'react-native'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { useNavigation } from '@react-navigation/native'
import { ServerError } from '@apollo/client'
import { Icon, Text, UI_ICONS_MAPPING } from '@src/components/base'
import { StyleService, useStyleSheet } from '@src/style/service'
import { DailyAction } from '@src/types'
import { useSnack } from '@src/utils/navigatorContext'
import { CustomEventTypes } from '@src/config/analytics'
import { Analytics } from '@src/config'
import { Haptic } from '@src/utils'
import { statsSelector } from '../Insights/models/insights.selectors'
import { DailyActionCardInteraction } from './DailyActionCardInteraction'

export interface DailyActionCardProps {
  dailyAction: DailyAction & { completed: boolean }
}

export const DailyActionCard = ({ dailyAction }: DailyActionCardProps) => {
  const styles = useStyleSheet(themedStyle)
  const dispatch = useDispatch()
  const showSnack = useSnack()
  const navigation = useNavigation()
  const stats = useSelector(statsSelector)

  const iconName = UI_ICONS_MAPPING[dailyAction.icon]

  const navigateToStat = () => {
    const stat = stats.find((stat) => stat.meta.key === dailyAction.statKey)
    if (!stat) {
      return
    }

    Analytics.track(CustomEventTypes.HomeScreenNavigateToTrendsTapped, {
      actionKey: dailyAction.kind,
      statKey: dailyAction.statKey,
    })
    navigation.navigate('InsightsDetailedPage', { statistic: stat })
  }

  const toggleCompleted = () => {
    const type = dailyAction.completed ? 'dailyActions/markIncomplete' : 'dailyActions/markComplete'

    Haptic.heavyTap()

    dispatch({
      type,
      payload: { dailyActionId: dailyAction.id },
      failure: (error: ServerError) => {
        showSnack(error.message)
      },
    })

    Analytics.track(CustomEventTypes.HomeScreenDailyActionToggleTapped, {
      actionKey: dailyAction.kind,
      completed: !dailyAction.completed,
    })
  }

  const card = (
    <View style={styles.container}>
      {iconName && (
        <View style={styles.icon}>
          <View style={styles.iconCircleBackground} />
          <Icon name={iconName} style={styles[dailyAction.category]} />
        </View>
      )}
      <View style={styles.body}>
        <Text type="regular" bold style={styles.title} lineSpacing="tight">
          {dailyAction.title}
        </Text>
        <Text type="small" style={styles.subTitle} lineSpacing="tight">
          {dailyAction.description}
        </Text>
      </View>
      <DailyActionCardInteraction
        dailyAction={dailyAction}
        onPress={dailyAction.statKey ? navigateToStat : toggleCompleted}
      />
    </View>
  )
  const pressableCard = (
    <TouchableOpacity onPress={navigateToStat} accessibilityLabel={dailyAction.title}>
      {card}
    </TouchableOpacity>
  )

  return dailyAction.statKey ? pressableCard : card
}

const themedStyle = StyleService.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    borderRadius: 12,
    borderWidth: 1,
    borderColor: 'theme.surface.base',
    backgroundColor: 'theme.background.modal',
    padding: 12,
    marginBottom: 12,
    alignItems: 'center',
  },
  body: {
    flex: 5,
    marginRight: 20,
  },
  title: {
    marginBottom: 4,
  },
  subTitle: {
    color: 'theme.text.secondary',
  },
  iconCircleBackground: {
    borderRadius: 100,
    backgroundColor: 'theme.surface.base3',
    width: 40,
    height: 40,
    position: 'absolute',
  },
  icon: { flex: 1, marginRight: 12, justifyContent: 'center', alignItems: 'center' },
  nutrition: {
    color: 'theme.success.base',
  },
  activity: {
    color: 'theme.info.base',
  },
  experiment: {
    color: 'theme.warning.base',
  },
  journal: {
    color: 'theme.accent.brick',
  },
  trends: {
    color: 'theme.primary.base',
  },
})
