import React from 'react'
import { TouchableOpacity, View } from 'react-native'
import { Radio, Text } from '@src/components/base'
import { StyleService, useStyleSheet } from '@src/style/service'
import { DialChartPreview } from '@src/assets/svgs/dialChartPreview'
import { GlucoseChartPreview } from '@src/assets/svgs/glucoseChartPreview'
import { MobileAppFeature } from '@src/types'
import { User } from '@src/utils'
import { HeroChartType } from '../models/dailyActions.types'

export interface ChartTypePreviewCardProps {
  title: string
  content: string
  onPress: () => void
  checked: boolean
  chartType: HeroChartType
}

export const ChartTypePreviewCard = ({
  title,
  content,
  onPress,
  checked,
  chartType,
}: ChartTypePreviewCardProps) => {
  const styles = useStyleSheet(themedStyle)
  const disabled = !User.hasFeature(MobileAppFeature.ViewGlucoseChart)

  const handlePress = disabled ? undefined : onPress

  return (
    <TouchableOpacity
      disabled={disabled}
      onPress={handlePress}
      accessibilityLabel={`${title} Card Preview`}
      style={[styles.container, disabled && styles.disabledContainer]}
    >
      <View style={[styles.innerContainer, !disabled && checked ? styles.selected : {}]}>
        <View style={styles.contentContainer}>
          {chartType === HeroChartType.GlucoseChart ? (
            <GlucoseChartPreview />
          ) : (
            <DialChartPreview />
          )}
          <Text type="regular" bold>
            {title}
          </Text>
          <Text type="small">{content}</Text>
        </View>
        <Radio checked={disabled ? false : checked} style={styles.radio} onPress={handlePress} />
      </View>
    </TouchableOpacity>
  )
}

const themedStyle = StyleService.create({
  disabledContainer: {
    opacity: 0.3,
  },
  container: {
    flex: 1,
  },
  disabled: {
    backgroundColor: 'theme.surface.base',
    borderRadius: 16,
  },
  innerContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    flex: 1,
    borderWidth: 1,
    borderColor: 'theme.surface.base',
    borderRadius: 16,
    padding: 16,
  },
  selected: {
    borderColor: 'theme.primary.base',
  },
  contentContainer: {
    flex: 1,
  },
  radio: {
    alignSelf: 'flex-start',
  },
})
