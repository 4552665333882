import React, { Pressable } from 'react-native'
import { AnimatedCheckbox, Icon } from '@components/base'
import { DailyAction } from '@src/types'

export interface DailyActionCardInteractionProps {
  dailyAction: DailyAction & { completed: boolean }
  onPress: () => void
}

export const DailyActionCardInteraction = ({
  dailyAction,
  onPress,
}: DailyActionCardInteractionProps) => {
  if (dailyAction.statKey) {
    return (
      <Pressable accessibilityLabel="navigateToStat" onPress={onPress} hitSlop={10}>
        <Icon name="caret-right" size={28} />
      </Pressable>
    )
  }

  return (
    <AnimatedCheckbox
      checked={dailyAction.completed}
      accessibilityLabel="toggleCompleted"
      onChange={onPress}
      hitSlop={10}
    />
  )
}
