import React from 'react'
import { View } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { Button, Icon, Text } from '@src/components/base'
import { StyleService, useStyleSheet } from '@src/style/service'
import { useIsByosLinkupSensorMode } from '@src/screens/OwnSensorOnboarding/hooks'

export interface ByosIntegrationWarningProps {
  thirdPartyWarning: boolean
  nativeWarning: boolean
}

export const ByosIntegrationWarning = ({
  thirdPartyWarning,
  nativeWarning,
}: ByosIntegrationWarningProps) => {
  const styles = useStyleSheet(themedStyles)
  const navigation = useNavigation()
  const isByosLinkupSensorMode = useIsByosLinkupSensorMode()

  if (!thirdPartyWarning && !nativeWarning) {
    return null
  }

  const iconName = nativeWarning ? 'warning' : 'wifi-x'
  const titleText = nativeWarning ? 'Not Active' : 'No Connection'
  const bodyText = nativeWarning
    ? 'Activate your sensor to get optimal glucose control and better health!'
    : 'Integrate your sensor with Nutrisense to review your data'
  const buttonText = nativeWarning ? 'Activate Sensor' : 'Connect Sensor'

  const onButtonPress = () => {
    if (nativeWarning) {
      navigation.navigate('AddScan', { action: 'activate' })
      return
    }

    if (isByosLinkupSensorMode) {
      return navigation.navigate('LibreLinkupIntegration')
    }

    navigation.navigate('CheckConnection')
  }

  return (
    <View style={styles.container}>
      <View style={styles.background} />
      <View style={styles.contentContainer}>
        <View style={styles.header}>
          <Icon name={iconName} />
          <Text type="regular" bold style={styles.textHeader}>
            {titleText}
          </Text>
        </View>
        <Text type="regular" style={styles.textBody}>
          {bodyText}
        </Text>
        <Button
          type="primary"
          size="small"
          style={styles.button}
          onPress={onButtonPress}
          accessibilityLabel="Next"
        >
          {buttonText}
        </Button>
      </View>
    </View>
  )
}

const themedStyles = StyleService.create({
  container: {
    position: 'absolute',
    top: '30%',
    left: '50%',
    width: 244,
    transform: [{ translateX: -120 }, { translateY: -50 }],
    height: 188,
  },
  background: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    opacity: 0.4,
    borderRadius: 24,
    backgroundColor: 'theme.accent.citron',
  },
  contentContainer: {
    alignItems: 'center',
    justifyContent: 'space-between',
    flex: 1,
    marginHorizontal: 16,
  },
  header: {
    marginTop: 16,
    alignItems: 'center',
    flexDirection: 'row',
  },
  textHeader: {
    textAlign: 'center',
    marginLeft: 4,
    color: 'theme.text.primary',
  },
  textBody: {
    textAlign: 'center',
    marginBottom: 12,
    color: 'theme.text.primary',
  },
  button: {
    marginBottom: 16,
  },
})
