import React, { useEffect, useState } from 'react'
import { View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigation, useRoute } from '@react-navigation/core'
import { SafeAreaView } from 'react-native-safe-area-context'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Button, Text, Toggle } from '@components/base'
import { Section } from '@src/components'
import { libreLinkupConnectionSelector } from '@src/selectors/integrations'
import { useSnack } from '@src/utils/navigatorContext'
import { LibreLinkupConnectionStatus, UiStateNames } from '@src/types'
import { AppRouteProp } from '@src/navigation/types'
import { NavigationContainer } from '@src/screens/Common/containers'
import { useShouldShowByosOnboarding } from '@src/screens/OwnSensorOnboarding/hooks'
import { useDispatchAsync } from '@src/utils'
import LastSyncedSection from './LastSyncedSection'

export const LibreLinkupIntegration = () => {
  const styles = useStyleSheet(themedStyles)
  const navigation = useNavigation()
  const route = useRoute<AppRouteProp<'LibreLinkupIntegration'>>()
  const { cancel: isDeleteConfirmed } = route.params || {}

  const dispatch = useDispatch()
  const dispatchAsync = useDispatchAsync()
  const showSnack = useSnack()
  const linkupConnection = useSelector(libreLinkupConnectionSelector)
  const [connectionActive, setConnectionActive] = useState(linkupConnection?.active || false)
  const isByosOnboarding = useShouldShowByosOnboarding()

  const confirmSyncDelete = () => {
    navigation.navigate('CancelModal', {
      title: `Are you sure you want to delete synchronization with FreeStyle Libre?`,
      cancelText: 'No, Keep it synced',
      confirmText: 'Yes, delete synchronization',
      parentScreen: 'LibreLinkupIntegration',
    })
  }

  useEffect(() => {
    if (isDeleteConfirmed) {
      setConnectionActive(false)
      dispatch({
        type: 'settings/disableLibreLinkupConnection',
        success: () => {
          showSnack('FreeStyle Libre sync turned off')
        },
        failure: () => {
          setConnectionActive(true)
        },
      })
    }
  }, [dispatch, setConnectionActive, showSnack, isDeleteConfirmed])

  useEffect(() => {
    dispatch({
      type: 'settings/fetchLibreLinkupConnection',
    })
  }, [dispatch])

  useEffect(() => {
    setConnectionActive(!!linkupConnection?.active)
  }, [linkupConnection])

  const linkupTogglePress = () => {
    if (connectionActive) {
      confirmSyncDelete()
    } else {
      navigation.navigate('LibreAuth')
    }
  }

  const onSkipPress = async () => {
    await dispatchAsync({
      type: 'app/upsertUiState',
      payload: {
        name: UiStateNames.OwnSensorOnboardingCompleted,
        value: true,
      },
    })
  }

  const hideConnectingText =
    !linkupConnection ||
    linkupConnection.status !== LibreLinkupConnectionStatus.ConnectionAuthorized

  return (
    <NavigationContainer title="FreeStyle Libre" showLoadingIndicator>
      <SafeAreaView edges={['bottom']} style={styles.container}>
        <View style={styles.container}>
          <Section title="FreeStyle Libre">
            <Toggle checked={connectionActive} onChange={linkupTogglePress} />
          </Section>
          {!hideConnectingText && (
            <Text type="small" style={styles.connectionInProgress}>
              Data will appear on dashboard shortly
            </Text>
          )}
          {connectionActive &&
            linkupConnection?.status === LibreLinkupConnectionStatus.Connected && (
              <LastSyncedSection lastSynced={linkupConnection?.lastSyncedAt} />
            )}
        </View>
        <View style={styles.buttonsContainer}>
          {isByosOnboarding && (
            <Button
              type="outline"
              size="block"
              onPress={onSkipPress}
              style={styles.skipButton}
              accessibilityLabel="Skip"
            >
              Skip for Now
            </Button>
          )}
        </View>
      </SafeAreaView>
    </NavigationContainer>
  )
}

const themedStyles = StyleService.create({
  container: {
    flexGrow: 1,
    backgroundColor: 'theme.background',
  },
  connectionInProgress: {
    marginTop: 16,
    textAlign: 'center',
    color: 'theme.text.secondary',
  },
  buttonsContainer: {
    padding: 16,
  },
  skipButton: {
    marginBottom: 8,
  },
})
