import React from 'react'
import { View } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Button, Text } from '@src/components/base'
import { ScrollViewWithFade } from '@src/components'
import { AppStackParamList } from '@src/navigation/types'

export const SwitchToDexcomNotSuccessful = () => {
  const styles = useStyleSheet(themedStyles)
  const navigation = useNavigation<StackNavigationProp<AppStackParamList>>()
  const insets = useSafeAreaInsets()

  const onConfirmPress = () => {
    navigation.replace('Drawer', { screen: 'Dashboard' })
  }

  return (
    <View style={styles.container}>
      <ScrollViewWithFade scrollViewStyle={styles.scrollViewContent}>
        <Text type="regular" style={styles.text}>
          Looks like you have a few Libre sensors that you are working through. Please complete
          these Libre sensors before transitioning over to Dexcom G7.
        </Text>
        <Text type="regular" style={styles.text}>
          Come back to this banner when you are ready to move over to Dexcom G7.
        </Text>
      </ScrollViewWithFade>
      <View style={[styles.buttonsWrapper, { marginBottom: insets.bottom + 16 }]}>
        <Button type="primary" size="block" onPress={onConfirmPress} accessibilityLabel="Confirm">
          Close
        </Button>
      </View>
    </View>
  )
}

const themedStyles = StyleService.create({
  container: {
    flex: 1,
    backgroundColor: 'theme.background',
    paddingTop: 72,
  },
  scrollViewContent: {
    paddingHorizontal: 32,
    paddingVertical: 24,
  },
  buttonsWrapper: {
    paddingHorizontal: 16,
  },
  text: {
    textAlign: 'center',
    marginBottom: 24,
  },
})
