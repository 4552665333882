import defaultReducers from '@models/defaultReducers'
import {
  DailyAction,
  DailyActionCollection,
  DailyActionCompletionsCount,
  UserDailyActionKind,
  UserDailyActionStatus,
} from '@src/types'
import {
  DailyActionCompletion,
  DailyActionsStoreState,
  HeroChartType,
} from '../models/dailyActions.types'

const orderDailyActions = (dailyActions: DailyAction[], order: UserDailyActionKind[]) => {
  if (!dailyActions.length) {
    return []
  }
  if (!order.length) {
    return dailyActions
  }
  const actionLookup = Object.fromEntries(dailyActions.map((action) => [action.kind, action]))
  return order.map((kind) => actionLookup[kind]).filter(Boolean)
}

const reducers = {
  ...defaultReducers,

  listData: (
    state: DailyActionsStoreState,
    {
      payload,
    }: {
      payload: { listUserDailyActions: DailyActionCollection }
    },
  ) => {
    return {
      ...state,
      dailyActions: orderDailyActions(
        payload?.listUserDailyActions?.dailyActions,
        state.orderedActiveDailyActionKinds,
      ),
      dailyActionsCompletions: payload?.listUserDailyActions.completionCounts.reduce(
        (acc: DailyActionCompletion[], completion: DailyActionCompletionsCount) => {
          acc.push({
            dailyActionId: completion.dailyActionId,
            completed: completion.completionCount > 0,
          })

          return acc
        },
        [] as DailyActionCompletion[],
      ),
    }
  },
  toggleCompletion: (
    state: DailyActionsStoreState,
    {
      payload: { completed, dailyActionId },
    }: { payload: { completed: true; dailyActionId: string } },
  ) => {
    if (!dailyActionId) {
      return state
    }

    const existingCompletion = state.dailyActionsCompletions.some(
      (completion) => completion.dailyActionId === Number(dailyActionId),
    )

    const completedExistingActionsMarked = state.dailyActionsCompletions.map((completion) => {
      if (completion.dailyActionId === Number(dailyActionId)) {
        return {
          ...completion,
          completed,
        }
      }
      return completion
    })

    if (existingCompletion) {
      return {
        ...state,
        dailyActionsCompletions: completedExistingActionsMarked,
      }
    }

    return {
      ...state,
      dailyActionsCompletions: [
        ...state.dailyActionsCompletions,
        { dailyActionId: Number(dailyActionId), completed },
      ],
    }
  },

  upsertData: (
    state: DailyActionsStoreState,
    {
      payload: { kind, status },
    }: { payload: { kind: UserDailyActionKind; status: UserDailyActionStatus } },
  ) => {
    if (!status) {
      return state
    }
    if (status === UserDailyActionStatus.Active) {
      return { ...state, dailyActions: [...state.dailyActions, { kind, status }] }
    }

    const updatedDailyActions = state.dailyActions.filter((action) => action.kind !== kind)
    const updatedOrderedActiveDailyActionKinds = state.orderedActiveDailyActionKinds.filter(
      (kind) => kind !== kind,
    )

    return {
      ...state,
      dailyActions: updatedDailyActions,
      orderedActiveDailyActionKinds: updatedOrderedActiveDailyActionKinds,
    }
  },

  reorderDailyActions: (
    state: DailyActionsStoreState,
    { payload: { nextOrder } }: { payload: { nextOrder: UserDailyActionKind[] } },
  ) => {
    return {
      ...state,
      dailyActions: orderDailyActions(state.dailyActions, nextOrder),
      orderedActiveDailyActionKinds: nextOrder,
    }
  },

  setPreferredHeroChart: (
    state: DailyActionsStoreState,
    { payload }: { payload: { chartType: HeroChartType } },
  ) => {
    return { ...state, preferredHeroChart: payload.chartType }
  },
}

export default reducers
