import React from 'react'
import { Image, ImageStyle, View } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Button, Text } from '@src/components/base'
import { ScrollViewWithFade } from '@src/components'
import { AppStackParamList } from '@src/navigation/types'
import { dexcomG7BoxImage } from '@src/assets/images'

export const ActivationInfo = () => {
  const styles = useStyleSheet(themedStyles)
  const navigation = useNavigation<StackNavigationProp<AppStackParamList>>()
  const insets = useSafeAreaInsets()
  const image = dexcomG7BoxImage

  const onConfirmPress = () => {
    navigation.replace('RemainingSensorsQuestionScreen')
  }

  const onDeclinePress = () => {
    navigation.replace('Drawer', { screen: 'Dashboard' })
  }

  return (
    <View style={styles.container}>
      <ScrollViewWithFade scrollViewStyle={styles.scrollViewContent}>
        <Text type="title-2" style={styles.titleText}>
          Activate your first G7 Dexcom sensor
        </Text>
        <Image source={image.imageSource} style={styles.sensorBoxImage as ImageStyle} />
        <Text type="regular" style={styles.descriptionText}>
          Once you start the G7 integration, you will no longer be able to sync data from your Libre
          sensor into the Nutrisense app.
          {'\n'}
        </Text>
        <Text type="regular" style={styles.descriptionText}>
          Moving forward, you will be using your new bluetooth, waterproof sensor with the Dexcom G7
          app and sync data to the Nutrisense app scan-free!
        </Text>
      </ScrollViewWithFade>
      <View style={[styles.buttonsWrapper, { marginBottom: insets.bottom + 16 }]}>
        <Button type="primary" size="block" onPress={onConfirmPress} accessibilityLabel="Confirm">
          Transition to G7
        </Button>
        <Button
          type="outline"
          size="block"
          onPress={onDeclinePress}
          accessibilityLabel="Not yet"
          style={styles.secondaryButton}
        >
          Not Now
        </Button>
      </View>
    </View>
  )
}

const themedStyles = StyleService.create({
  container: {
    flex: 1,
    backgroundColor: 'theme.background',
    paddingTop: 72,
  },
  titleText: {
    textAlign: 'center',
    paddingHorizontal: 24,
    paddingVertical: 24,
  },
  scrollViewContent: {
    paddingHorizontal: 32,
  },
  sensorBoxImage: {
    alignSelf: 'center',
    marginBottom: 24,
  },
  secondaryButton: {
    marginTop: 8,
  },
  buttonsWrapper: {
    paddingHorizontal: 16,
  },
  descriptionText: {
    textAlign: 'center',
  },
})
